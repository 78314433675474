import React, {useState, useEffect} from "react";
import _ from 'lodash';

export function withBreakpoint(Component){
    return (props) => {

        const [state, setState] = useState({
            currentBreakpoint: undefined,
            activeBreakpoints: undefined,
            breakpoints: [
                { name: 'xs', value: 0 },
                { name: 'sm', value: 576 },
                { name: 'md', value: 768 },
                { name: 'lg', value: 992 },
                { name: 'xl', value: 1200 }
            ]
        });

        useEffect(() => {
            window.addEventListener('resize', handleResize, false);
            calculateBreakpoint();

            return () => {
                handleResize.cancel();
                window.removeEventListener('resize', handleResize, false);
            };
        }, []);

        const handleResize = _.throttle(() => {
            calculateBreakpoint();
        }, 500);

        const calculateBreakpoint = () => {
            const active = state.breakpoints.filter(x => x.value <= window.innerWidth);
            const current = active[active.length-1];
            setState({
                currentBreakpoint: current,
                activeBreakpoints: active
            });
        }

        return (
            <Component {...props} {...state} />
        )
    }
}