import React from 'react';



export default class GmapMsgShareAccepted extends React.Component {

	render() {
		let lWidth = '100%';
		let lHeight = '200px';
		if(this.props.pMapContainerStyle){
			let size = this.props.pMapContainerStyle;
			if(size.width){
				lWidth = `${size.width}`.indexOf("px")>0 ? size.width
				:(`${size.width}`.indexOf("%")>0 ? size.width : size.width+'px');
			}
			if(size.height){
				lHeight = `${size.height}`.indexOf("px")>0 ? size.height : size.height+'px';
			}
		}
		
		return (
			<div style={{backgroundColor:'lightgray', width:lWidth, height:lHeight,
						display:'flex',alignItems:'center',justifyContent:'center', textAlign:'center'
				}}>
				<p style={{padding:'15px'}}>
					Hoopla!<br/>Die Google Dienste sind nicht erlaubt.
					<br/>
					Diese können mit Ihrer Zustimmung wieder freigeschaltet werden.
					<br/>
					Die Einstellungen können Sie unter dem 'Handshake'-Symbol unten links ändern.
				</p>

			</div>
		);
	}
}
