import React, { Component } from 'react';
import { scroller } from 'react-scroll';

import KontaktFormSharePartial from '../../common/components/KontaktFormSharePartial';



/**
 * Static page: Kontakt
 * The form data is submitted via the ApiConnector
 *
 * @class      ViewKontaktDefault (name)
 */
export default class ViewKontaktDefault extends Component {

  componentDidMount() {
    scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
    });
  	window.addEventListener('scroll', this.listenToScroll);
  }
  render() {
    let btnResetShow = this.props.layoutRouterProps?.p_kontaktform_btnReset;

    //KGOT: p_labelCommune="Servicebüro"
    let formular = (<KontaktFormSharePartial p_kontaktform_btnReset={btnResetShow} p_kontaktform_labelCommune={this.props.p_kontaktform_labelCommune} />);
    

    return(
      <section className="ViewKontakt view-kontakt" name="scroll_start">
        <div className="wrapper">
          <div className="container">
            <h2 className="">Bei Fragen oder Anregungen stehen wir Ihnen gerne zur Verfügung</h2>
            <br/>
            <h4>Kontaktformular</h4>
            <br/>
            <p>Bitte füllen Sie alle mit * markierten Felder (Pflichtfelder) aus.</p>
            {formular}
          </div>
        </div>
      </section>
      );
  }
}
