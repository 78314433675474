import React, {Component} from 'react';
import Pagination from "react-js-pagination";
import {withRouter} from "react-router";
import {withBreakpoint} from "../../common/tools/BootstrapBreakpoints";
import Select from 'react-select';
import {scroller} from 'react-scroll';

import {AppContext} from '../app/AppContext';
import Config from '../../Config';

import ResultSetFactory from '../model/ResultSetFactory';
import SearchResultsWithMap from './SearchResultsWithMap';

import MessageSharePartial from './MessageSharePartial';
import ProviderSet from "../model/ProviderSet";


/**
 * This component renders the search results as ResultSets, that are created with the ResultSetFactory.
 * The ResultSets are childs of the SearchResultsWithMap Wrapper, which renders the Google Map view.
 *
 * @class      SearchResults (name)
 * @prop        {json} results - json search results from API
 * @prop        {function} filterCallBack -
 */

class SearchResults extends Component {

    static TAB_OFFERS = "TabOffers";
    static TAB_PROVIDERS = "TabProviders";

    constructor(props) {
        super(props);

        this.map_results = null;
        this.show_pagination = false;
        this.all_offers = null;

        this.state = {
            search_results: null,
            activePage: 1,
            totalItemsCount: 0,
            itemsCountPerPage: {value: Config.PAGINATION_ITEMS, label: Config.PAGINATION_ITEMS},
            zoomToID: null,
            tab: SearchResults.TAB_OFFERS
        }

        this.handlePageChange = this.handlePageChange.bind(this);
        this.highlightDataSet = this.highlightDataSet.bind(this);
    }

    componentDidMount() {

        // scroller.scrollTo('scroll_start', {
        //     duration: 1000,
        //     smooth: true
        // });

        this.updatePager();

    }

    componentDidUpdate(prevProps, prevState) {

        //check for page_num in url
        let page_num = this.props.match.params.page_num;

        //when it is different, change activePage to page_num
        if (page_num && page_num != this.state.activePage) {
            this.setState({
                activePage: parseInt(page_num)
            });
        } else if (this.state.activePage !== 1 && !page_num) {
            this.setState({
                activePage: 1
            });
        }

        if (prevProps.results !== this.props.results) {
            this.updatePager();
        }
    }

    updatePager() {
        //extract offers
        if (Array.isArray(this.props.results)) {
            this.all_offers = ResultSetFactory.extractOffers(this.props.results);
        } else {
            this.all_offers = [];
        }

        this.setState({
            totalItemsCount: this.all_offers.length,
            activePage: 1
        });
    }

    /**
     * Pager change page handler
     *
     * @param      {int}  page_number  The page number
     */
    handlePageChange(page_number) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
        this.setState({activePage: page_number}, () => {
            //set page number in url to access when moving through history with the browser
            this.props.history.push('/ergebnisse/' + page_number);
        });
    }

    handleItemsPerPage(options) {
        this.setState({
            itemsCountPerPage: options,
            activePage: 1
        });
    }

    resetAllFilters() {
        this.context.resetAllFilters().then(() => {
            this.context.searchApiOffersWithFilters();
        });
    }

    /**
     * Renders the json results through the ResultSetFactory into ResultSets.
     * Filters the json data for the map_results.
     *
     * @param      {<type>}  json_data  The json data
     * @return     {Array}   All ResultSets
     */
    createSearchResults(json_data) {

        let results = null;
        let ndate = null;
        this.map_results = [];

        //check for error
        if (json_data.ID) {
            this.show_pagination = false;
            switch (json_data.ID.toString()) {
                case Config.REQUESTED_ERROR_GEOGRAPHY_INVALID.c_id:
                    //show API Error
                    results = (
                        <div className="alert alert-danger mt-5" role="alert">
                            {Config.REQUESTED_ERROR_GEOGRAPHY_INVALID.c_message}
                        </div>
                    );
                    break;
                default:
                    //show API Error
                    results = (
                        <div className="alert alert-danger mt-5" role="alert">
                            {json_data.ID}: {json_data.Description}
                        </div>
                    );
                    break;
            }
        } else if (this.all_offers && this.all_offers.length) {
            this.show_pagination = true;
            results = [];

            let start_item = (this.state.activePage - 1) * this.state.itemsCountPerPage.value;
            let end_item = this.state.activePage * this.state.itemsCountPerPage.value;

            //check max_length
            if (end_item > this.all_offers.length)
                end_item = this.all_offers.length;

            //create result sets
            results = ResultSetFactory.buildResultSets(this.all_offers, start_item, end_item, this.context.gotoOfferDetail, this.highlightDataSet);

            //create offer_data for maps view
            this.map_results = ResultSetFactory.buildMapResults(this.all_offers, start_item, end_item);
        }
        return results;
    }

    highlightDataSet(id) {
        this.setState({
            zoomToID: id
        });
    }

    createProviders(providers)
    {
        let providers_jsx = [];
        let key = 0;

        console.log("createProviders: ", providers);

        // if(providers)
        // {
        //     //make a recursive loop through the whole tree
        //     let output_func = (provider) => {
        //         providers_jsx.push(<ProviderSet
        //             name={provider.Title}
        //             id={provider.Id}
        //             key={key++}
        //         />);
        //
        //         if(provider.ChildNodes && provider.ChildNodes.length)
        //         {
        //             for (let child_provider of provider.ChildNodes) {
        //                 output_func(child_provider);
        //             }
        //         }
        //     }
        //     //go
        //     output_func(providers);
        // }

        if(providers) {
            if(providers.ID === "1110"){
                //Description: "Dataset list filter sent without dataset IDs"
                // ID: "1110"
                return providers_jsx;
            }
            for (const provider of providers) {
                providers_jsx.push(<ProviderSet
                    name={provider.LongName}
                    id={provider.DatasetId}
                    key={key++}
                />);
            }
        }

        return providers_jsx;
    }

    showTab(tab_to_show) {
        console.log("showTab");
        this.setState({
            tab: tab_to_show
        })
    }

    render() {
        let active_filters = this.context.getActiveFiltersAsJSX();
        let active_filters_content;
        if (active_filters.length) {
            active_filters_content = <p className="active-filters"><strong>Filter:</strong>{active_filters}</p>
        }

        let reset_btn = null;
        if (this.context.checkFiltersActive()) {
            reset_btn = <button className="resetBtn" onClick={() => {
                this.resetAllFilters()
            }}>Filterung zurücksetzen</button>;
        }

        if(this.props.results === null || this.props.results.length === 0){
            this.show_pagination = false;
            return (
                <div className="search-results">
                    <header className="search-results-header">
                        <h2 className="title">Ergebnisse Ihrer Suche:</h2>
                        {active_filters_content}
                        <button className="backBtn" onClick={() => {
                            this.props.history.goBack()
                        }}>&lt;&lt; zurück
                        </button>
                        {reset_btn}
                    </header>
                    <div name="scroll_start"></div>
                    <MessageSharePartial>
                        Es wurden keine Ergebnisse für diese Suche gefunden. <br/>
                        Versuche es mit einer neuen Suche oder setze die Filter zurück.
                    </MessageSharePartial>
                </div>
            );
        }

        let search_results = this.createSearchResults(this.props.results);
        let num_offers = "";
        if(this.all_offers && this.all_offers.length)
            num_offers = `(${this.all_offers.length})`;

        let providers = this.createProviders(this.props.providers);

        //set page range depending on bootstrap breakpoint
        //see at bottom of class -> withBreakpoint()
        let page_range = 5;
        console.log(this.props.currentBreakpoint);
        if(this.props.currentBreakpoint && this.props.currentBreakpoint.value > 768){
            page_range = 15;
        }

        let itemsPerPageOption = [
            {value: 5, label: '5'},
            {value: 10, label: '10'},
            {value: 25, label: '25'}
        ];

        let pagination = null;
        if (this.show_pagination) {
            pagination = (
                <div className="pagination-wrapper">
                    <div className="pagination-header">
                        <h3 className="pagination-title">Ergebnisse pro Seite</h3>
                        <Select
                            className="pagination-select"
                            classNamePrefix="ps"
                            options={itemsPerPageOption}
                            value={this.state.itemsCountPerPage}
                            isMulti={false}
                            name="select-pager"
                            onChange={options => {
                                this.handleItemsPerPage(options)
                            }}/>
                    </div>

                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage.value}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={page_range}
                        onChange={this.handlePageChange}
                        itemClassFirst="pageFirst"
                        itemClassPrev="pagePrev"
                        itemClassNext="pageNext"
                        itemClassLast="pageLast"
                        prevPageText="‹"
                        nextPageText="›"
                    ></Pagination>
                </div>
            );
        }

        let tab_view;
        switch(this.state.tab)
        {
            case SearchResults.TAB_OFFERS:
                tab_view =
                    <SearchResultsWithMap
                        api_results={this.map_results}
                        zoomToID={this.state.zoomToID}
                    >
                        {search_results}
                    </SearchResultsWithMap>
                break;

            case SearchResults.TAB_PROVIDERS:
                tab_view =
                    <div className={"provider-list"}>
                        {providers}
                    </div>;
                break;
        }

        return (

            <div className="search-results">
                <header className="search-results-header">
                    <h2 className="title">Ergebnisse Ihrer Suche:</h2>
                    {active_filters_content}
                    <button className="backBtn" onClick={() => {
                        this.props.history.goBack()
                    }}>&lt;&lt; zurück
                    </button>
                    {reset_btn}
                </header>
                <div name="scroll_start"></div>
                {pagination}

                <div className="area-tabs">
                    <button type="button" className={this.state.tab == SearchResults.TAB_OFFERS ? "tab-button selected" : "tab-button"}
                            onClick={() => this.showTab(SearchResults.TAB_OFFERS)}>Angebote {num_offers}</button>
                    <button type="button" className={this.state.tab == SearchResults.TAB_PROVIDERS ? "tab-button selected" : "tab-button"}
                            onClick={() => this.showTab(SearchResults.TAB_PROVIDERS)}>Anbieter ({providers.length})</button>
                </div>

                {tab_view}

                {pagination}
            </div>
        );
    }
}

SearchResults.contextType = AppContext;

//the "withBreakpoint" Function allows the component to get the current bootstrap Breakpoint in this.props.currentBreakpoint
export default withRouter(withBreakpoint(SearchResults));
