class Helper{

	static cutStr(str, cut)
	{
		let fp_index;

		//check and delete (FP)
		fp_index = str.indexOf(cut);
		if(fp_index !== -1)
		{
			//remove cut from str
			str = str.substr(cut.length);
		}
	
		return str;
	}

	static space2str(str_with_space, str_swap = '_')
	{
		return str_with_space.split(' ').join(str_swap);
	}

	// https://stackoverflow.com/questions/7627000/javascript-convert-string-to-safe-class-name-for-css
	static makeSafeForCSS(name) {
	    return name.replace(/[^a-z0-9]/g, function(s) {
	        var c = s.charCodeAt(0);
	        if (c === 32) return '-';
	        if (c >= 65 && c <= 90) return s.toLowerCase();
	        return '__' + ('000' + c.toString(16)).slice(-4);
	    });
	}

	static strMaxLength(str, length = 10)
	{
		let new_str = str;
		if(length && str.length > length)
		{
			new_str = str.substr(0, length) + "…";
		}
		
		return new_str;
	}


	// Shorten a string to less than maxLen characters without truncating words.
	// @see https://stackoverflow.com/questions/5454235/shorten-string-without-cutting-words-in-javascript
	static shorten(pStr, maxLen, separator = ' ') {
		if (typeof pStr === 'string' || pStr instanceof String){
			// it's a string
			if (pStr.length <= maxLen) return pStr;
			return pStr.substr(0, pStr.lastIndexOf(separator, maxLen)) + '...';
		}
		else{
			// it's something else
		}
		return pStr;
	}

	static encodeHTMLEntities(str) {
  		let buf = [];
		  for (let i=str.length-1;i>=0;i--) {
		    buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
		  }
  		return buf.join('');
	};

	// https://stackoverflow.com/questions/1053902/how-to-convert-a-title-to-a-url-slug-in-jquery
	static convertToSlug(str) {
		return str
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/[^\w-]+/g, '');
	}

	static reload(e)
	{
		e.preventDefault();
		//todo check if this works in ie
		window.location.href = window.location.origin;
	}

	/**
	* Calling: let test = [];	Helper.getValuesFromKeyJson(jsonFamFreundOrteConfig, "ConfOfferTypeId", test); console.log("Gefundene Werte", test);
	* sucht in einem JSON-Object anhand von KeyName alle Values in ein Array radius
	* @ pJson: JSON Object, der durchgesucht werden soll [{"keyName1":"value1","keyNameN":"valueN","keyNameSearch":"keyValueFind","level2":[{"keyNameSearchN":"keyValueFindN"}]},{}, {},{}]
	* @ pKeyName: String, wie der gesuchte Schlüssel im JSON-Object gefunden werden muss
	* @ pResult: leere [] Variable, die Result zurückliefert
	*/
	static getValuesFromKeyJsonRecursiv(pJson, pKeyName, pResult){
		pJson.forEach(obj => {
        Object.entries(obj).forEach(([key, value]) => {
					if (typeof value == 'object') {
	            console.log("Iterate Object " + key);
	            this.getValuesFromKeyJsonRecursiv(value, pKeyName, pResult);
	        }
	        else if(key === pKeyName){
            pResult.push(value);
					}
        });
    });
		return pResult;
	}

 	static openGmapNavigate(pLat, pLong){
	  let url = `https://maps.google.com/maps?f=q&hl=de&q=${pLat},${pLong}`;
	  const win = window.open(url, '_blank');
	  if (win != null) {
	    win.focus();
	  }
	}


	static StringToHtml = require('html-react-parser');

}
export default Helper;
