import React, {Component} from 'react';
import {scroller} from 'react-scroll';
import {AppContext} from '../../common/app/AppContext';

import imgNews from './media/static/klimaschutz.jpg';

/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewStaticDatenschutz (name)
 */
export default class ViewStaticDatenschutz extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount(prevProps, prevState) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    render() {
        let out = null;

        //create page
        out = (

            <div className="view-static-datenschutz" name="scroll_start">

                <h1 class={"title"}>Datenschutz‐Erkl&auml;rung</h1>
                <p>Die Landeshauptstadt Stuttgart nimmt den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Die
                    EU‐Datenschutz‐Grundverordnung (DSGVO), wirksam geworden am 25. Mai 2018, verpflichtet uns, Sie
                    umfassend &uuml;ber die Verarbeitung Ihrer personenbezogenen Daten zu informieren.</p>
                <h2>Verantwortliche im Sinne der DSGVO</h2>
                <p>Landeshauptstadt Stuttgart<br/> Marktplatz 1<br/> 70173 Stuttgart<br/> E‐Mail:<a
                    href="mailto:info@stuttgart.de">info@<em>stuttgart.</em>de</a></p>
                <p>Die konkreten Angaben zu den verantwortlichen f&uuml;r das Online‐Angebot finden Sie im <a
                    href="https://www.stuttgart.de/impressum.php">Impressum</a>.</p>
                <h2>Datenschutzbeauftragter der Landeshauptstadt Stuttgart</h2>
                <p>Unseren beh&ouml;rdlichen Datenschutzbeauftragten erreichen Sie unter:</p>
                <p><a
                    href="https://www.stuttgart.de/vv/verwaltungseinheit/abteilung-datenschutz-und-informationssicherheit.php">Abteilung
                    Datenschutz und Informationssicherheit</a></p>
                <p>Wenn Sie Fragen zum Datenschutz haben, die die Landeshauptstadt Stuttgart nicht betreffen,
                    k&ouml;nnen Sie sich gerne an den <a
                        href="https://www.baden-wuerttemberg.datenschutz.de/">Landesbeauftragten f&uuml;r
                        Datenschutz </a>wenden. Das ist Ihr zentraler Ansprechpartner des Landes Baden‐W&uuml;rttemberg
                    f&uuml;r Datenschutz. Dort k&ouml;nnen Sie auch eine Beschwerde einreichen.</p>
                <h2>Datenverarbeitung</h2>
                <p>Bei einem Besuch unserer Internetpr&auml;senzen verarbeiten wir personenbezogene Daten
                    ausschlie&szlig;lich im Rahmen der gesetzlichen Bestimmungen (Datenschutzgrundverordnung‐DSGVO,
                    Bundesdatenschutzgesetz‐BDSG, Landesdatenschutzgesetz Baden‐W&uuml;rttemberg‐LDSG Ba‐W&uuml;).</p>
                <p>Im Folgenden geben wir Ihnen Informationen &uuml;ber die Art, den Umfang und den Zweck der Erhebung,
                    Verarbeitung und Nutzung von Daten, wenn Sie eine unserer Internetseiten besuchen.</p>
                <h2>Nutzungsdaten</h2>
                <p>Bei jedem Zugriff auf eine der vorgenannten Seiten und bei jedem Abruf einer Datei, werden
                    automatisch &uuml;ber diesen Vorgang allgemeine Daten in einer Protokolldatei gespeichert. Das
                    Tracking erfolgt &uuml;ber das Webanalysetool Matomo. Die Speicherung dient ausschlie&szlig;lich
                    systembezogenen und statistischen Zwecken (auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO), sowie
                    in Ausnahmef&auml;llen zur Anzeige von Straftaten (auf Grundlage von Art. 6 Abs. 1 Buchst. e)
                    DSGVO).</p>
                <p>Eine Weitergabe der Daten an Dritte oder eine sonstige Auswertung findet nicht statt, es sei denn, es
                    besteht eine gesetzliche Verpflichtung dazu.</p>
                <p>Im Einzelnen wird &uuml;ber jeden Abruf folgender Datensatz gespeichert:</p>
                <ul>
                    <li>Datum und Uhrzeit des Abrufs</li>
                    <li>Beschreibung des Typs des verwendeten Webbrowsers und des verwendeten Betriebssystems</li>
                    <li>Suchbegriffe mit Datum und Uhrzeit</li>
                </ul>
                <h2>Personenbezogene Daten</h2>
                <p>An einigen Stellen unserer Internetangebote haben Sie die M&ouml;glichkeit, freiwillig
                    personenbezogene Angaben zu machen: beispielsweise in Kontaktformularen</p>
                <p>a.) Kontakt</p>
                <p>Wenn Sie mit uns in Kontakt treten (z. B. &uuml;ber Telefon, E‐Mail oder ein Kontaktformular),
                    speichern wir Ihre Daten auf Grundlage von Art. 6 Abs. 1 Buchst. b) DSGVO zum Zwecke der Bearbeitung
                    Ihrer Anfrage, sowie f&uuml;r den Fall, dass eine weitere Korrespondenz stattfinden sollte. Ihre
                    Daten werden gel&ouml;scht, sobald Ihre Anfrage vollst&auml;ndig bearbeitet und/oder beantwortet
                    wurde. Im Rahmen des t&auml;glichen Backups werden sie dort noch bis zu 30 Tage
                    gespeichert.</p>
                <h2>Verwendung technisch notwendiger Cookies</h2>
                <p>Die online-Seite verwendet sogenannte Cookies. Bei Cookies handelt es sich um kleine Textdateien,
                    die &uuml;ber einen Internetbrowser auf Ihrem Computer oder mobilen Endger&auml;t abgelegt und
                    gespeichert werden. Neben Cookies, die lediglich w&auml;hrend einer Sitzung benutzt und nach Ihrem
                    Website‐Besuch gel&ouml;scht werden (&bdquo;Session Cookies&ldquo;), k&ouml;nnen Cookies auch
                    benutzt werden, um Nutzereinstellungen und andere Informationen &uuml;ber eine bestimmte Zeit (z.B.
                    zwei Jahre) zu speichern (&bdquo;permanente Cookies&ldquo;). Cookies dienen dazu, Ihnen unser
                    Angebot die Verwendung unserer Internetseite zu erm&ouml;glichen.</p>
                <p>Wir setzen in einigen Bereichen unserer Website sogenannte technisch notwendige Cookies ein. Durch
                    solche Dateielemente kann Ihr Computer als technische Einheit w&auml;hrend Ihres Besuchs auf einer
                    unserer Internetseite identifiziert werden. Einige Funktionen unserer Internetseiten k&ouml;nnen
                    ohne den Einsatz von Cookies nicht angeboten werden. Notwendige Cookies helfen dabei, eine
                    Internetseite nutzbar zu machen, indem sie Grundfunktionen wie Seitennavigation und Zugriff auf
                    sichere Bereiche der Internetseite erm&ouml;glichen. Die Internetseite kann ohne diese Cookies nicht
                    richtig funktionieren.</p>
                <p>Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von
                    Nutzerprofilen verwendet.</p>
                <p>Sofern durch einzelne von uns implementierte Cookies auch personenbezogene Daten verarbeitet werden,
                    erfolgt die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 lit. b DSGVO entweder zur Durchf&uuml;hrung
                    des Vertrages oder gem&auml;&szlig; Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten
                    Interessen. Die Verarbeitung erfolgt zur Erm&ouml;glichung der Funktionsweise unserer Website. Sie
                    ist daher zur Wahrung unserer berechtigten Interessen erforderlich.</p>
                <p>Sie k&ouml;nnen das Setzen von Cookies durch die entsprechende Einstellung Ihres Internetbrowsers
                    selbst bestimmen. &Uuml;ber die Einstellungen in Ihrem Internetbrowser k&ouml;nnen Sie
                    die &Uuml;bertragung von Cookies zulassen, deaktivieren oder einschr&auml;nken. Ferner k&ouml;nnen
                    bereits gesetzte Cookies jederzeit gel&ouml;scht werden.</p>
                <p>Denken Sie bitte daran, dass die Nutzung unserer Internetseiten bei Nichtverwendung von Cookies unter
                    Umst&auml;nden nicht in allen Funktionen vollumf&auml;nglich nutzbar ist.</p>
                <h2>Verwendung von Local Storages</h2>
                <p> Damit Sie unsere Website Ihren pers&ouml;nlichen
                    Bed&uuml;rfnissen und Ihrer Nutzung anpassen k&ouml;nnen, verwenden wir auch die sogenannte Local
                    Storage Technik (auch &bdquo;Lokale Daten&ldquo; und &bdquo;Lokaler Speicher&ldquo; genannt). Dabei
                    werden Daten lokal im Cache Ihres Browsers gespeichert, die auch nach dem Schlie&szlig;en des
                    Browser‐Fensters oder dem Beenden des Programms &ndash; soweit sie den Cache nicht
                    l&ouml;schen &ndash; weiterhin bestehen und ausgelesen werden k&ouml;nnen.</p>
                <p>Dabei werden folgende Informationen als lokale Daten hinterlegt:</p>
                <p>Cookie-Box Abfrage auf dem Portal:</p>
                <ul>
                    <li>GoogleMaps / OpenStreetMap</li>
                    <li>Matomo (Cookie)</li>
                    <li>Angebote (GUIDs) als Favoriten setzen</li>
                </ul>
                <p>Auf die im Local Storage gespeicherten Daten k&ouml;nnen Dritte nicht zugreifen. Sie werden an Dritte
                    nicht weitergegeben und auch nicht zu Werbezwecken verwendet.</p>
                <p>Wir verwenden diese Techniken im berechtigten Interesse um Ihnen ein voll funktionsf&auml;higes
                    Angebot machen zu k&ouml;nnen auf Basis von Artikel 6 Abs 1 lit. f DSGVO. Die Speicherung im Local
                    Storage ist f&uuml;r die technische Umsetzung der beschriebenen Funktionen erforderlich.</p>
                <p>Wenn Sie nicht w&uuml;nschen, dass unsere Website Local‐Storage‐Funktionen einsetzt, dann k&ouml;nnen
                    Sie dem widersprechen, indem Sie in den Einstellungen Ihres jeweiligen Browsers die Speicherung
                    deaktivieren.</p>
                <p>Die Speicherung der Informationen im Local Storage erfolgt bis zur L&ouml;schung in Ihrem
                    Browser.</p>
                <h2>Verwendung des Analyse‐Tools Matomo Software</h2>
                <p>Unsere Internetseite benutzt Matomo (https://matomo.org), eine Open‐Source‐Software zur Analyse der
                    Besucherzugriffe auf dieser Seite.</p>
                <p>Matomo verwendet so genannte Cookies, Textdateien, die auf Ihren Computern gespeichert werden und die
                    eine Analyse der Benutzung der Seiten durch Sie erm&ouml;glichen. Die durch das Cookie erzeugten
                    Informationen &uuml;ber die Benutzung dieser Seiten werden auf unseren Servern in Deutschland
                    gespeichert und nicht an Dritte weitergegeben. Die IP‐Adresse jedes Besuchers wird sofort nach der
                    Verarbeitung und vor deren Speicherung anonymisiert.</p>
                <p>Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung Ihres Browsers
                    verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle
                    Funktionen unserer Seiten vollumf&auml;nglich nutzen k&ouml;nnen.</p>
                <p>Sie k&ouml;nnen der Verwendung Ihrer Daten durch Matomo auch widersprechen, indem sie von der
                    nachfolgenden dargestellten M&ouml;glichkeit Gebrauch machen.</p>
                <p>Dabei wird auf Ihrem Browser ein Cookie gespeichert, der lediglich die Information enth&auml;lt,
                    dass &uuml;ber Sie durch Matomo keinerlei Daten erhoben werden d&uuml;rfen. Zuvor bereits abgelegte
                    Cookies werden dann gel&ouml;scht. Sollten Sie jedoch einmal s&auml;mtliche Cookies in Ihrem Browser
                    l&ouml;schen, m&uuml;ssen Sie der Verwendung Ihrer Daten ggf. erneut widersprechen.</p>
                <p>Sie k&ouml;nnen sich hier entscheiden, ob in Ihrem Browser ein eindeutiger Webanalyse‐Cookie abgelegt
                    werden darf, um uns die Erfassung und Analyse verschiedener statistischer Daten zu erm&ouml;glichen.
                    Wenn Sie sich dagegen entscheiden m&ouml;chten, k&ouml;nnen Sie durch Klicken des folgenden Links
                    den Matomo‐Deaktivierungs‐Cookie in Ihrem Browser ablegen.</p>
                <p>Wir st&uuml;tzen den Einsatz des vorgenannten Analyse‐Tools auf Art. 6 Abs. 1 Buchst. f) DSGVO: die
                    Verarbeitung erfolgt zur Analyse des Nutzungsverhaltens und ist daher zur Wahrung unserer
                    berechtigten Interessen erforderlich.</p>
                <p>Sie haben hier die M&ouml;glichkeit, das Tracking zu deaktiveren oder wieder zu aktivieren: <a
                    href="https://www.stuttgart.de/datenschutz.php">Datenschutz‐Einstellungen</a></p>
                <h2>Plugins und Tools</h2>
                <h3>OpenStreetMap</h3>
                <p>Diese Seite nutzt &uuml;ber eine API das
                    Open-Source-Mapping-Werkzeug &bdquo;OpenStreetMap&ldquo; (OSM). Anbieter ist die <a
                        href="https://www.studierendenwerk-kaiserslautern.de/kaiserslautern/info/datenschutzerklaerung/#https://wiki.osmfoundation.org/wiki/Main_Page">OpenStreetMap
                        Foundation</a>. Zur Nutzung der Funktionen von OpenStreetMap ist es notwendig, Ihre IP
                    Adresse zu speichern. Diese Informationen werden in der Regel an einen Server
                    vonOpenStreetMap &uuml;bertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen
                    Einfluss auf diese Daten&uuml;bertragung. Die Nutzung von OpenStreetMap erfolgt im Interesse
                    einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von
                    uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
                    Abs. 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der <a
                        href="http://wiki.openstreetmap.org/wiki/Privacy_Policy">Datenschutzseite von
                        OpenStreetMap</a>und hier<a
                        href="http://wiki.openstreetmap.org/wiki/Legal_FAQ">http://wiki.openstreetmap.org/wiki/Legal_FAQ</a>.
                </p>
                <h2>Weitergabe von Daten an Dritte</h2>
                <p>Soweit nachfolgend oder in den vorhergehenden Abschnitten nicht ausdr&uuml;cklich ausgewiesen, werden
                    Ihre Daten nicht an Dritte oder andere Empf&auml;nger weitergegeben.</p>
                <p>Wir geben Ihre pers&ouml;nlichen Daten auch nur dann an Dritte weiter, wenn:</p>
                <ul>
                    <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdr&uuml;ckliche Einwilligung dazu erteilt
                        haben,
                    </li>
                    <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Aus&uuml;bung oder
                        Verteidigung von Rechtsanspr&uuml;chen erforderlich ist und kein Grund zur Annahme besteht, dass
                        Sie ein &uuml;berwiegendes schutzw&uuml;rdiges Interesse an der Nichtweitergabe Ihrer Daten
                        haben,
                    </li>
                    <li>f&uuml;r den Fall, dass f&uuml;r die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine
                        gesetzliche Verpflichtung besteht, sowie
                    </li>
                    <li>dies gesetzlich zul&auml;ssig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO f&uuml;r die Abwicklung
                        von Vertragsverh&auml;ltnissen mit Ihnen erforderlich ist.
                    </li>
                </ul>
                <h2>Speicherdauer</h2>
                <p>Die von uns verarbeiteten Daten werden nach Ma&szlig;gabe der Art. 17 und 18 DSGVO gel&ouml;scht oder
                    in ihrer Verarbeitung eingeschr&auml;nkt.</p>
                <p>Sofern nicht im Rahmen dieser Datenschutzerkl&auml;rung ausdr&uuml;cklich angegeben, werden die bei
                    uns gespeicherten Daten gel&ouml;scht, sobald sie eine etwaige Einwilligung widerrufen bzw. wenn die
                    Daten f&uuml;r ihre Zweckbestimmung nicht mehr erforderlich sind und der L&ouml;schung keine
                    berechtigten Interessen oder gesetzlichen Aufbewahrungspflichten entgegenstehen.</p>
                <p>Sofern die Daten nicht gel&ouml;scht werden, weil sie f&uuml;r andere und gesetzlich zul&auml;ssige
                    Zwecke erforderlich sind, wird deren Verarbeitung eingeschr&auml;nkt. D.h. die Daten werden gesperrt
                    und nicht f&uuml;r andere Zwecke verarbeitet. Das gilt z.B. f&uuml;r Daten der Nutzer, die aus
                    handels‐ oder steuerrechtlichen Gr&uuml;nden aufbewahrt werden m&uuml;ssen.</p>
                <p>Nach gesetzlichen Vorgaben erfolgt die Aufbewahrung f&uuml;r 6 Jahre gem&auml;&szlig; &sect; 257 Abs.
                    1 HGB (z.B. Handelsbriefe, Buchungsbelege, etc.) sowie f&uuml;r 10 Jahre gem&auml;&szlig; &sect; 147
                    Abs. 1 AO (z.B. Handels‐ und Gesch&auml;ftsbriefe, steuerrelevante Unterlagen).</p>
                <h2>Betroffenenrechte</h2>
                <h3>a.) Auskunftsrecht</h3>
                <p>Sie haben das Recht, von uns eine Best&auml;tigung dar&uuml;ber zu verlangen, ob Sie betreffende
                    personenbezogene Daten verarbeitet werden.</p>
                <p>Senden Sie hierf&uuml;r bitte einfach eine E‐Mail an
                    Poststelle.51.Portal.Fruehehilfen@stuttgart.de.</p>
                <h3>b.) Berichtigung/L&ouml;schung/Einschr&auml;nkung der Verarbeitung</h3>
                <p>Des Weiteren haben Sie das Recht, von uns zu verlangen, dass</p>
                <ul>
                    <li>Sie betreffende unrichtige personenbezogene Daten unverz&uuml;glich berichtigt werden (Recht auf
                        Berichtigung);
                    </li>
                    <li>Sie betreffende personenbezogene Daten unverz&uuml;glich gel&ouml;scht werden (Recht auf
                        L&ouml;schung) und
                    </li>
                    <li>die Verarbeitung eingeschr&auml;nkt wird (Recht auf Einschr&auml;nken der
                        Verarbeitung).</li>
                </ul>
                <p>Senden Sie hierf&uuml;r bitte einfach eine E‐Mail an
                    Poststelle.51.Portal.Fruehehilfen@stuttgart.de.</p>
                <h3>c.) Widerrufsrecht</h3>
                <p>Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung
                    wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
                    Verarbeitung nicht ber&uuml;hrt.</p>
                <p>Senden Sie hierf&uuml;r bitte einfach eine E‐Mail an
                    Poststelle.51.Portal.Fruehehilfen@stuttgart.de.</p>
                <h3>d.) Widerspruchsrecht</h3>
                <p>Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung
                    wird die Rechtm&auml;&szlig;igkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
                    Verarbeitung nicht ber&uuml;hrt.</p>
                <p>Senden Sie hierf&uuml;r bitte einfach eine E‐Mail an post[at]stuttgart.de.</p>
                <h3>e.) Beschwerderecht</h3>
                <p>Sind Sie der Ansicht, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
                    DSGVO verst&ouml;&szlig;t, haben Sie unbeschadet anderweitiger Rechtsbehelfe das Recht auf
                    Beschwerde bei einer Aufsichtsbeh&ouml;rde, wie z.B. beim Landesbeauftragten f&uuml;r Datenschutz
                    und Informationsfreiheit Baden‐W&uuml;rttemberg, E‐Mail: poststelle[at]lfdi.bwl.de.</p>
                <h2>&Auml;nderung der Datenschutzerkl&auml;rung</h2>
                <p>Wir behalten uns vor, diese Datenschutzerkl&auml;rung bei einer etwaigen &Auml;nderung der
                    Rechtslage, des auf der Website angebotenen Dienstes sowie der Datenverarbeitung anzupassen. Dies
                    gilt jedoch nur im Hinblick auf Erkl&auml;rungen zur Datenverarbeitung. Sofern Ihre Einwilligung
                    erforderlich ist oder Bestandteile der Datenschutzerkl&auml;rung Regelungen des
                    Vertragsverh&auml;ltnisses mit Ihnen enthalten, erfolgen die &Auml;nderungen nur mit Ihrer
                    Zustimmung.</p>
                <p>Sie k&ouml;nnen sich &uuml;ber etwaige &Auml;nderungen regelm&auml;&szlig;ig in dieser
                    Datenschutzerkl&auml;rung informieren.</p>
                <p>(Stand: Oktober 2021)</p>

            </div>
        );

        return out;
    }
}

ViewStaticDatenschutz.contextType = AppContext;
