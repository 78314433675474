import React from 'react';
import Svg from "./Svg.js";
import {SvgIconsConstants} from './SvgIconsConstants.js';

//https://blog.lftechnology.com/using-svg-icons-components-in-react-44fbe8e5f91
//https://codesandbox.io/s/ryy418r5km?from-embed=&file=/src/SVGIcon.js:4619-4642
//https://css-tricks.com/scale-svg/


const getViewBox = pConfigKey => {
  switch(pConfigKey){
    case SvgIconsConstants.g_IconSvgParkplatz:
      return '0 0 42 70';
    case SvgIconsConstants.g_IconSvgParkplatz_Stu_imkreis:
      return '0 0 1417 1416';
    case SvgIconsConstants.g_IconSvgWc:
      return '0 0 112 69';
    case SvgIconsConstants.g_IconSvgWc_Stu_imkreis:
      return '0 0 1417 1416';
    case SvgIconsConstants.g_IconSvgTaub:
      return '0 0 105 130';
    case SvgIconsConstants.g_IconSvgBlind:
      return '0 0 94 130';
    case SvgIconsConstants.g_IconSvgAufzug:
      return '0 0 84 130';
    case SvgIconsConstants.g_IconSvgRollstuhl:
      return '0 0 84 130';
    case SvgIconsConstants.g_IconSvgRollstuhl_Stu_imkreis:
      return '0 0 1417 1416';
    case SvgIconsConstants.g_IconSvgKinderwagen:
      return '0 0 512 512';
    case SvgIconsConstants.g_IconSvgKinderwagen_Stu_imkreis:
      return '0 0 1417 1416';
    case SvgIconsConstants.g_IconResultSetSvgOrt:
      return '0 0 77 53';
    case SvgIconsConstants.g_IconResultSetSvgOrt_Filled:
      return '0 0 77 53';
    case SvgIconsConstants.g_IconResultSetNeu:
      return '0 0 512 512';
    case SvgIconsConstants.g_IconResultSetNeu_left:
      return '0 0 40 40';
    case SvgIconsConstants.g_IconFavoriteInit:
      return '0 0 350 350';
    case SvgIconsConstants.g_IconFavoriteSet:
      return '0 0 350 350';
    case SvgIconsConstants.g_IconHandshake:
      return '0 0 762 638';
    case SvgIconsConstants.g_IconPfeilTop:
      return '0 0 14 8.6';
    default:
      return "0 0 32 32";
  }
};
const getPath = (pConfigKey, props) => {
  switch(pConfigKey){
    case SvgIconsConstants.g_IconSvgParkplatz:
      return (
        <path
          {...props}
          d="M 4.00,13.00
           C 11.65,13.00 27.75,11.91 33.90,16.01
             41.08,20.79 40.52,32.47 32.98,37.15
             28.33,40.03 18.46,40.00 13.00,40.00
             13.00,42.74 13.34,52.77 12.40,54.69
             11.11,57.31 6.79,57.47 4.99,55.26
             3.83,53.83 4.01,50.78 4.00,49.00
             4.00,49.00 4.00,13.00 4.00,13.00 Z
           M 13.00,20.10
           C 13.00,22.27 12.74,28.94 13.60,30.69
             14.67,32.87 16.86,32.91 19.00,32.98
             21.85,33.06 25.39,32.89 27.40,30.57
             29.91,27.68 29.39,21.73 23.98,20.10
             22.70,19.98 20.37,20.01 19.00,20.10
             19.00,20.10 13.00,20.10 13.00,20.10 Z"
        />
      );
    case SvgIconsConstants.g_IconSvgParkplatz_Stu_imkreis:
      return (
          <path
              {...props}
              d="M 701.00,0.42
           C 711.80,-0.89 714.77,0.96 724.00,1.00
             724.00,1.00 740.00,1.00 740.00,1.00
             740.00,1.00 752.00,2.00 752.00,2.00
             752.00,2.00 761.00,2.00 761.00,2.00
             761.00,2.00 788.00,4.84 788.00,4.84
             836.57,10.03 884.66,20.55 931.00,36.00
             1147.06,108.02 1316.31,278.09 1384.02,496.00
             1395.49,532.90 1403.76,569.76 1409.27,608.00
             1409.27,608.00 1414.09,654.00 1414.09,654.00
             1414.09,654.00 1415.00,664.00 1415.00,664.00
             1415.00,664.00 1416.00,684.00 1416.00,684.00
             1416.00,684.00 1416.00,731.00 1416.00,731.00
             1416.00,731.00 1415.00,752.00 1415.00,752.00
             1415.00,752.00 1414.09,762.00 1414.09,762.00
             1414.09,762.00 1409.42,806.00 1409.42,806.00
             1405.05,836.95 1399.15,867.91 1390.57,898.00
             1378.16,941.57 1362.13,984.51 1341.75,1025.00
             1261.29,1184.91 1119.00,1313.17 950.00,1373.31
             891.07,1394.28 836.16,1406.15 774.00,1412.17
             774.00,1412.17 749.00,1414.00 749.00,1414.00
             749.00,1414.00 733.00,1415.00 733.00,1415.00
             733.00,1415.00 685.00,1415.00 685.00,1415.00
             685.00,1415.00 665.00,1414.00 665.00,1414.00
             665.00,1414.00 655.00,1413.09 655.00,1413.09
             655.00,1413.09 597.00,1406.42 597.00,1406.42
             548.32,1398.87 501.01,1385.93 455.00,1368.42
             392.51,1344.65 326.26,1307.85 274.00,1266.20
             209.73,1214.98 158.27,1160.24 113.69,1091.00
             57.12,1003.14 20.91,903.56 7.28,800.00
             3.96,774.78 2.04,749.42 2.00,724.00
             2.00,724.00 1.00,709.00 1.00,709.00
             1.00,709.00 2.00,694.00 2.00,694.00
             2.00,694.00 2.00,675.00 2.00,675.00
             2.00,675.00 2.96,664.00 2.96,664.00
             2.96,664.00 8.73,606.00 8.73,606.00
             19.69,529.94 41.60,461.05 75.26,392.00
             87.56,366.76 102.32,342.49 117.66,319.00
             209.71,178.01 352.18,73.56 514.00,27.43
             556.96,15.18 600.58,7.47 645.00,3.17
             645.00,3.17 666.00,1.91 666.00,1.91
             666.00,1.91 678.00,1.00 678.00,1.00
             678.00,1.00 701.00,0.42 701.00,0.42 Z
           M 635.00,821.00
           C 635.00,821.00 744.00,821.00 744.00,821.00
             744.00,821.00 759.00,820.00 759.00,820.00
             759.00,820.00 788.00,818.17 788.00,818.17
             822.56,814.82 847.93,811.68 878.00,792.31
             936.51,754.62 962.48,691.85 969.17,625.00
             969.17,625.00 971.00,601.00 971.00,601.00
             971.00,601.00 971.00,572.00 971.00,572.00
             971.00,572.00 969.17,547.00 969.17,547.00
             964.17,497.00 951.32,454.41 918.75,415.00
             910.29,404.77 902.54,397.00 892.00,388.89
             863.07,366.62 836.40,361.50 801.00,358.91
             801.00,358.91 773.00,357.00 773.00,357.00
             773.00,357.00 750.00,356.00 750.00,356.00
             750.00,356.00 513.00,356.00 513.00,356.00
             513.00,356.00 513.00,1102.00 513.00,1102.00
             513.00,1102.00 635.00,1102.00 635.00,1102.00
             635.00,1102.00 635.00,821.00 635.00,821.00 Z
           M 635.00,483.00
           C 635.00,483.00 722.00,483.00 722.00,483.00
             722.00,483.00 734.00,483.96 734.00,483.96
             734.00,483.96 745.00,483.96 745.00,483.96
             745.00,483.96 755.00,484.91 755.00,484.91
             769.72,485.99 784.75,487.86 798.00,494.89
             860.73,528.14 862.28,640.59 803.00,677.58
             784.88,688.88 762.80,690.66 742.00,692.09
             742.00,692.09 730.00,693.00 730.00,693.00
             730.00,693.00 716.00,693.00 716.00,693.00
             716.00,693.00 701.00,694.00 701.00,694.00
             701.00,694.00 635.00,694.00 635.00,694.00
             635.00,694.00 635.00,483.00 635.00,483.00 Z"
          />
      );
    case SvgIconsConstants.g_IconSvgWc:
      return (
        <path
          {...props}
          d="M 5.00,13.00
           C 5.00,13.00 13.49,14.02 13.49,14.02
             13.49,14.02 17.14,22.00 17.14,22.00
             17.14,22.00 22.00,41.00 22.00,41.00
             28.39,34.72 28.01,19.08 32.09,14.57
             34.31,12.11 39.26,12.55 41.20,16.15
             41.20,16.15 49.00,42.00 49.00,42.00
             49.00,42.00 54.87,23.00 54.87,23.00
             54.87,23.00 58.51,14.02 58.51,14.02
             58.51,14.02 65.00,13.00 65.00,13.00
             65.00,13.00 55.72,46.00 55.72,46.00
             54.69,49.43 53.94,55.54 49.88,56.53
             45.44,57.60 43.55,54.58 42.30,50.98
             42.30,50.98 35.00,29.00 35.00,29.00
             35.00,29.00 29.14,48.00 29.14,48.00
             28.33,50.82 27.67,54.76 24.73,56.11
             18.95,58.74 16.74,53.41 15.42,49.00
             15.42,49.00 5.00,13.00 5.00,13.00 Z
           M 106.00,44.00
           C 104.84,47.02 104.00,49.30 101.66,51.67
             95.01,58.43 82.25,58.68 75.09,52.61
             70.20,48.46 68.17,42.23 68.01,36.00
             67.84,29.00 69.25,21.25 75.10,16.64
             79.44,13.22 83.73,12.89 89.00,13.01
             97.97,13.23 103.28,16.97 105.00,26.00
             105.00,26.00 96.00,27.00 96.00,27.00
             93.39,19.36 86.34,15.86 80.88,23.19
             75.88,29.89 76.74,46.90 86.00,49.76
             92.97,51.91 95.87,44.13 100.17,43.04
             102.20,42.52 104.13,43.36 106.00,44.00 Z"/>
        );
    case SvgIconsConstants.g_IconSvgWc_Stu_imkreis:
      return (
          <path
              {...props}
              d="M 701.00,0.42
           C 711.80,-0.89 714.77,0.96 724.00,1.00
             724.00,1.00 740.00,1.00 740.00,1.00
             740.00,1.00 752.00,2.00 752.00,2.00
             752.00,2.00 761.00,2.00 761.00,2.00
             761.00,2.00 788.00,4.84 788.00,4.84
             836.57,10.03 884.66,20.55 931.00,36.00
             1147.06,108.02 1316.31,278.09 1384.02,496.00
             1395.49,532.90 1403.76,569.76 1409.27,608.00
             1409.27,608.00 1414.09,654.00 1414.09,654.00
             1414.09,654.00 1415.00,664.00 1415.00,664.00
             1415.00,664.00 1416.00,684.00 1416.00,684.00
             1416.00,684.00 1416.00,731.00 1416.00,731.00
             1416.00,731.00 1415.00,752.00 1415.00,752.00
             1415.00,752.00 1414.09,762.00 1414.09,762.00
             1414.09,762.00 1409.42,806.00 1409.42,806.00
             1405.05,836.95 1399.15,867.91 1390.57,898.00
             1378.16,941.57 1362.13,984.51 1341.75,1025.00
             1261.29,1184.91 1119.00,1313.17 950.00,1373.31
             891.07,1394.28 836.16,1406.15 774.00,1412.17
             774.00,1412.17 749.00,1414.00 749.00,1414.00
             749.00,1414.00 733.00,1415.00 733.00,1415.00
             733.00,1415.00 685.00,1415.00 685.00,1415.00
             685.00,1415.00 665.00,1414.00 665.00,1414.00
             665.00,1414.00 655.00,1413.09 655.00,1413.09
             655.00,1413.09 597.00,1406.42 597.00,1406.42
             548.32,1398.87 501.01,1385.93 455.00,1368.42
             392.51,1344.65 326.26,1307.85 274.00,1266.20
             209.73,1214.98 158.27,1160.24 113.69,1091.00
             57.12,1003.14 20.91,903.56 7.28,800.00
             3.96,774.78 2.04,749.42 2.00,724.00
             2.00,724.00 1.00,709.00 1.00,709.00
             1.00,709.00 2.00,694.00 2.00,694.00
             2.00,694.00 2.00,675.00 2.00,675.00
             2.00,675.00 2.96,664.00 2.96,664.00
             2.96,664.00 8.73,606.00 8.73,606.00
             19.69,529.94 41.60,461.05 75.26,392.00
             87.56,366.76 102.32,342.49 117.66,319.00
             209.71,178.01 352.18,73.56 514.00,27.43
             556.96,15.18 600.58,7.47 645.00,3.17
             645.00,3.17 666.00,1.91 666.00,1.91
             666.00,1.91 678.00,1.00 678.00,1.00
             678.00,1.00 701.00,0.42 701.00,0.42 Z
           M 1185.00,427.00
           C 1185.00,427.00 1142.00,413.33 1142.00,413.33
             1138.80,412.29 1129.33,408.22 1126.56,410.06
             1124.62,411.35 1123.52,416.75 1122.86,419.00
             1121.53,423.50 1117.84,432.90 1115.68,437.00
             1104.87,457.54 1084.52,471.73 1061.00,472.00
             1053.25,472.08 1047.47,471.11 1040.00,469.12
             1029.62,466.34 1020.58,460.50 1013.00,453.00
             992.88,433.09 989.75,409.87 987.91,383.00
             987.91,383.00 987.00,364.00 987.00,364.00
             987.00,364.00 987.91,354.00 987.91,354.00
             990.03,323.15 996.49,294.51 1025.00,277.20
             1030.90,273.62 1035.40,271.95 1042.00,270.04
             1050.38,267.61 1058.39,267.90 1067.00,268.00
             1085.69,268.22 1104.19,279.73 1114.48,295.00
             1117.56,299.57 1119.06,302.87 1120.95,308.00
             1121.72,310.10 1122.93,314.73 1124.70,315.99
             1127.29,317.84 1135.89,314.88 1139.00,314.13
             1139.00,314.13 1168.00,307.35 1168.00,307.35
             1170.36,306.81 1180.18,304.54 1181.67,303.57
             1185.25,301.28 1182.70,296.10 1181.67,293.00
             1178.36,283.05 1173.68,272.77 1167.92,264.00
             1147.41,232.77 1108.81,215.43 1072.00,215.00
             1026.27,214.47 989.16,224.99 958.30,261.00
             931.08,292.76 923.53,337.41 924.00,378.00
             924.00,378.00 924.91,388.00 924.91,388.00
             928.18,435.53 944.65,476.75 986.00,503.92
             1003.33,515.31 1029.18,523.97 1050.00,524.00
             1050.00,524.00 1074.00,524.00 1074.00,524.00
             1101.15,523.68 1133.11,510.48 1151.96,491.00
             1161.34,481.31 1168.33,470.02 1174.24,458.00
             1178.37,449.60 1183.70,436.19 1185.00,427.00 Z
           M 575.00,220.00
           C 575.00,220.00 512.00,220.00 512.00,220.00
             512.00,220.00 519.12,251.00 519.12,251.00
             519.12,251.00 532.12,306.00 532.12,306.00
             532.12,306.00 566.13,450.00 566.13,450.00
             566.13,450.00 578.42,502.00 578.42,502.00
             579.13,505.20 581.24,516.27 583.56,517.98
             585.26,519.23 588.92,519.00 591.00,519.00
             591.00,519.00 650.00,519.00 650.00,519.00
             650.00,519.00 663.85,466.00 663.85,466.00
             663.85,466.00 685.12,386.00 685.12,386.00
             685.12,386.00 700.85,327.00 700.85,327.00
             700.85,327.00 709.00,298.00 709.00,298.00
             709.00,298.00 722.12,350.00 722.12,350.00
             722.12,350.00 753.37,468.00 753.37,468.00
             753.37,468.00 762.37,502.00 762.37,502.00
             762.37,502.00 767.14,517.43 767.14,517.43
             767.14,517.43 775.00,519.00 775.00,519.00
             775.00,519.00 833.00,519.00 833.00,519.00
             833.00,519.00 840.12,488.00 840.12,488.00
             840.12,488.00 853.13,434.00 853.13,434.00
             853.13,434.00 885.88,298.00 885.88,298.00
             885.88,298.00 899.42,242.00 899.42,242.00
             899.42,242.00 904.00,220.00 904.00,220.00
             904.00,220.00 851.00,220.00 851.00,220.00
             851.00,220.00 843.14,221.57 843.14,221.57
             843.14,221.57 839.58,235.00 839.58,235.00
             839.58,235.00 832.21,268.00 832.21,268.00
             832.21,268.00 809.42,372.00 809.42,372.00
             809.42,372.00 797.00,426.00 797.00,426.00
             797.00,426.00 791.87,403.00 791.87,403.00
             791.87,403.00 782.37,365.00 782.37,365.00
             782.37,365.00 757.63,266.00 757.63,266.00
             757.63,266.00 746.00,220.00 746.00,220.00
             746.00,220.00 673.00,220.00 673.00,220.00
             673.00,220.00 662.15,261.00 662.15,261.00
             662.15,261.00 644.88,326.00 644.88,326.00
             644.88,326.00 628.88,386.00 628.88,386.00
             628.88,386.00 619.00,422.00 619.00,422.00
             619.00,422.00 575.00,220.00 575.00,220.00 Z
           M 476.00,649.00
           C 488.25,647.35 501.17,641.99 511.00,634.50
             520.61,627.17 527.77,618.85 533.19,608.00
             551.02,572.34 538.34,527.93 504.00,507.45
             490.55,499.43 478.42,496.98 463.00,497.00
             448.20,497.02 433.13,501.68 421.00,510.21
             412.62,516.10 405.49,523.29 400.06,532.00
             392.00,544.97 387.83,559.74 388.00,575.00
             388.15,587.51 392.21,601.19 398.43,612.00
             402.70,619.43 409.58,624.97 410.71,633.00
             410.71,633.00 414.17,669.00 414.17,669.00
             414.17,669.00 422.17,749.00 422.17,749.00
             422.17,749.00 430.17,830.00 430.17,830.00
             430.17,830.00 435.02,878.00 435.02,878.00
             435.02,878.00 436.92,896.00 436.92,896.00
             436.92,896.00 438.83,915.00 438.83,915.00
             440.39,930.56 441.32,950.67 459.00,957.03
             462.10,958.15 464.78,957.99 468.00,958.00
             468.00,958.00 685.00,958.00 685.00,958.00
             687.27,958.00 690.66,957.78 692.61,959.02
             695.44,960.83 699.86,972.41 701.42,976.00
             701.42,976.00 725.30,1030.00 725.30,1030.00
             725.30,1030.00 769.23,1129.00 769.23,1129.00
             772.53,1136.23 779.49,1154.84 784.33,1159.67
             786.74,1162.09 790.81,1164.31 794.00,1165.53
             803.33,1169.10 814.65,1166.97 824.00,1164.63
             824.00,1164.63 864.00,1153.88 864.00,1153.88
             864.00,1153.88 894.00,1145.88 894.00,1145.88
             904.80,1143.04 914.10,1141.38 920.53,1131.00
             932.27,1112.07 921.16,1088.39 899.00,1085.30
             891.91,1084.31 880.14,1088.22 873.00,1090.15
             873.00,1090.15 826.00,1103.00 826.00,1103.00
             824.87,1096.96 820.94,1089.78 818.42,1084.00
             818.42,1084.00 802.86,1049.00 802.86,1049.00
             802.86,1049.00 754.70,940.00 754.70,940.00
             754.70,940.00 745.30,919.00 745.30,919.00
             738.76,904.48 736.29,895.03 718.00,895.00
             718.00,895.00 510.00,895.00 510.00,895.00
             507.84,895.00 504.24,895.22 502.43,893.98
             499.33,891.84 499.23,879.00 498.83,875.00
             498.83,875.00 493.00,820.00 493.00,820.00
             493.00,820.00 664.00,820.00 664.00,820.00
             669.81,819.99 675.01,819.82 680.00,816.35
             697.72,804.04 697.73,768.79 678.00,758.44
             673.51,756.08 668.91,756.01 664.00,756.00
             664.00,756.00 487.00,756.00 487.00,756.00
             487.00,756.00 480.17,688.00 480.17,688.00
             480.17,688.00 476.00,649.00 476.00,649.00 Z
           M 403.00,769.00
           C 395.43,771.04 388.03,775.24 381.00,778.75
             360.60,788.95 342.64,802.41 326.00,817.91
             248.13,890.45 225.87,1009.16 272.26,1105.00
             305.58,1173.85 367.30,1223.08 442.00,1239.58
             466.57,1245.00 484.16,1245.29 509.00,1245.00
             509.00,1245.00 528.00,1243.15 528.00,1243.15
             584.18,1236.44 638.39,1208.35 676.91,1167.00
             684.76,1158.57 691.73,1149.36 698.42,1140.00
             705.38,1130.26 709.13,1127.44 709.00,1115.00
             708.96,1111.60 708.86,1109.20 707.47,1106.00
             699.84,1088.41 677.36,1085.74 663.21,1102.00
             663.21,1102.00 656.62,1111.00 656.62,1111.00
             656.62,1111.00 639.83,1132.00 639.83,1132.00
             628.77,1144.25 610.27,1158.91 596.00,1167.40
             532.53,1205.16 453.42,1204.23 391.00,1164.68
             379.59,1157.45 364.37,1145.69 355.17,1135.96
             340.75,1120.71 328.37,1103.81 318.97,1085.00
             287.84,1022.75 293.14,949.30 330.43,891.00
             342.34,872.39 360.36,853.60 378.00,840.37
             384.41,835.57 391.05,831.12 398.00,827.15
             400.40,825.78 406.34,822.98 407.43,820.61
             408.36,818.59 406.95,806.86 406.58,804.00
             406.58,804.00 404.83,786.00 404.83,786.00
             404.83,786.00 403.00,769.00 403.00,769.00 Z"/>
      );
    case SvgIconsConstants.g_IconSvgTaub:
      return (
        <path
          {...props}
          d="M 93.00,15.04
           C 98.18,13.70 102.22,16.73 99.81,22.00
             98.43,25.00 87.93,35.56 85.00,37.22
             79.73,40.22 75.25,35.85 77.27,31.00
             78.94,26.99 89.21,17.53 93.00,15.04 Z
           M 40.00,27.52
           C 58.12,24.65 74.12,33.06 77.86,52.00
             78.67,56.87 79.04,59.07 77.86,64.00
             75.49,75.61 69.11,79.14 66.22,86.00
             64.44,90.25 65.05,92.76 64.86,97.00
             64.58,103.16 63.00,110.47 56.98,113.43
             53.31,115.24 41.43,116.63 43.84,109.14
             44.98,105.61 49.26,105.84 51.85,104.07
             55.22,101.78 54.97,98.62 55.00,95.00
             55.03,90.39 54.83,86.41 56.61,82.00
             60.39,72.66 76.48,61.30 64.61,46.00
             63.00,43.93 61.07,42.00 59.00,40.39
             56.15,38.18 52.61,36.71 49.00,36.34
             40.15,35.43 30.97,42.10 27.53,50.00
             26.06,53.38 25.61,64.82 19.22,61.72
             14.71,59.53 16.33,52.71 17.44,49.00
             20.88,37.43 28.50,30.61 40.00,27.52 Z
           M 44.00,43.53
           C 50.51,42.52 56.27,44.20 60.00,50.04
             61.81,52.87 64.46,61.32 58.95,61.97
             54.05,62.55 54.93,52.82 47.99,51.87
             40.38,50.84 40.39,62.84 35.10,61.95
             31.55,61.35 32.07,56.47 32.80,54.00
             34.60,47.92 38.21,45.34 44.00,43.53 Z
           M 32.00,76.91
           C 36.02,76.40 39.67,78.32 38.34,83.00
             37.51,85.93 33.21,89.71 31.09,92.00
             26.71,96.71 16.38,109.22 11.00,111.45
             6.35,113.38 2.72,110.09 3.84,106.00
             4.54,103.45 8.59,99.12 10.42,97.00
             15.18,91.49 25.85,80.05 32.00,76.91 Z"
        />
      );
    case SvgIconsConstants.g_IconSvgBlind:
      return (
        <path
          {...props}
          d="M 32.02,10.74
           C 36.03,9.76 40.32,9.47 43.58,12.57
             48.69,17.42 46.85,26.30 39.98,28.72
             28.75,32.69 19.58,17.60 32.02,10.74 Z
           M 19.00,51.00
           C 11.68,58.01 13.47,70.59 8.59,74.36
             5.70,76.59 2.76,74.62 2.66,70.96
             2.57,67.39 6.80,49.90 8.72,47.04
             10.43,44.51 14.54,41.25 17.00,39.26
             20.21,36.67 23.76,33.89 28.00,33.42
             29.92,33.20 32.10,33.60 34.00,33.92
             44.12,35.67 45.10,40.84 50.00,49.00
             50.00,49.00 62.51,70.00 62.51,70.00
             62.51,70.00 66.17,79.00 66.17,79.00
             66.17,79.00 76.85,98.00 76.85,98.00
             80.68,104.71 89.03,116.89 90.00,124.00
             90.00,124.00 86.61,122.41 86.61,122.41
             86.61,122.41 74.28,102.00 74.28,102.00
             74.28,102.00 63.10,83.04 63.10,83.04
             63.10,83.04 53.43,72.00 53.43,72.00
             53.43,72.00 40.00,53.00 40.00,53.00
             39.01,56.44 37.09,63.59 37.27,67.00
             37.45,70.28 39.85,75.64 41.00,79.00
             41.00,79.00 49.05,102.00 49.05,102.00
             50.28,105.36 54.50,116.00 54.45,118.98
             54.37,124.22 48.84,125.11 45.65,121.69
             43.60,119.50 38.87,106.70 37.28,103.00
             34.67,96.92 32.75,90.49 29.00,85.00
             29.00,100.92 27.18,103.45 20.41,118.00
             16.66,126.04 11.00,124.46 9.64,120.85
             8.64,118.21 10.26,114.49 11.19,112.00
             11.19,112.00 17.10,95.00 17.10,95.00
             17.10,95.00 17.10,71.00 17.10,71.00
             17.10,71.00 19.00,51.00 19.00,51.00 Z"/>
        );
    case SvgIconsConstants.g_IconSvgAufzug:
      return (
        <path
          {...props}
          d="M 41.00,31.00
           C 43.44,25.85 47.90,21.92 52.00,18.02
             53.51,16.58 55.76,14.20 58.00,14.20
             60.24,14.20 62.49,16.58 64.00,18.02
             68.29,22.10 73.30,26.19 75.00,32.00
             75.00,32.00 41.00,31.00 41.00,31.00 Z
           M 38.00,15.00
           C 35.56,20.17 30.25,25.08 26.00,28.94
             24.00,30.76 21.92,32.84 19.00,31.83
             16.62,31.01 8.98,23.20 7.15,21.00
             6.05,19.67 4.15,17.46 5.17,15.60
             6.24,13.64 11.07,14.00 13.00,14.00
             19.82,13.99 31.88,13.26 38.00,15.00 Z
           M 10.11,55.02
           C 15.35,53.19 24.22,54.00 30.00,54.00
             30.00,54.00 67.00,54.00 67.00,54.00
             77.23,54.07 77.99,57.77 78.00,67.00
             78.00,67.00 78.00,99.00 78.00,99.00
             78.00,102.96 78.61,109.64 76.26,112.85
             73.68,116.39 69.88,115.99 66.00,116.00
             66.00,116.00 18.00,116.00 18.00,116.00
             13.63,115.99 9.61,116.39 7.17,111.94
             5.80,109.42 6.00,105.82 6.00,103.00
             6.00,103.00 6.00,66.00 6.00,66.00
             6.03,60.71 5.75,58.55 10.11,55.02 Z
           M 38.00,71.00
           C 38.00,71.00 15.00,71.00 15.00,71.00
             15.00,71.00 15.00,108.00 15.00,108.00
             15.00,108.00 38.00,108.00 38.00,108.00
             38.00,108.00 38.00,71.00 38.00,71.00 Z
           M 69.00,71.00
           C 69.00,71.00 46.00,71.00 46.00,71.00
             46.00,71.00 46.00,108.00 46.00,108.00
             46.00,108.00 69.00,108.00 69.00,108.00
             69.00,108.00 69.00,71.00 69.00,71.00 Z"/>
        );
    case SvgIconsConstants.g_IconSvgKinderwagen:
      return (
        <path
          {...props}
            d="M 0.00,192.00
           C 0.00,192.00 0.00,178.00 0.00,178.00
             0.01,170.52 1.52,160.50 2.75,153.00
             9.93,109.20 29.09,71.77 57.59,38.00
             63.41,31.10 75.07,19.67 82.00,13.73
             90.89,6.11 97.69,0.15 110.00,0.00
             115.14,-0.06 120.02,-0.28 125.00,1.21
             142.37,6.40 150.22,23.87 159.31,38.00
             159.31,38.00 224.68,141.00 224.68,141.00
             224.68,141.00 244.34,172.00 244.34,172.00
             247.78,177.29 254.21,186.38 256.00,192.00
             256.00,192.00 0.00,192.00 0.00,192.00 Z
           M 384.00,224.00
           C 384.00,224.00 384.00,178.00 384.00,178.00
             384.00,178.00 384.00,156.00 384.00,156.00
             384.03,138.09 393.78,119.67 408.00,108.90
             424.93,96.08 436.10,96.00 456.00,96.00
             456.00,96.00 482.00,96.00 482.00,96.00
             492.32,96.00 507.35,93.26 511.35,106.00
             512.11,108.41 512.00,111.47 512.00,114.00
             512.00,114.00 512.00,144.00 512.00,144.00
             511.86,154.47 506.47,159.86 496.00,160.00
             496.00,160.00 448.00,160.00 448.00,160.00
             448.00,160.00 448.00,234.00 448.00,234.00
             447.98,244.07 445.81,255.22 443.37,265.00
             434.07,302.38 415.93,328.07 389.00,355.00
             416.38,359.23 439.57,385.98 446.11,412.00
             448.56,421.77 448.11,429.06 448.00,439.00
             447.91,446.19 446.02,453.25 443.66,460.00
             427.40,506.54 372.68,524.90 330.00,503.24
             317.59,496.95 305.86,486.06 298.87,474.00
             288.95,456.86 287.91,446.13 288.00,427.00
             288.04,418.69 289.44,415.75 291.00,408.00
             273.55,411.51 255.93,415.97 238.00,416.00
             238.00,416.00 210.00,416.00 210.00,416.00
             192.07,415.97 174.45,411.51 157.00,408.00
             158.35,414.74 159.91,418.89 160.00,426.00
             160.22,445.38 159.37,455.48 149.69,473.00
             143.21,484.72 130.89,496.59 119.00,502.74
             76.94,524.50 23.72,508.09 5.45,463.00
             -0.48,448.35 -0.17,440.26 0.00,425.00
             0.26,402.73 15.18,379.01 33.00,366.44
             40.52,361.14 49.85,356.41 59.00,355.00
             32.22,328.22 13.88,302.18 4.63,265.00
             2.19,255.22 0.02,244.07 0.00,234.00
             0.00,234.00 0.00,224.00 0.00,224.00
             0.00,224.00 384.00,224.00 384.00,224.00 Z
           M 74.00,401.30
           C 44.18,407.13 39.46,448.23 67.00,460.65
             72.44,463.10 80.13,463.57 86.00,462.70
             107.74,459.49 117.99,432.86 106.45,415.00
             98.63,402.89 87.58,399.94 74.00,401.30 Z
           M 362.00,401.30
           C 332.18,407.13 327.46,448.23 355.00,460.65
             360.44,463.10 368.13,463.57 374.00,462.70
             395.74,459.49 405.99,432.86 394.45,415.00
             386.63,402.89 375.58,399.94 362.00,401.30 Z"
          />
      );
    case SvgIconsConstants.g_IconSvgKinderwagen_Stu_imkreis:
      return (
        <path
          {...props}
            d="M 701.00,0.42
           C 711.80,-0.89 714.77,0.96 724.00,1.00
             724.00,1.00 740.00,1.00 740.00,1.00
             740.00,1.00 752.00,2.00 752.00,2.00
             752.00,2.00 761.00,2.00 761.00,2.00
             761.00,2.00 788.00,4.84 788.00,4.84
             836.57,10.03 884.66,20.55 931.00,36.00
             1147.06,108.02 1316.31,278.09 1384.02,496.00
             1395.49,532.90 1403.76,569.76 1409.27,608.00
             1409.27,608.00 1414.09,654.00 1414.09,654.00
             1414.09,654.00 1415.00,664.00 1415.00,664.00
             1415.00,664.00 1416.00,684.00 1416.00,684.00
             1416.00,684.00 1416.00,731.00 1416.00,731.00
             1416.00,731.00 1415.00,752.00 1415.00,752.00
             1415.00,752.00 1414.09,762.00 1414.09,762.00
             1414.09,762.00 1409.42,806.00 1409.42,806.00
             1405.05,836.95 1399.15,867.91 1390.57,898.00
             1378.16,941.57 1362.13,984.51 1341.75,1025.00
             1261.29,1184.91 1119.00,1313.17 950.00,1373.31
             891.07,1394.28 836.16,1406.15 774.00,1412.17
             774.00,1412.17 749.00,1414.00 749.00,1414.00
             749.00,1414.00 733.00,1415.00 733.00,1415.00
             733.00,1415.00 685.00,1415.00 685.00,1415.00
             685.00,1415.00 665.00,1414.00 665.00,1414.00
             665.00,1414.00 655.00,1413.09 655.00,1413.09
             655.00,1413.09 597.00,1406.42 597.00,1406.42
             548.32,1398.87 501.01,1385.93 455.00,1368.42
             392.51,1344.65 326.26,1307.85 274.00,1266.20
             209.73,1214.98 158.27,1160.24 113.69,1091.00
             57.12,1003.14 20.91,903.56 7.28,800.00
             3.96,774.78 2.04,749.42 2.00,724.00
             2.00,724.00 1.00,709.00 1.00,709.00
             1.00,709.00 2.00,694.00 2.00,694.00
             2.00,694.00 2.00,675.00 2.00,675.00
             2.00,675.00 2.96,664.00 2.96,664.00
             2.96,664.00 8.73,606.00 8.73,606.00
             19.69,529.94 41.60,461.05 75.26,392.00
             87.56,366.76 102.32,342.49 117.66,319.00
             209.71,178.01 352.18,73.56 514.00,27.43
             556.96,15.18 600.58,7.47 645.00,3.17
             645.00,3.17 666.00,1.91 666.00,1.91
             666.00,1.91 678.00,1.00 678.00,1.00
             678.00,1.00 701.00,0.42 701.00,0.42 Z
           M 637.00,247.90
           C 637.00,247.90 539.00,247.90 539.00,247.90
             539.00,247.90 520.00,247.90 520.00,247.90
             520.00,247.90 508.00,247.90 508.00,247.90
             508.00,247.90 501.00,247.90 501.00,247.90
             482.39,248.21 464.07,250.96 446.00,255.47
             358.59,277.33 288.23,346.61 263.58,433.00
             263.58,433.00 256.11,467.99 256.11,467.99
             254.98,474.67 254.04,472.03 254.00,480.00
             254.00,480.00 254.00,490.00 254.00,490.00
             254.00,490.00 637.00,490.00 637.00,490.00
             637.00,490.00 637.00,247.90 637.00,247.90 Z
           M 254.00,519.00
           C 254.00,519.00 254.00,636.00 254.00,636.00
             254.00,675.44 252.26,705.90 269.31,743.00
             289.20,786.30 331.52,818.76 378.00,828.20
             385.57,829.74 393.26,830.99 401.00,831.00
             401.00,831.00 470.00,831.00 470.00,831.00
             470.00,831.00 711.00,831.00 711.00,831.00
             711.00,831.00 935.00,831.00 935.00,831.00
             943.76,830.99 952.48,829.33 961.00,827.42
             1010.57,816.33 1051.32,781.07 1070.20,734.00
             1074.92,722.23 1080.98,700.51 1081.00,688.00
             1081.00,688.00 1081.00,595.00 1081.00,595.00
             1081.01,592.35 1080.81,589.43 1082.02,587.00
             1083.80,583.44 1100.95,567.05 1105.00,563.00
             1105.00,563.00 1177.00,491.00 1177.00,491.00
             1184.00,484.00 1199.75,470.05 1202.36,461.00
             1203.08,458.51 1203.02,455.58 1203.00,453.00
             1202.94,447.54 1202.38,444.55 1199.06,440.01
             1197.31,437.62 1195.48,435.75 1192.99,434.13
             1185.11,429.02 1173.70,429.27 1166.00,434.65
             1162.75,436.91 1154.31,445.69 1151.00,449.00
             1151.00,449.00 1098.00,502.00 1098.00,502.00
             1094.27,505.73 1083.08,517.90 1079.00,519.40
             1077.06,520.12 1074.09,520.00 1072.00,520.00
             1072.00,520.00 799.00,520.00 799.00,520.00
             799.00,520.00 644.00,520.00 644.00,520.00
             644.00,520.00 629.00,519.00 629.00,519.00
             629.00,519.00 254.00,519.00 254.00,519.00 Z
           M 474.00,860.14
           C 474.00,860.14 463.00,861.28 463.00,861.28
             452.19,862.58 441.33,864.91 431.00,868.35
             392.42,881.22 358.29,911.19 340.89,948.00
             314.23,1004.38 323.11,1073.44 365.17,1120.00
             380.49,1136.97 397.31,1148.79 418.00,1158.23
             432.16,1164.69 454.44,1170.81 470.00,1171.00
             493.03,1171.27 509.73,1171.07 532.00,1163.31
             597.92,1140.31 637.32,1080.95 637.00,1012.00
             636.96,1003.70 635.24,992.22 633.60,984.00
             619.92,915.63 562.16,865.96 493.00,860.91
             493.00,860.91 482.00,860.14 482.00,860.14
             482.00,860.14 474.00,860.14 474.00,860.14 Z
           M 842.00,860.28
           C 842.00,860.28 831.00,861.28 831.00,861.28
             820.32,862.57 808.15,865.15 798.00,868.69
             757.31,882.89 723.38,914.35 706.43,954.00
             682.43,1010.14 693.31,1075.60 734.17,1121.00
             747.97,1136.34 763.68,1147.11 782.00,1156.25
             797.08,1163.77 821.13,1170.80 838.00,1171.00
             854.23,1171.19 870.10,1171.10 886.00,1167.35
             931.31,1156.64 971.26,1124.40 990.69,1082.00
             1019.09,1020.03 1004.85,946.09 954.00,900.17
             936.86,884.69 917.77,874.56 896.00,867.33
             881.56,862.54 857.10,858.88 842.00,860.28 Z"
          />
      );
    case SvgIconsConstants.g_IconSvgRollstuhl:
      return (
        <path
          {...props}
          d="M 63.00,37.00
                C 53.41,30.82 58.31,16.26 70.00,18.23
                  78.14,19.60 81.05,29.76 75.43,35.58
                  71.95,39.19 67.48,38.49 63.00,39.00
                  73.92,45.92 59.62,56.52 55.00,62.79
                  55.00,62.79 69.00,62.79 69.00,62.79
                  72.26,62.15 76.77,61.30 79.49,63.74
                  82.20,66.17 81.26,69.83 81.08,73.00
                  81.08,73.00 79.27,96.00 79.27,96.00
                  78.80,99.15 78.60,102.90 74.89,103.92
                  65.42,106.54 68.39,91.59 68.83,87.00
                  68.83,87.00 70.00,74.00 70.00,74.00
                  70.00,74.00 61.00,74.00 61.00,74.00
                  63.86,82.75 65.60,90.02 62.07,99.00
                  60.36,103.36 57.43,110.10 52.16,104.84
                  46.57,99.25 54.28,95.95 53.61,87.00
                  52.87,77.00 42.16,67.19 32.00,68.20
                  27.61,68.63 23.82,70.99 20.00,73.00
                  20.00,73.00 13.00,65.00 13.00,65.00
                  13.00,65.00 20.00,60.99 20.00,60.99
                  20.00,60.99 28.00,57.44 28.00,57.44
                  28.00,57.44 44.00,40.00 44.00,40.00
                  41.54,39.29 37.13,37.08 35.00,37.48
                  29.89,38.44 24.37,48.61 18.23,44.97
                  17.09,44.30 16.22,43.21 15.84,41.94
                  14.48,37.33 20.05,33.32 23.00,30.72
                  25.70,28.34 29.45,24.19 33.00,23.48
                  36.45,22.79 40.11,25.22 43.00,26.85
                  48.51,29.97 57.12,36.05 63.00,37.00 Z
                M 8.00,67.00
                C 20.82,73.07 13.51,77.78 14.04,88.00
                  14.34,93.66 17.00,98.03 21.04,101.90
                  25.53,106.19 30.77,108.34 37.00,107.90
                  42.50,107.52 44.75,105.06 48.00,106.23
                  51.02,107.31 53.21,110.52 55.00,113.00
                  37.07,125.70 11.32,118.21 4.10,97.00
                  0.36,86.01 2.90,76.94 8.00,67.00 Z"
        />
      );
    case SvgIconsConstants.g_IconSvgRollstuhl_Stu_imkreis:
      return (
          <path
              {...props}
              d="M 701.00,0.42
           C 711.80,-0.89 714.77,0.96 724.00,1.00
             724.00,1.00 740.00,1.00 740.00,1.00
             740.00,1.00 752.00,2.00 752.00,2.00
             752.00,2.00 761.00,2.00 761.00,2.00
             761.00,2.00 788.00,4.84 788.00,4.84
             836.57,10.03 884.66,20.55 931.00,36.00
             1147.06,108.02 1316.31,278.09 1384.02,496.00
             1395.49,532.90 1403.76,569.76 1409.27,608.00
             1409.27,608.00 1414.09,654.00 1414.09,654.00
             1414.09,654.00 1415.00,664.00 1415.00,664.00
             1415.00,664.00 1416.00,684.00 1416.00,684.00
             1416.00,684.00 1416.00,731.00 1416.00,731.00
             1416.00,731.00 1415.00,752.00 1415.00,752.00
             1415.00,752.00 1414.09,762.00 1414.09,762.00
             1414.09,762.00 1409.42,806.00 1409.42,806.00
             1405.05,836.95 1399.15,867.91 1390.57,898.00
             1378.16,941.57 1362.13,984.51 1341.75,1025.00
             1261.29,1184.91 1119.00,1313.17 950.00,1373.31
             891.07,1394.28 836.16,1406.15 774.00,1412.17
             774.00,1412.17 749.00,1414.00 749.00,1414.00
             749.00,1414.00 733.00,1415.00 733.00,1415.00
             733.00,1415.00 685.00,1415.00 685.00,1415.00
             685.00,1415.00 665.00,1414.00 665.00,1414.00
             665.00,1414.00 655.00,1413.09 655.00,1413.09
             655.00,1413.09 597.00,1406.42 597.00,1406.42
             548.32,1398.87 501.01,1385.93 455.00,1368.42
             392.51,1344.65 326.26,1307.85 274.00,1266.20
             209.73,1214.98 158.27,1160.24 113.69,1091.00
             57.12,1003.14 20.91,903.56 7.28,800.00
             3.96,774.78 2.04,749.42 2.00,724.00
             2.00,724.00 1.00,709.00 1.00,709.00
             1.00,709.00 2.00,694.00 2.00,694.00
             2.00,694.00 2.00,675.00 2.00,675.00
             2.00,675.00 2.96,664.00 2.96,664.00
             2.96,664.00 8.73,606.00 8.73,606.00
             19.69,529.94 41.60,461.05 75.26,392.00
             87.56,366.76 102.32,342.49 117.66,319.00
             209.71,178.01 352.18,73.56 514.00,27.43
             556.96,15.18 600.58,7.47 645.00,3.17
             645.00,3.17 666.00,1.91 666.00,1.91
             666.00,1.91 678.00,1.00 678.00,1.00
             678.00,1.00 701.00,0.42 701.00,0.42 Z
           M 555.00,391.00
           C 565.88,390.11 577.28,386.09 587.00,381.25
             638.06,355.78 661.34,297.36 640.94,244.00
             623.74,199.04 582.36,173.45 535.00,174.00
             521.11,174.17 501.30,179.40 489.00,185.78
             471.23,195.01 457.97,207.33 447.00,224.00
             437.54,238.38 430.08,259.68 430.00,277.00
             430.00,277.00 430.00,290.00 430.00,290.00
             430.20,307.01 437.20,326.61 446.00,341.00
             450.46,348.29 460.96,358.44 461.83,366.00
             461.83,366.00 469.17,442.00 469.17,442.00
             469.17,442.00 487.83,628.00 487.83,628.00
             487.83,628.00 493.83,688.00 493.83,688.00
             493.83,688.00 496.42,715.00 496.42,715.00
             496.42,715.00 501.83,769.00 501.83,769.00
             502.85,779.15 503.42,793.64 506.52,803.00
             512.60,821.38 524.12,832.97 544.00,833.00
             544.00,833.00 867.00,833.00 867.00,833.00
             868.19,839.34 874.72,852.43 877.58,859.00
             877.58,859.00 900.70,911.00 900.70,911.00
             900.70,911.00 936.86,993.00 936.86,993.00
             936.86,993.00 970.58,1069.00 970.58,1069.00
             970.58,1069.00 985.70,1103.00 985.70,1103.00
             988.65,1109.55 990.96,1116.30 996.30,1121.38
             1004.91,1129.58 1017.50,1132.13 1029.00,1132.00
             1039.84,1131.87 1060.82,1125.53 1072.00,1122.42
             1072.00,1122.42 1129.00,1107.15 1129.00,1107.15
             1140.18,1104.12 1168.60,1097.72 1177.00,1092.53
             1189.70,1084.69 1197.54,1069.91 1196.96,1055.00
             1196.21,1035.74 1182.08,1018.91 1163.00,1015.23
             1159.78,1014.88 1156.27,1014.97 1153.00,1015.23
             1143.43,1015.12 1124.97,1021.15 1115.00,1023.85
             1115.00,1023.85 1056.00,1040.00 1056.00,1040.00
             1056.00,1040.00 959.42,822.00 959.42,822.00
             959.42,822.00 938.15,774.00 938.15,774.00
             933.37,762.86 929.54,752.03 918.00,745.98
             910.72,742.17 909.12,743.51 902.00,742.83
             898.84,742.53 897.42,741.94 894.00,742.18
             894.00,742.18 883.00,743.00 883.00,743.00
             883.00,743.00 813.00,743.00 813.00,743.00
             813.00,743.00 796.00,742.00 796.00,742.00
             796.00,742.00 590.00,742.00 590.00,742.00
             590.00,742.00 582.83,667.00 582.83,667.00
             582.83,667.00 580.00,635.00 580.00,635.00
             580.00,635.00 829.00,635.00 829.00,635.00
             877.02,634.93 877.02,545.07 829.00,545.00
             829.00,545.00 570.00,545.00 570.00,545.00
             570.00,545.00 563.17,471.00 563.17,471.00
             563.17,471.00 555.00,391.00 555.00,391.00 Z
           M 450.00,563.00
           C 440.69,565.51 422.85,574.84 414.00,579.70
             388.76,593.55 366.21,609.40 345.00,628.92
             318.03,653.72 294.68,682.12 276.43,714.00
             217.86,816.30 214.87,940.09 265.78,1046.00
             307.55,1132.90 388.83,1200.81 481.00,1228.28
             506.53,1235.89 532.47,1240.27 559.00,1242.09
             559.00,1242.09 571.00,1243.00 571.00,1243.00
             571.00,1243.00 597.00,1243.00 597.00,1243.00
             597.00,1243.00 607.00,1242.09 607.00,1242.09
             607.00,1242.09 628.00,1240.15 628.00,1240.15
             707.79,1230.62 779.41,1193.68 836.00,1137.00
             850.87,1122.11 863.89,1105.40 875.67,1088.00
             882.29,1078.23 887.84,1073.81 888.00,1061.00
             888.10,1052.62 887.74,1047.43 883.24,1040.00
             872.54,1022.32 848.62,1017.76 832.00,1029.93
             822.48,1036.90 820.36,1042.11 813.85,1051.00
             813.85,1051.00 790.72,1080.00 790.72,1080.00
             771.79,1101.51 742.27,1123.99 717.00,1137.69
             637.69,1180.68 542.21,1183.05 461.00,1143.74
             434.16,1130.75 409.79,1113.21 388.00,1092.91
             366.64,1073.01 347.19,1045.67 333.31,1020.00
             317.87,991.45 307.20,955.21 303.28,923.00
             303.28,923.00 301.91,902.00 301.91,902.00
             301.91,902.00 301.00,892.00 301.00,892.00
             301.00,892.00 301.00,888.00 301.00,888.00
             301.00,888.00 302.00,877.00 302.00,877.00
             302.00,877.00 302.00,868.00 302.00,868.00
             302.00,868.00 304.28,849.00 304.28,849.00
             309.75,810.44 326.49,765.96 348.72,734.00
             368.65,705.34 388.77,683.52 417.00,662.73
             426.30,655.89 435.98,649.73 446.00,644.00
             448.68,642.47 456.31,639.15 457.41,636.61
             458.30,634.58 456.37,621.15 456.07,618.00
             456.07,618.00 452.28,581.00 452.28,581.00
             451.59,575.25 451.58,568.56 450.00,563.00 Z"
          />
      );
    case SvgIconsConstants.g_IconResultSetSvgOrt:
      return (
        <path
          {...props}
          d="M 0.00,0.00
           C 0.00,0.00 77.00,0.00 77.00,0.00
             77.00,0.00 77.00,53.00 77.00,53.00
             77.00,53.00 0.00,53.00 0.00,53.00
             0.00,53.00 0.00,0.00 0.00,0.00 Z
           M 20.00,2.30
           C 9.06,4.46 1.86,13.73 4.53,25.00
             5.55,29.31 8.56,33.35 11.00,37.00
             13.12,40.16 19.45,51.60 23.83,49.83
             26.19,48.88 31.04,41.37 32.66,39.00
             35.61,34.66 39.77,29.19 40.67,24.00
             42.94,10.97 32.85,1.30 20.00,2.30 Z
           M 15.00,17.00
           C 15.65,15.12 15.93,13.82 17.42,12.31
             24.54,5.08 36.97,16.69 25.95,24.11
             24.14,25.33 23.01,25.50 21.00,26.00
             22.02,19.88 24.75,20.91 25.55,17.86
             27.22,11.48 17.16,16.67 15.00,17.00 Z
           M 60.00,41.00
           C 64.05,39.08 75.57,30.03 73.83,25.00
             72.46,21.03 63.83,13.81 60.00,12.00
             58.39,17.65 58.39,35.35 60.00,41.00 Z"
        />
      );
    case SvgIconsConstants.g_IconResultSetSvgOrt_Filled:
      return (
        <path
          {...props}
          d="M 18.00,3.53
           C 28.89,1.74 39.07,7.14 39.90,19.00
             40.44,26.70 37.06,30.94 33.00,37.00
             31.13,39.79 25.16,50.53 21.28,48.83
             19.23,47.93 14.78,41.10 13.34,39.00
             6.26,28.58 -0.87,16.40 12.02,6.42
             14.22,4.71 15.45,4.35 18.00,3.53 Z
           M 26.00,19.00
           C 18.04,22.23 20.50,26.28 22.98,26.43
             24.47,26.53 26.05,25.37 27.11,24.44
             35.67,16.98 28.09,6.65 20.02,9.60
             18.54,10.14 17.45,10.97 16.42,12.15
             14.96,13.82 14.68,15.01 14.00,17.00
             21.78,18.62 22.02,11.50 26.00,19.00 Z
           M 60.00,13.00
           C 65.83,15.76 71.27,21.78 75.00,27.00
             70.37,31.63 65.98,37.17 60.00,40.00
             60.00,40.00 60.00,13.00 60.00,13.00 Z
           M 22.09,29.91
           C 17.25,32.71 21.36,36.02 23.69,34.83
             25.78,33.76 26.12,30.77 22.09,29.91 Z"
        />
      );
    case SvgIconsConstants.g_IconResultSetNeu:
      return (
        <path
          {...props}
          d="M 5.02,1.02
           C 10.69,-0.89 26.21,0.00 33.00,0.00
             33.00,0.00 91.00,0.00 91.00,0.00
             91.00,0.00 285.00,0.00 285.00,0.00
             296.80,0.02 297.80,3.80 306.00,12.00
             306.00,12.00 324.00,30.00 324.00,30.00
             324.00,30.00 417.00,123.00 417.00,123.00
             417.00,123.00 488.00,194.00 488.00,194.00
             488.00,194.00 503.00,209.00 503.00,209.00
             505.67,211.67 509.93,215.50 511.26,219.00
             512.13,221.28 512.00,224.55 512.00,227.00
             512.00,227.00 512.00,499.00 512.00,499.00
             512.00,501.06 512.11,504.10 511.40,506.00
             509.16,511.96 501.45,513.34 496.00,511.26
             492.21,509.82 486.99,503.99 484.00,501.00
             484.00,501.00 408.00,425.00 408.00,425.00
             408.00,425.00 107.00,124.00 107.00,124.00
             107.00,124.00 20.00,37.00 20.00,37.00
             16.16,33.16 3.09,20.82 1.17,17.00
             -1.72,11.22 -0.12,4.72 5.02,1.02 Z
           M 250.00,141.00
           C 250.00,141.00 264.65,73.00 264.65,73.00
             264.65,73.00 268.79,54.00 268.79,54.00
             268.79,54.00 269.68,45.00 269.68,45.00
             269.68,45.00 255.00,29.06 255.00,29.06
             255.00,29.06 249.00,26.34 249.00,26.34
             249.00,26.34 242.00,32.00 242.00,32.00
             242.00,32.00 227.00,46.91 227.00,46.91
             227.00,46.91 186.00,87.00 186.00,87.00
             186.00,87.00 164.00,110.00 164.00,110.00
             164.00,110.00 176.00,121.98 176.00,121.98
             176.00,121.98 182.00,125.80 182.00,125.80
             182.00,125.80 188.00,121.98 188.00,121.98
             188.00,121.98 199.00,111.00 199.00,111.00
             199.00,111.00 243.00,68.00 243.00,68.00
             243.00,68.00 227.35,138.00 227.35,138.00
             227.35,138.00 223.00,158.00 223.00,158.00
             223.00,158.00 221.77,167.00 221.77,167.00
             221.77,167.00 227.00,174.00 227.00,174.00
             227.00,174.00 241.00,187.00 241.00,187.00
             241.00,187.00 290.00,138.01 290.00,138.01
             290.00,138.01 304.00,125.00 304.00,125.00
             304.00,125.00 320.00,109.00 320.00,109.00
             321.63,107.33 324.80,104.49 324.80,102.00
             324.80,99.76 322.42,97.51 320.98,96.00
             317.39,92.22 310.67,82.85 305.00,86.49
             305.00,86.49 289.00,102.00 289.00,102.00
             289.00,102.00 263.00,128.00 263.00,128.00
             263.00,128.00 250.00,141.00 250.00,141.00 Z
           M 347.00,122.00
           C 347.00,122.00 321.00,146.00 321.00,146.00
             321.00,146.00 284.00,183.00 284.00,183.00
             284.00,183.00 268.00,198.83 268.00,198.83
             266.12,200.53 261.96,203.59 261.34,206.01
             260.35,209.85 268.43,216.43 271.00,219.00
             271.00,219.00 300.00,248.00 300.00,248.00
             302.48,250.48 308.26,257.60 312.00,256.66
             314.65,256.00 323.94,246.67 324.55,244.00
             325.01,241.97 323.93,240.52 322.76,239.00
             322.76,239.00 304.00,220.00 304.00,220.00
             302.10,218.10 297.04,213.73 297.04,211.00
             297.04,208.07 303.85,202.15 306.00,200.00
             307.52,198.48 311.15,194.62 313.00,193.91
             316.71,192.50 320.68,197.66 323.00,200.00
             323.00,200.00 337.00,214.94 337.00,214.94
             339.00,216.76 341.08,218.84 344.00,217.83
             346.08,217.11 349.38,213.61 351.00,212.00
             353.84,209.16 355.65,207.28 358.00,204.00
             358.00,204.00 330.00,175.00 330.00,175.00
             330.00,175.00 350.00,157.00 350.00,157.00
             354.08,162.70 374.30,182.92 380.00,187.00
             380.00,187.00 395.00,173.00 395.00,173.00
             392.01,166.67 383.17,159.17 378.00,154.00
             378.00,154.00 347.00,122.00 347.00,122.00 Z
           M 480.00,258.00
           C 480.00,258.00 467.00,244.02 467.00,244.02
             465.45,242.55 463.30,240.30 461.00,240.34
             457.34,240.39 446.97,252.03 444.00,255.00
             444.00,255.00 419.00,280.00 419.00,280.00
             413.08,285.92 406.43,293.12 399.00,297.09
             381.14,306.62 366.13,286.77 367.20,276.00
             368.06,267.22 375.11,259.89 381.00,254.00
             381.00,254.00 411.00,224.00 411.00,224.00
             411.00,224.00 423.94,211.28 423.94,211.28
             425.81,209.47 427.86,207.87 426.83,204.99
             426.26,203.37 416.59,192.97 414.72,191.06
             413.14,189.47 411.46,187.72 409.00,188.34
             406.87,188.87 403.59,192.42 402.00,194.00
             402.00,194.00 388.00,208.00 388.00,208.00
             388.00,208.00 365.00,231.00 365.00,231.00
             352.85,243.15 339.00,257.21 340.09,276.00
             341.45,299.26 367.54,325.45 391.00,325.99
             417.33,326.59 436.83,300.77 454.00,284.01
             462.30,275.92 473.27,267.40 480.00,258.00 Z"
        />
      );
    case SvgIconsConstants.g_IconResultSetNeu_left:
      return(<path {...props}
        d="M 0.08,39.61
           C -0.07,39.16 0.00,37.95 0.00,37.42
             0.00,37.42 0.00,32.89 0.00,32.89
             0.00,32.89 0.00,17.73 0.00,17.73
             0.00,16.81 0.30,16.73 0.94,16.09
             0.94,16.09 2.34,14.69 2.34,14.69
             2.34,14.69 9.61,7.42 9.61,7.42
             9.61,7.42 15.16,1.88 15.16,1.88
             15.16,1.88 16.33,0.70 16.33,0.70
             16.54,0.49 16.84,0.16 17.11,0.06
             17.29,-0.01 17.54,0.00 17.73,0.00
             17.73,0.00 38.98,0.00 38.98,0.00
             39.15,0.00 39.38,-0.01 39.53,0.05
             40.00,0.22 40.10,0.82 39.94,1.25
             39.83,1.55 39.37,1.95 39.14,2.19
             39.14,2.19 33.20,8.13 33.20,8.13
             33.20,8.13 9.69,31.64 9.69,31.64
             9.69,31.64 2.89,38.44 2.89,38.44
             2.59,38.74 1.63,39.76 1.33,39.91
             0.88,40.13 0.37,40.01 0.08,39.61 Z
           M 11.02,20.47
           C 11.02,20.47 5.70,19.32 5.70,19.32
             5.70,19.32 4.22,19.00 4.22,19.00
             4.22,19.00 3.52,18.93 3.52,18.93
             3.52,18.93 2.27,20.08 2.27,20.08
             2.27,20.08 2.06,20.55 2.06,20.55
             2.06,20.55 2.50,21.09 2.50,21.09
             2.50,21.09 3.66,22.27 3.66,22.27
             3.66,22.27 6.80,25.47 6.80,25.47
             6.80,25.47 8.59,27.19 8.59,27.19
             8.59,27.19 9.53,26.25 9.53,26.25
             9.53,26.25 9.83,25.78 9.83,25.78
             9.83,25.78 9.53,25.31 9.53,25.31
             9.53,25.31 8.67,24.45 8.67,24.45
             8.67,24.45 5.31,21.02 5.31,21.02
             5.31,21.02 10.78,22.24 10.78,22.24
             10.78,22.24 12.34,22.58 12.34,22.58
             12.34,22.58 13.05,22.67 13.05,22.67
             13.05,22.67 13.59,22.27 13.59,22.27
             13.59,22.27 14.61,21.17 14.61,21.17
             14.61,21.17 10.78,17.34 10.78,17.34
             10.78,17.34 9.77,16.25 9.77,16.25
             9.77,16.25 8.52,15.00 8.52,15.00
             8.39,14.87 8.16,14.62 7.97,14.62
             7.79,14.62 7.62,14.81 7.50,14.92
             7.20,15.20 6.47,15.73 6.76,16.17
             6.76,16.17 7.97,17.42 7.97,17.42
             7.97,17.42 10.00,19.45 10.00,19.45
             10.00,19.45 11.02,20.47 11.02,20.47 Z
           M 9.53,12.89
           C 9.53,12.89 11.41,14.92 11.41,14.92
             11.41,14.92 14.30,17.81 14.30,17.81
             14.30,17.81 15.53,19.06 15.53,19.06
             15.67,19.21 15.91,19.53 16.09,19.58
             16.39,19.66 16.91,19.03 17.11,18.83
             17.11,18.83 19.38,16.56 19.38,16.56
             19.57,16.37 20.12,15.92 20.05,15.62
             20.00,15.42 19.27,14.69 19.06,14.64
             18.90,14.61 18.79,14.69 18.67,14.78
             18.67,14.78 17.19,16.25 17.19,16.25
             17.04,16.40 16.70,16.79 16.48,16.79
             16.26,16.79 15.79,16.26 15.63,16.09
             15.51,15.98 15.20,15.69 15.15,15.55
             15.04,15.26 15.44,14.95 15.63,14.77
             15.63,14.77 16.79,13.67 16.79,13.67
             16.93,13.52 17.10,13.35 17.02,13.12
             16.96,12.96 16.69,12.70 16.56,12.58
             16.34,12.36 16.19,12.21 15.94,12.03
             15.94,12.03 13.67,14.22 13.67,14.22
             13.67,14.22 12.27,12.66 12.27,12.66
             12.71,12.34 14.29,10.76 14.61,10.31
             14.61,10.31 13.52,9.14 13.52,9.14
             13.02,9.37 12.44,10.06 12.03,10.47
             12.03,10.47 9.53,12.89 9.53,12.89 Z
           M 20.16,2.50
           C 20.16,2.50 19.06,3.52 19.06,3.52
             18.95,3.64 18.77,3.80 18.78,3.98
             18.78,4.27 19.69,5.08 19.92,5.31
             19.92,5.31 21.88,7.27 21.88,7.27
             22.34,7.73 22.90,8.25 23.21,8.83
             23.95,10.22 22.40,11.40 21.56,11.31
             20.88,11.25 20.30,10.69 19.84,10.23
             19.84,10.23 17.50,7.89 17.50,7.89
             17.50,7.89 16.51,6.88 16.51,6.88
             16.36,6.73 16.24,6.57 16.01,6.65
             15.89,6.70 15.08,7.45 14.93,7.60
             14.80,7.72 14.67,7.85 14.71,8.05
             14.76,8.21 15.03,8.47 15.16,8.59
             15.16,8.59 16.25,9.69 16.25,9.69
             16.25,9.69 18.05,11.48 18.05,11.48
             19.00,12.43 20.09,13.52 21.56,13.43
             23.38,13.32 25.43,11.29 25.47,9.45
             25.51,7.40 23.50,5.87 22.19,4.53
             21.56,3.88 20.89,3.03 20.16,2.50 Z" />);
    case SvgIconsConstants.g_IconFavoriteInit:
      //<path id="Selection" fill="none"
      //stroke="black" stroke-width="1" -> path/linie
      return (
        <path
          {...props}
          d="M 169.00,5.52
           C 187.16,2.69 191.10,15.69 197.75,29.00
             197.75,29.00 223.75,81.00 223.75,81.00
             225.96,85.45 231.86,99.77 234.63,102.49
             237.65,105.44 253.12,106.70 258.00,107.42
             258.00,107.42 319.00,116.27 319.00,116.27
             332.92,118.28 347.14,119.24 348.67,137.00
             349.71,148.98 338.62,157.42 331.00,165.00
             331.00,165.00 319.00,176.04 319.00,176.04
             319.00,176.04 291.00,204.00 291.00,204.00
             287.19,207.79 275.83,217.12 274.15,221.00
             272.84,223.68 273.78,227.18 274.15,230.00
             274.15,230.00 277.25,248.00 277.25,248.00
             277.25,248.00 285.41,295.00 285.41,295.00
             285.41,295.00 288.99,319.00 288.99,319.00
             289.32,333.63 278.77,343.86 264.00,340.47
             258.12,339.12 248.64,333.17 243.00,330.26
             243.00,330.26 196.00,305.74 196.00,305.74
             191.50,303.42 177.18,294.78 173.00,294.78
             169.48,294.78 154.36,303.57 150.00,305.75
             150.00,305.75 105.00,329.31 105.00,329.31
             99.44,332.31 87.71,339.16 82.00,340.47
             67.56,343.79 56.48,333.50 57.04,319.00
             57.04,319.00 60.92,295.00 60.92,295.00
             60.92,295.00 68.92,248.00 68.92,248.00
             68.92,248.00 72.00,230.00 72.00,230.00
             72.45,227.70 73.51,223.77 72.60,221.58
             71.71,219.42 67.75,215.75 66.00,214.00
             66.00,214.00 52.00,200.83 52.00,200.83
             52.00,200.83 18.00,167.09 18.00,167.09
             10.67,160.26 0.19,153.95 0.00,143.00
             0.00,143.00 0.00,134.00 0.00,134.00
             0.18,120.95 9.38,119.12 20.00,117.41
             20.00,117.41 87.00,107.58 87.00,107.58
             92.17,106.81 108.13,105.65 111.37,102.49
             113.48,100.42 120.40,85.69 122.25,82.00
             122.25,82.00 146.75,32.00 146.75,32.00
             152.67,20.17 155.15,9.33 169.00,5.52 Z
           M 174.00,36.00
           C 174.00,36.00 172.00,36.00 172.00,36.00
             172.00,36.00 161.26,58.00 161.26,58.00
             161.26,58.00 142.25,97.00 142.25,97.00
             138.40,104.68 132.57,119.16 125.96,124.06
             119.83,128.60 103.12,130.00 95.00,131.13
             95.00,131.13 27.00,141.00 27.00,141.00
             31.71,147.57 38.30,152.33 44.00,158.00
             44.00,158.00 74.00,187.83 74.00,187.83
             80.52,193.73 93.93,205.00 96.87,213.00
             99.56,220.32 95.77,235.91 94.41,244.00
             94.41,244.00 86.75,289.00 86.75,289.00
             86.75,289.00 83.00,313.00 83.00,313.00
             83.00,313.00 98.00,305.25 98.00,305.25
             98.00,305.25 145.00,280.74 145.00,280.74
             152.26,277.00 165.07,268.58 173.00,268.58
             180.50,268.58 196.98,278.25 204.00,282.14
             204.00,282.14 236.00,298.78 236.00,298.78
             236.00,298.78 263.00,313.00 263.00,313.00
             263.00,313.00 248.09,221.00 248.09,221.00
             247.33,207.57 258.53,201.25 267.00,192.99
             267.00,192.99 297.00,163.09 297.00,163.09
             304.41,156.18 313.08,149.27 319.00,141.00
             319.00,141.00 252.00,131.13 252.00,131.13
             243.87,129.99 227.31,128.78 221.00,124.35
             212.42,118.33 207.64,104.36 203.22,95.00
             203.22,95.00 174.00,36.00 174.00,36.00 Z" />
      );
    case SvgIconsConstants.g_IconFavoriteSet:
      return (<path {...props}
        d="M 169.00,5.52
           C 187.16,2.69 191.10,15.69 197.75,29.00
             197.75,29.00 223.75,81.00 223.75,81.00
             225.96,85.45 231.86,99.77 234.63,102.49
             237.65,105.44 253.12,106.70 258.00,107.42
             258.00,107.42 319.00,116.27 319.00,116.27
             332.92,118.28 347.14,119.24 348.67,137.00
             349.71,148.98 338.62,157.42 331.00,165.00
             331.00,165.00 319.00,176.04 319.00,176.04
             319.00,176.04 291.00,204.00 291.00,204.00
             287.19,207.79 275.83,217.12 274.15,221.00
             272.84,223.68 273.78,227.18 274.15,230.00
             274.15,230.00 277.25,248.00 277.25,248.00
             277.25,248.00 285.41,295.00 285.41,295.00
             285.41,295.00 288.99,319.00 288.99,319.00
             289.32,333.63 278.77,343.86 264.00,340.47
             258.12,339.12 248.64,333.17 243.00,330.26
             243.00,330.26 196.00,305.74 196.00,305.74
             191.50,303.42 177.18,294.78 173.00,294.78
             169.48,294.78 154.36,303.57 150.00,305.75
             150.00,305.75 105.00,329.31 105.00,329.31
             99.44,332.31 87.71,339.16 82.00,340.47
             67.56,343.79 56.48,333.50 57.04,319.00
             57.04,319.00 60.92,295.00 60.92,295.00
             60.92,295.00 68.92,248.00 68.92,248.00
             68.92,248.00 72.00,230.00 72.00,230.00
             72.45,227.70 73.51,223.77 72.60,221.58
             71.71,219.42 67.75,215.75 66.00,214.00
             66.00,214.00 52.00,200.83 52.00,200.83
             52.00,200.83 18.00,167.09 18.00,167.09
             10.67,160.26 0.19,153.95 0.00,143.00
             0.00,143.00 0.00,134.00 0.00,134.00
             0.18,120.95 9.38,119.12 20.00,117.41
             20.00,117.41 87.00,107.58 87.00,107.58
             92.17,106.81 108.13,105.65 111.37,102.49
             113.48,100.42 120.40,85.69 122.25,82.00
             122.25,82.00 146.75,32.00 146.75,32.00
             152.67,20.17 155.15,9.33 169.00,5.52 Z"
        />);
    case SvgIconsConstants.g_IconHandshake:
      return (<path {...props}
        //id="Selection #2" fill="none" stroke="black" stroke-width="1"
        d="M 444.72,1.80
           C 462.76,-0.51 454.23,25.56 452.87,35.00
             452.87,35.00 436.27,137.00 436.27,137.00
             435.56,141.81 432.65,161.81 430.84,164.89
             429.52,167.15 427.47,168.47 425.00,169.18
             419.64,170.73 414.19,167.57 413.23,162.00
             412.55,158.04 415.66,142.10 416.42,137.00
             416.42,137.00 432.72,37.00 432.72,37.00
             432.72,37.00 435.75,17.00 435.75,17.00
             436.29,13.71 436.83,8.83 438.73,6.10
             440.46,3.63 442.08,2.90 444.72,1.80 Z
           M 302.09,2.85
           C 316.29,-1.18 316.88,10.74 318.15,21.00
             318.15,21.00 319.73,30.00 319.73,30.00
             319.73,30.00 335.43,129.00 335.43,129.00
             335.43,129.00 339.83,157.00 339.83,157.00
             340.27,161.80 339.82,166.08 334.96,168.35
             329.86,170.73 323.92,169.10 321.31,163.98
             320.05,161.50 317.54,143.93 317.00,140.00
             317.00,140.00 300.73,38.00 300.73,38.00
             299.76,31.42 295.50,12.42 297.85,7.09
             298.94,4.65 299.99,4.16 302.09,2.85 Z
           M 186.00,90.69
           C 196.06,89.45 200.80,100.97 205.72,108.00
             205.72,108.00 231.02,145.00 231.02,145.00
             231.02,145.00 247.60,169.00 247.60,169.00
             251.42,175.29 251.02,185.69 242.00,187.14
             233.85,188.45 226.81,174.66 222.88,169.00
             222.88,169.00 184.58,113.00 184.58,113.00
             178.90,104.96 173.34,95.85 186.00,90.69 Z
           M 561.00,90.74
           C 567.34,89.22 573.15,90.45 573.89,98.00
             574.68,106.07 567.36,113.71 563.02,120.00
             563.02,120.00 530.33,168.00 530.33,168.00
             526.69,173.39 520.05,184.66 514.00,186.62
             506.32,189.11 498.87,180.33 503.97,171.00
             503.97,171.00 542.00,115.00 542.00,115.00
             546.76,107.90 553.72,94.62 561.00,90.74 Z
           M 185.00,541.00
           C 168.99,545.63 151.82,542.02 143.81,526.00
             141.90,522.18 140.18,516.28 140.18,512.00
             140.17,508.75 142.03,499.18 143.10,496.00
             143.84,493.79 145.68,490.71 144.19,488.51
             142.96,486.68 138.13,485.36 136.00,484.67
             127.81,482.00 118.68,479.66 110.00,480.02
             100.54,480.41 103.06,487.32 93.00,486.90
             88.24,486.70 74.36,482.38 69.00,480.86
             69.00,480.86 26.00,468.86 26.00,468.86
             20.02,467.17 6.15,464.86 2.85,459.89
             -0.65,454.60 3.31,447.22 5.40,442.00
             5.40,442.00 22.20,400.00 22.20,400.00
             22.20,400.00 78.40,258.00 78.40,258.00
             78.40,258.00 92.68,222.00 92.68,222.00
             94.35,217.50 97.62,207.50 101.21,204.70
             108.44,199.07 119.40,207.74 126.00,211.22
             126.00,211.22 172.00,235.25 172.00,235.25
             172.00,235.25 191.00,245.15 191.00,245.15
             194.51,246.22 201.16,244.53 205.00,244.00
             205.00,244.00 236.00,239.43 236.00,239.43
             236.00,239.43 291.00,231.58 291.00,231.58
             308.02,229.17 319.48,226.80 337.00,227.00
             337.00,227.00 355.00,228.83 355.00,228.83
             355.00,228.83 372.00,230.04 372.00,230.04
             378.73,230.58 378.71,231.77 386.00,230.04
             389.55,232.52 391.83,231.51 396.00,231.91
             396.00,231.91 413.00,234.00 413.00,234.00
             413.00,234.00 417.00,234.00 417.00,234.00
             428.53,234.02 437.29,237.92 448.00,241.66
             448.00,241.66 494.00,257.28 494.00,257.28
             520.49,265.26 544.02,266.07 571.00,260.00
             566.99,245.87 575.22,243.46 586.00,237.78
             586.00,237.78 635.00,212.14 635.00,212.14
             641.97,208.27 654.43,198.91 661.79,205.42
             665.34,208.56 668.52,218.30 670.40,223.00
             670.40,223.00 685.40,261.00 685.40,261.00
             685.40,261.00 740.20,399.00 740.20,399.00
             740.20,399.00 756.40,440.00 756.40,440.00
             758.48,445.21 762.58,453.34 760.26,458.91
             757.78,464.87 742.98,467.17 737.00,468.86
             737.00,468.86 692.00,481.42 692.00,481.42
             681.86,484.31 673.65,487.90 663.00,486.00
             657.87,496.84 646.29,500.95 635.00,501.00
             630.92,501.02 619.36,499.81 617.02,503.31
             615.79,505.16 616.00,508.81 616.00,511.00
             615.99,517.89 616.23,522.38 613.53,529.00
             607.13,544.65 590.07,556.65 573.00,555.96
             573.00,555.96 560.00,554.00 560.00,554.00
             563.26,580.55 538.34,593.69 515.00,592.25
             509.67,591.92 501.03,588.13 496.00,586.00
             496.09,588.95 495.00,596.06 494.24,599.00
             486.56,628.73 453.16,628.61 431.00,616.00
             428.08,624.36 422.85,632.96 414.00,636.12
             414.00,636.12 400.00,638.00 400.00,638.00
             400.00,638.00 380.00,633.50 380.00,633.50
             380.00,633.50 356.00,626.11 356.00,626.11
             356.00,626.11 341.00,633.17 341.00,633.17
             341.00,633.17 319.00,638.00 319.00,638.00
             315.83,635.62 310.99,635.18 306.00,632.74
             294.57,627.17 289.06,619.66 288.00,607.00
             269.38,611.53 253.10,604.64 243.46,588.00
             241.67,584.90 240.32,581.49 239.51,578.00
             239.08,576.12 238.96,573.05 237.40,571.70
             235.67,570.34 232.94,571.19 231.00,571.70
             226.08,572.74 223.16,573.44 218.00,572.70
             205.07,570.86 193.82,564.30 188.35,552.00
             188.35,552.00 185.00,541.00 185.00,541.00 Z
           M 112.00,227.00
           C 112.00,227.00 98.20,262.00 98.20,262.00
             98.20,262.00 74.00,323.00 74.00,323.00
             74.00,323.00 49.28,385.00 49.28,385.00
             49.28,385.00 31.40,430.00 31.40,430.00
             30.22,432.95 25.09,444.24 25.81,446.69
             26.63,449.51 36.84,451.50 39.72,452.33
             39.72,452.33 77.00,462.58 77.00,462.58
             80.56,463.58 87.43,466.49 90.68,464.31
             92.94,462.79 96.45,456.55 98.05,454.00
             98.05,454.00 113.42,428.00 113.42,428.00
             134.72,390.77 149.91,350.10 161.71,309.00
             161.71,309.00 170.00,274.00 170.00,274.00
             170.61,270.88 172.41,261.78 171.39,259.10
             169.87,255.13 160.80,251.75 157.00,249.69
             157.00,249.69 129.00,234.69 129.00,234.69
             123.17,231.54 118.67,228.25 112.00,227.00 Z
           M 651.00,227.00
           C 651.00,227.00 632.00,235.74 632.00,235.74
             632.00,235.74 590.00,258.00 590.00,258.00
             597.18,308.93 620.36,376.28 645.58,421.00
             645.58,421.00 664.19,453.00 664.19,453.00
             664.19,453.00 671.53,463.65 671.53,463.65
             674.98,466.80 683.34,464.25 686.00,461.00
             691.40,461.77 692.98,460.28 698.00,459.03
             698.00,459.03 725.04,451.79 725.04,451.79
             727.71,451.08 736.38,449.45 737.19,446.69
             737.91,444.24 732.78,432.95 731.60,430.00
             731.60,430.00 714.15,386.00 714.15,386.00
             714.15,386.00 674.60,287.00 674.60,287.00
             674.60,287.00 651.00,227.00 651.00,227.00 Z
           M 351.00,248.00
           C 351.00,248.00 328.00,246.32 328.00,246.32
             328.00,246.32 299.00,250.71 299.00,250.71
             299.00,250.71 251.00,257.71 251.00,257.71
             251.00,257.71 234.00,259.92 234.00,259.92
             234.00,259.92 205.00,264.13 205.00,264.13
             202.03,264.54 194.64,265.21 192.51,266.93
             189.93,269.03 188.39,280.32 187.58,284.00
             187.58,284.00 176.86,326.00 176.86,326.00
             167.64,358.20 154.71,389.09 139.75,419.00
             139.75,419.00 117.00,461.00 117.00,461.00
             125.88,462.20 134.50,464.19 143.00,467.00
             145.77,467.92 154.69,471.41 157.00,471.03
             157.00,471.03 173.00,460.46 173.00,460.46
             181.77,455.39 186.41,455.63 195.00,453.00
             195.00,453.00 204.00,457.82 204.00,457.82
             204.00,457.82 214.00,466.00 214.00,466.00
             224.52,462.14 225.04,459.06 238.00,459.00
             258.04,458.91 275.06,468.67 284.00,487.00
             290.52,485.51 295.98,483.06 303.00,483.51
             308.82,483.89 314.68,484.85 320.00,487.32
             334.97,494.28 347.03,513.25 345.82,530.00
             345.82,530.00 342.00,550.00 342.00,550.00
             358.86,552.39 367.96,563.95 371.13,580.00
             371.57,582.23 372.24,584.74 372.21,587.00
             372.21,587.00 368.80,604.98 368.80,604.98
             368.02,610.16 371.91,610.80 376.00,612.17
             382.72,614.41 392.97,616.92 400.00,617.00
             402.45,617.02 406.69,617.25 408.77,615.98
             414.05,612.75 411.91,604.85 408.77,601.00
             408.77,601.00 397.15,588.00 397.15,588.00
             397.15,588.00 361.58,548.00 361.58,548.00
             358.99,545.02 354.24,540.47 352.85,537.00
             350.42,530.91 354.49,524.62 361.00,524.17
             368.47,523.66 380.79,538.68 385.96,543.99
             385.96,543.99 396.04,555.00 396.04,555.00
             396.04,555.00 429.00,588.00 429.00,588.00
             437.15,596.15 447.13,605.77 459.83,604.52
             462.85,604.23 468.48,603.28 470.79,601.44
             479.37,594.59 475.26,580.44 472.22,572.00
             471.05,568.76 470.38,566.07 468.20,563.28
             468.20,563.28 443.00,539.92 443.00,539.92
             443.00,539.92 387.00,489.02 387.00,489.02
             382.89,485.19 372.24,476.47 370.02,472.00
             367.50,466.91 370.61,460.83 376.00,459.43
             381.31,458.06 385.39,461.79 389.00,465.09
             389.00,465.09 413.00,486.58 413.00,486.58
             413.00,486.58 482.00,548.17 482.00,548.17
             492.34,557.47 504.56,571.42 519.00,572.67
             521.83,572.92 525.27,572.28 528.00,571.51
             530.23,570.88 532.04,570.12 534.00,568.87
             536.93,566.98 539.66,564.49 540.57,561.00
             541.54,557.25 540.01,553.41 538.57,550.00
             535.37,542.42 531.33,535.16 525.83,529.00
             519.00,521.35 502.70,508.96 494.00,502.00
             494.00,502.00 443.00,460.80 443.00,460.80
             443.00,460.80 421.00,443.20 421.00,443.20
             417.88,440.65 413.35,437.14 412.47,433.00
             411.20,426.97 418.21,420.48 425.00,423.02
             428.27,424.24 434.06,429.25 437.00,431.60
             437.00,431.60 458.00,448.20 458.00,448.20
             458.00,448.20 534.00,508.88 534.00,508.88
             534.00,508.88 566.00,533.60 566.00,533.60
             578.62,540.15 592.81,531.86 596.89,519.00
             598.01,515.00 597.06,506.76 596.89,502.00
             596.89,494.01 593.37,489.44 588.97,483.00
             578.47,467.63 569.54,461.28 555.00,450.37
             555.00,450.37 443.00,367.12 443.00,367.12
             422.64,352.10 413.36,342.75 387.00,340.91
             383.67,340.68 379.32,339.61 376.06,340.15
             371.89,340.83 372.14,343.53 371.34,347.00
             370.23,351.77 368.30,357.58 366.22,362.00
             359.09,377.17 346.65,390.87 332.00,399.13
             321.33,405.14 298.06,411.82 286.00,408.20
             280.88,406.67 273.89,401.55 270.00,397.91
             264.91,393.13 260.68,388.22 260.09,381.00
             258.93,366.72 269.05,359.91 274.57,347.00
             281.75,330.22 280.83,313.45 288.49,299.00
             297.34,282.32 313.54,273.10 329.00,263.42
             334.22,260.15 348.12,252.58 351.00,248.00 Z
           M 391.00,320.00
           C 393.66,322.99 402.78,323.36 407.00,324.56
             417.93,327.67 427.76,332.55 437.00,339.14
             437.00,339.14 569.00,436.72 569.00,436.72
             581.57,445.54 591.19,454.02 600.91,466.00
             603.44,469.13 609.49,479.19 612.32,480.43
             613.66,481.01 615.56,480.97 617.00,481.00
             617.00,481.00 627.00,481.00 627.00,481.00
             634.17,481.01 640.20,483.12 645.61,476.89
             647.12,475.15 648.67,472.28 649.11,470.00
             650.01,465.31 646.40,460.77 644.05,457.00
             644.05,457.00 629.25,431.00 629.25,431.00
             629.25,431.00 620.37,414.00 620.37,414.00
             606.45,386.17 594.25,354.93 585.71,325.00
             585.71,325.00 577.21,292.00 577.21,292.00
             576.68,289.64 575.15,281.29 573.41,280.06
             571.27,278.55 560.92,280.77 558.00,281.25
             549.20,282.68 532.53,282.11 523.00,282.00
             502.72,281.76 472.37,270.05 453.00,262.95
             436.41,256.86 431.12,253.02 413.00,253.00
             413.00,253.00 390.00,253.00 390.00,253.00
             376.53,253.02 373.93,257.41 363.00,264.19
             363.00,264.19 334.00,282.58 334.00,282.58
             323.27,289.29 314.05,294.01 307.09,305.00
             300.91,314.77 300.60,327.73 297.42,339.00
             295.03,347.48 291.44,357.62 286.66,365.00
             284.50,368.33 278.95,375.05 279.64,378.99
             280.08,381.51 282.21,383.54 284.09,385.10
             291.96,391.61 295.82,390.25 305.00,388.40
             325.48,384.28 343.19,370.08 350.00,350.00
             352.84,341.60 352.43,341.29 353.58,333.00
             354.14,328.91 353.86,325.57 357.22,322.57
             364.36,316.18 379.72,323.20 391.00,320.00 Z
           M 188.00,474.41
           C 174.57,478.52 164.62,491.80 161.16,505.00
             160.41,507.86 159.72,511.06 160.42,514.00
             162.03,520.70 169.42,524.71 176.00,523.39
             187.78,521.02 198.78,503.52 200.54,492.28
             202.01,482.85 199.47,473.44 188.00,474.41 Z
           M 236.00,478.30
           C 218.13,481.79 221.56,495.12 216.13,508.00
             211.91,517.99 207.38,520.82 205.23,526.00
             200.45,537.46 207.07,552.28 220.00,553.47
             236.82,555.03 251.80,534.30 260.00,522.00
             262.81,517.79 268.06,510.95 268.58,506.00
             268.93,502.70 267.58,498.95 266.20,496.00
             260.64,484.07 249.19,477.10 236.00,478.30 Z
           M 298.00,503.30
           C 295.55,503.76 291.46,504.46 289.53,506.09
             286.51,508.64 287.27,515.51 281.91,524.00
             281.91,524.00 267.38,545.00 267.38,545.00
             264.76,548.22 258.82,553.67 257.60,557.00
             256.87,558.97 256.96,562.83 257.01,565.00
             257.30,576.42 264.64,588.42 277.00,588.96
             286.62,589.38 290.98,584.78 298.00,578.92
             310.67,568.33 324.63,547.81 326.52,531.00
             328.39,514.42 313.96,501.72 298.00,503.30 Z
           M 334.00,570.43
           C 327.50,573.05 321.84,582.66 316.83,587.96
             313.73,591.24 309.66,593.61 308.03,598.00
             303.97,608.90 312.38,616.73 323.00,616.99
             338.01,617.35 351.37,605.24 351.71,590.00
             352.01,576.90 349.27,568.10 334.00,570.43 Z" />);
    case SvgIconsConstants.g_IconPfeilTop:
    return (
      <polygon class="st0" points="12.7,8.6 7,2.9 1.3,8.6 -0.1,7.2 7,0.1 14.1,7.2 	"/>
    );
    default:
      return <path />;
  }
};

const IconSvg = ({
  //diese alle Variablen können wie 'props' übergeben werden,
  //dann werden die defaults überschrieben
  p_IconKey = "",
  p_Tooltip = "",
  className = "",
  style = {},
  fill = "#000",
  width = "100%",
  height = "100%",
  viewBox = ""
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox={viewBox || getViewBox(p_IconKey)}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>{p_Tooltip}</title>
    {getPath(p_IconKey, { fill })}
  </svg>
);
export default IconSvg;
