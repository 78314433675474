import React from 'react';

export const SvgIconsConstants = {
  g_IconResultSetNeu: "IconResultSetNeu",
  g_IconResultSetNeu_left: "IconResultSetNeu_left",
  g_IconFavoriteInit: 'KeyIconFavoriteInit',
  g_IconFavoriteSet: 'KeyIconFavoriteSet',
  g_IconHandshake: 'KeyIconHandshake',
  g_IconPfeilTop: 'KeyIconPfeilTop'


  ,g_IconSvgRollstuhl:' KeyIconSvgRollstuhl'
  ,g_IconSvgRollstuhl_Stu_imkreis:' KeyIconSvgRollstuhlStu'
  ,g_IconSvgParkplatz:'KeyIconSvgParkplatz'
  ,g_IconSvgParkplatz_Stu_imkreis:'KeyIconSvgParkplatzStu'
  ,g_IconSvgWc: 'KeyIconSvgWc'
  ,g_IconSvgWc_Stu_imkreis: 'KeyIconSvgWcStu'
  ,g_IconSvgTaub: 'KeyIconSvgTaub'
  ,g_IconSvgBlind: 'KeyIconSvgBlind'
  ,g_IconSvgAufzug:'KeyIconSvgAufzug'
  ,g_IconSvgKinderwagen: 'KeyIconSvgKinderwagen'
  ,g_IconSvgKinderwagen_Stu_imkreis: 'KeyIconSvgKinderwagenStu'


  ,g_IconResultSetSvgOrt: 'KeyIconSvgOrt'
  ,g_IconResultSetSvgOrt_Filled: 'KeyIconSvgOrt_filled'
}
