import React, {Component} from 'react';
import SearchListBox from "../../common/components/SearchListBox";
import Config from "../../Config";

class SearchListBoxes extends Component {
    render() {
        return (
            <div id="search-list-boxes" className="container">
                <h1>Entdecken Sie die Vielfalt der Frühen Hilfen in Stuttgart</h1>
                <div className="row">
                    <SearchListBox
                        className="info-search-box"
                        headline="Gezielt informieren"
                        filter_id={Config.FILTER_IDS['topic']}
                    />
                    <SearchListBox
                        className="age-search-box"
                        headline="Wie alt ist Ihr Kind?"
                        filter_id={Config.FILTER_IDS['kids_age']}
                    />
                </div>
            </div>
        );
    }
}

export default SearchListBoxes;