/**
 * Describes the FilterSearch
 * for options @see SearchFilterOptions
 *
 * @class      FilterSearch (name)
 */
export default class FilterSearch {

	static TYPE_LIST = "SearchFilterList";
	static TYPE_OFFER_TYPES = "SearchFilterOfferTypes";

	constructor(ID, slug, options, type = FilterSearch.TYPE_LIST) {
		this.type = type;
		this.ID = ID;
		this.slug = slug;
		this.options = options;
	}
}