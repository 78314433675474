import React, {Component} from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import iconDownload from './media/icons/download-200.png';
import imgWissenswert from "../goettingen_kita_t/media/static_pages/wissenswertes_g.jpg";

/**
 * Shows a ViewStaticChecklisten Page
 *
 * @class      ViewStaticChecklisten (name)
 */
export default class ViewStaticChecklisten extends Component {

    render() {
        return (
            <div className="view-static view-static-checklisten">
                <div className="title-box">
                    <h1 class={"title"}>Checklisten</h1>
                    <p>Sie stehen kurz vor der Geburt und möchten Vorbereitungen treffen? Unsere Checklisten geben Ihnen Tipps und helfen bei der Orientierung.</p>
                    <p className="text-center">
                        <em> Folgende Checklisten stehen zum Download bereit:</em>
                    </p>
                </div>

                <hr/>

                <div className="row download-box">
                    <div className="col-info">
                        <h2>Checkliste Rund um die Geburt</h2>
                        <p>Hier können Sie sich die Checkliste im PDF Formart herunterladen</p>
                    </div>
                    <div className="col-download">
                        <a className="icon-pdf" href={process.env.PUBLIC_URL + '/pdf/ChecklisteRundUmDieGeburt.pdf'}>&nbsp;</a>
                    </div>
                </div>
                <hr/>

                <div className="row download-box">
                    <div className="col-info">
                        <h2>Checkliste Kliniktasche</h2>
                        <p>Hier können Sie sich die Checkliste im PDF Formart herunterladen</p>
                    </div>
                    <div className="col-download">
                        <a className="icon-pdf" href={process.env.PUBLIC_URL + '/pdf/ChecklisteKliniktasche.pdf'}>&nbsp;</a>
                    </div>
                </div>
                <hr/>


                <div className="row download-box">
                    <div className="col-info">
                        <h2>Checkliste Erstausstattung</h2>
                        <p>Hier können Sie sich die Checkliste im PDF Formart herunterladen</p>
                    </div>
                    <div className="col-download">
                        <a className="icon-pdf" href={process.env.PUBLIC_URL + '/pdf/ChecklisteErstausstattung.pdf'}>&nbsp;</a>
                    </div>
                </div>

            </div>
        );
    }
}