import React from 'react';
import $ from 'jquery';
import {AppContext} from '../app/AppContext';

// Example


/**
 * The FontResizer adds fs-plus-size classes to the components.
 * The components are stored in Config.js
 *
 * @class      FontResizer (name)
 * @prop 		{int} resize - size
 * @prop 		{array} components - class names of components to update 
 *
 *	Example for scss: 
 * 	
 * 	.Component{
 * 	&.fs-plus-1{
 * 	}
 * 	&.fs-plus-2{
 * 	}
 * 	&.fs-plus-3{
 * 	}	
 * }
 * 
 */
export default class FontResizer extends React.Component {

	constructor(props) {
		super(props);

		this.max_size = 3;
	}

	componentDidMount() {

		this.updateClasses(this.props.resize);
	}

	componentDidUpdate(prevProps, prevState) {

		if(this.props.resize !== prevProps.resize)
		{
			this.updateClasses(this.props.resize);
		}
	}

	sizeMinus(){
		this.context.setFontResize(this.props.resize - 1);
	}

	sizePlus(){
		this.context.setFontResize(this.props.resize + 1);
	}

	updateClasses(size)
	{
		this.removeClasses();

		//set classes if not 0
		if(size)
		{
			for(const comp of this.props.components)
			{
				$('.' + comp).addClass('fs-plus-' + size);
			}									
		}		
	}

	removeClasses(){
		for(const comp of this.props.components)
		{
			$('.' + comp).removeClass('fs-plus-1 fs-plus-2 fs-plus-3');	
		}
	}

	render() {
		return(
			<div className="FontResizer">
				<button className="btn-minus" disabled={!(this.props.resize)} onClick={e => this.sizeMinus(e)}><i className="fa fa-minus"></i></button>
				Text
				<button className="btn-plus" disabled={this.props.resize >= this.max_size} onClick={e => this.sizePlus(e)}><i className="fa fa-plus"></i></button>
			</div>
		);
	}
}
FontResizer.contextType = AppContext;
