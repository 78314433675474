import React, {Component} from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import {AppContext} from "../app/AppContext";

class CmsCardOffer extends Component {

    constructor(props) {
        super(props);

        this.searchAllOffers = this.searchAllOffers.bind(this);

        if(typeof props.offer_links_json === "string")
        {
            this.offer_links = props.offer_links_json;
        }
        else if(props.offer_links_json){
            this.offer_links = [];
            let link, url;
            let key = 0;
            try{
                for (const link_json of props.offer_links_json) {
                    url = '/detailorganisation/' + link_json.Cms_ActionValue;
                    link = <Link key={key++} to={url}>{link_json.Cms_Label}</Link>;
                    this.offer_links.push(link);
                }
            }
            catch (e) {
                console.error(this.props.headline + ': FEHLER IM JSON FÜR DIE LINKS, BITTE IM BACKEND FIXEN');
            }
        }

        if(typeof props.all_results_json === "string")
        {
            this.all_results = props.all_results_json;
        }
        else if(props.all_results_json)
        {
            this.all_results = <span className='all-results-link' onClick={() => this.searchAllOffers(this.props.all_results_json)}>
                &gt;&gt; Alle Ergebnisse
            </span>;
        }
    }

    searchAllOffers(filters) {
        this.context.setCardFiltersAndSearchApiOffers(filters);
    }


    render() {
        return (
            <div className="cms-card-offer">
                <h2 className="headline">{this.props.headline}</h2>
                <h3 className="subheadline">{this.props.subheadline}</h3>
                {this.props.p_offer_text_tag}
                <p className="offer-links">{this.offer_links}</p>
                <p className="all-results">{this.all_results}</p>
            </div>
        );
    }
}
CmsCardOffer.propTypes = {
    headline: PropTypes.string,
    subheadline: PropTypes.string,
    p_offer_text_tag: PropTypes.string,
    offer_links: PropTypes.string,
    all_results: PropTypes.string
};
CmsCardOffer.contextType = AppContext;
export default CmsCardOffer;

