import React from 'react';

class MerkmalJaNein extends React.Component {

	render() {

		// let label = this.props.bool_data.Label;
		let bool_data = this.props.bool_data.MultipleMerkmalId;

		let values = [];
		for(let bool_val of bool_data)
		{
			//todo: MerkmalJaNein - maybe loop through values
			if(bool_val.Values[0].Value === '+')
				values.push('Ja');
			else if(bool_val.Values[0].Value === '?')
				values.push('Nein');
		}

		return values.join(', ');
	}
}

export default MerkmalJaNein;