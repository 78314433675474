import React from 'react';

//Bilder fürs HomeCards
import IImgFfoSpielplatz from './sites/thueringen/media/themen/ffo_spielplatz.jpg';
import IImgFfoToilette from './sites/thueringen/media/themen/ffo_toilette.jpg';
import IImgFfoStillpunkt from './sites/thueringen/media/themen/ffo_stillpunkt.jpg';
import IImgFfoWickelpunkt from './sites/thueringen/media/themen/ffo_wickelpunkt.jpg';
import IImgFfoGuteFee from './sites/thueringen/media/themen/ffo_gutefee.jpg';
import IImgFfoElterncafe from './sites/thueringen/media/themen/ffo_elterncafe.jpg';

import imgTemp from './sites/stuttgart/media/kacheln/platzhalter.png';

export default class ConfigCards {

    //man braucht eine statische Variablen, damit diese von anderen js-Dateien geladen werden können
    //sonst für die lokale verwendung, braucht man diese nicht
    static ImgFfoSpielplatz = IImgFfoSpielplatz;
    static ImgFfoToilette = IImgFfoToilette;
    static ImgFfoStillpunkt = IImgFfoStillpunkt;
    static ImgFfoWickelpunkt = IImgFfoWickelpunkt;
    static ImgFfoGuteFee = IImgFfoGuteFee;
    static ImgFfoElterncafe = IImgFfoElterncafe;

    static cardTitles = {
        'schwangerschaft': "Schwangerschaft und Geburt",
        'begegnung': "Begegnung, Treff und Kurs",
        'unterstuetzung': "Entlastung und Unterstützung",
        'gesundheit': "Gesundheit",
        'kinderbetreuung': "Kinderbetreuung",
        'geld': "Rund ums Geld",
        'hilfe': "Beratung und Hilfe",
        'wickeln': "Still- und Wickelorte"
    }

    static MENU_LINKS_ROUTE = [
        {
            menu_title: 'Themen',
            menu_untermenus: [
                {
                    menu_title: this.cardTitles.schwangerschaft,
                    menu_link_to: '/thema/100---schwangerschaft--geburt-'
                },
                {
                    menu_title: this.cardTitles.begegnung,
                    menu_link_to: '/thema/200---begegnung-treff--kurs'
                },
                {
                    menu_title: this.cardTitles.hilfe,
                    menu_link_to: '/thema/300---beratung--hilfe'
                },
                {
                    menu_title: this.cardTitles.unterstuetzung,
                    menu_link_to: '/thema/400---entlastung--untersttzung'
                },
                {
                    menu_title: this.cardTitles.gesundheit,
                    menu_link_to: '/thema/500---gesundheit'
                },
                {
                    menu_title: this.cardTitles.kinderbetreuung,
                    menu_link_to: '/thema/600---kinderbetreuung'
                },
                {
                    menu_title: this.cardTitles.geld,
                    menu_link_to: '/thema/700---rund-ums-geld'
                },
                {
                    menu_title: this.cardTitles.wickeln,
                    menu_link_to: '/'
                }
            ]
        },
        {
            menu_title: 'Frühe Hilfen',
            menu_untermenus: [
                {
                    menu_title: 'Was sind Frühe Hilfen?',
                    menu_link_to: '/was-sind-fruehe-hilfen'    //fertig, ToDo: add PDF-img
                },
                {
                    menu_title: 'Ansprechpartner*innen',
                    menu_link_to: '/ansprechpartner-innen'    //fertig: ToDo:
                },
                {
                    menu_title: 'Über diese Seite',
                    menu_link_to: '/ueber_diese_seite'          //fertig
                }
            ]
        },
        {
            menu_title: 'Checklisten',
            menu_link_to: '/checklisten'
        },
        {
            menu_title: 'Notfallnummern',
            menu_link_to: '/notfallnummern'         //fertig, ToDo: links-style
        },
        {
            menu_title: 'Weitere Links',
            menu_link_to: '/weitere-links'
        },
        {
            menu_title: 'Kontakt',
            menu_link_to: '/kontakt'
        }


        // {
        //     menu_title: 'Leichte Sprache',
        //     menu_link_to: '/leichte-sprache'
        // },
        // {
        //     menu_title: 'Rahmenkonzeption',  //ToDo : ???
        //     menu_link_to: '/rahmenkonzeption'
        // }
    ];

}
