import React from 'react';



export default class AddWatermarkSharePartial extends React.Component {

	render() {
		return (
      <div id="idWatermark">
        <div id="idWatermarkText">
            <p>
                VORSCHAU
            </p>
        </div>
    	</div>
		);
	}
}
