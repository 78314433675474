import React, {Component} from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import iconDownload from './media/icons/download-200.png';
import imgWissenswert from "../goettingen_kita_t/media/static_pages/wissenswertes_g.jpg";

/**
 * Shows a ViewStaticUeberDieseSeite Page
 *
 * @class      ViewStaticUeberDieseSeite (name)
 */
export default class ViewStaticUeberDieseSeite extends Component {

    render() {
        return (
            <div className="view-static">
                    <h1 className={"title"}>Über diese Seite</h1>
                    <p>Die Website „Frühe Hilfen in Stuttgart“ richtet sich an alle Familien der Stadt Stuttgart.</p>
                    <p>Ziel ist es, Ihnen als Eltern eine aktuelle und gebündelte Übersicht zu den Angeboten der Frühen
                        Hilfen zu ermöglichen. Um dies zu erreichen, veröffentlichen und aktualisieren Stuttgarter
                        Träger und Anbieter*innen der Frühen Hilfen ihre Angebote auf unserer Website.</p>
                <h2>Sie finden die Informationen und Angebote systematisiert nach den Themen:</h2>
                <ul>
                    <li>Schwangerschaft und Geburt</li>
                    <li>Begegnung, Treff und Kurs</li>
                    <li>Beratung und Hilfe</li>
                    <li>Entlastung und Unterstützung</li>
                    <li>Gesundheit</li>
                    <li>Kinderbetreuung</li>
                    <li>Rund ums Geld</li>
                    <li>Still- und Wickelorte </li>
                </ul>

                <h2>Sie können Ihre Suche weiter nach den folgenden Kriterien differenzieren:</h2>
                <ul>
                    <li>Stadtbezirke</li>
                    <li>Alter des Kindes</li>
                    <li>weitere Themen</li>
                    <li>Schlagwortsuche</li>
                </ul>

                <p>Die Website wurde gemeinsam mit GEBIT, Münster, aufgebaut und wird durch den Fachdienst Frühe
                    Hilfen des Jugendamts Stuttgart gepflegt. Falls Sie Anmerkungen zur Website haben oder Ihnen
                    Informationen fehlen, würden wir uns über Ihre Rückmeldung freuen. </p>
                <p>Ihr Fachdienst Frühe Hilfen</p>

            </div>
        );
    }
}