import React from 'react';

/**
 * This component wraps a message with a alert view
 *
 * @class      MessageSharePartial (name)
 */
export default class MessageSharePartial extends React.Component {

	render() {
		return (
			<div className="alert text-center MessageSharePartial" role="alert">
				{this.props.children}
			</div>
		);
	}
}
