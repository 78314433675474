import React, {Component} from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import iconDownload from './media/icons/download-200.png';
import imgWissenswert from "../goettingen_kita_t/media/static_pages/wissenswertes_g.jpg";

/**
 * Shows a ViewStaticWasSindFrueheHilfen Page
 *
 * @class      ViewStaticWasSindFrueheHilfen (name)
 */
export default class ViewStaticWasSindFrueheHilfen extends Component {

    render() {
        return (
            <div className="view-static-fruehe-hilfen">
                <h1 className={"title"}>Was sind Frühe Hilfen?</h1>
                <p><strong>Frühe Hilfen </strong><strong>sind Angebote für</strong> werdende
                    Eltern und Familien mit Kindern von null bis drei Jahren und haben das Ziel,
                    von Anfang an gute Voraussetzungen für die Entwicklung jedes Kindes zu
                    schaffen. Das gelingt durch Stärkung der elterlichen Beziehungs- und
                    Erziehungskompetenz.</p>
                <p>Die Angebote der Frühen Hilfen bieten Eltern Unterstützung, Beratung und
                    Begleitung, sind freiwillig und richten sich an alle Eltern, auch an Eltern
                    mit besonderen Belastungen (zum Beispiel psychisch erkrankte oder
                    jugendliche Eltern sowie Alleinerziehende).</p>
                <p>Die vielfältigen Angebote und Maßnahmen der Frühen Hilfen werden in Stuttgart
                    im Kommunalen Netzwerk Frühe Hilfen koordiniert und bedarfsgerecht auf- und
                    ausgebaut. Im Netzwerk Frühe Hilfen arbeiten verschiedene Fachkräfte aus den
                    Bereichen der Kinder- und Jugendhilfe, des Gesundheitswesens, der
                    Schwangerschaftsberatung, der interdisziplinären Frühförderung und weiterer
                    psychosozialer Dienste zusammen.</p>
                <p style={{"textAlign": "center"}}><em>Mehr Infos über die Frühen Hilfen finden Sie
                    in diesem Video</em><br/>
                </p>
                    <div className={"video-wrapper"}>
                        <ReactPlayer style={{position: 'absolute', top: '0', left: '0'}} width='100%' height='100%'
                                     url='https://www.youtube.com/watch?v=uLNlWI4ZN3A'/>

                        {/*<iframe loading="lazy" src="https://www.youtube.com/embed/uLNlWI4ZN3A"*/}
                        {/*        title="YouTube video player"*/}
                        {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                        {/*        allowFullScreen=""*/}
                        {/*        width="100p%" height="100p%"*/}

                        {/*        frameBorder="0">*/}
                        {/*    */}
                        {/*</iframe>*/}
                    </div>
                <p style={{"textAlign": "justify"}}>Das Stuttgarter Rahmenkonzept „Frühe Hilfen“
                    wird seit 2010 umgesetzt und unterliegt den aktuellen fachlichen
                    Weiterentwicklungen im Bereich Frühe Hilfen, die sowohl stadtweit als auch
                    träger- und systemübergreifend gelten.</p>
                <p>Das Konzept fasst die Entwicklungen und Planungen der letzten Jahre zusammen
                    und beschreibt den aktuellen stadtweiten Stand der Vernetzungsstruktur und
                    der Angebotslandschaft in der Landeshauptstadt Stuttgart:</p>

                <hr/>

                <div className="row download-box">
                    <div className="col-info">
                        <h2>Rahmenkonzeption Frühe Hilfen</h2>
                        <p>Hier können Sie sich die Rahmenkonzeption Frühe Hilfen im PDF Formart herunterladen</p>
                    </div>
                    <div className="col-download">
                        <a className="icon-pdf" href={process.env.PUBLIC_URL + '/pdf/RahmenkonzeptFrüheHilfenInStuttgart.pdf'}>&nbsp;</a>
                    </div>
                </div>

            </div>
        );
    }
}