import React from "react";
import Helper from "../tools/Helper";

export default class CmsCardData {

	constructor(ID, title, parent, children = []) {
		this.ID = ID;
		this.title = title;
		this.slug = Helper.convertToSlug(title);
		this.parent = parent;
		this.children = children;

		//store data from API
		this.data = null;
	}

}

