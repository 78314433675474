import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

import Config from '../../Config';
import ConfigCards from '../../ConfigCards';
import {AppContext} from '../../common/app/AppContext';

import CookieUtil from '../../common/tools/CookieUtil.js';
import Helper from '../../common/tools/Helper';
import FontResizer from '../../common/components/FontResizer';
import ContrastChanger from "../../common/components/ContrastChanger";
import SearchFormEnhanced from "../../common/components/SearchFormEnhanced";

import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    WhatsappIcon
} from "react-share";


/**
 * This class describes the header.
 *
 * @class      Header (name)
 * @prop        {boolean} showHero - hide || show the hero video
 * @prop        {object} heroData - {img, title, body}
 */
class Header extends Component {

    static defaultProps = {
        heroData: {
            img: Config.ImgHeaderHauptbild
        }
    }

    constructor(props) {
        super(props);
    }

    setBookmark() {
        window.alert('Bitte nutzen Sie die Lesezeichen-Funktion Ihres Browsers.');
    }

    createMenuItems(pConfigMenuLinks) {
        let menuLinks = [];
        let count = 0;
        menuLinks.push(<li key={count++} className="main_links"><a href="#"
                                                                   onClick={e => Helper.reload(e)}>Startseite</a></li>);
        for (let route of pConfigMenuLinks) {
            if (route.menu_untermenus && Object.keys(route.menu_untermenus).length > 0) {
                menuLinks.push(<li key={count++} className="links_title">{route.menu_title}</li>);
                let items = route.menu_untermenus;
                for (let item of items) {
                    if (item.menu_link_to) {
                        menuLinks.push(<li key={count++} data-toggle="collapse" data-target="#menu">
                            <NavLink to={item.menu_link_to}>{item.menu_title}</NavLink>
                        </li>);
                    } else if (item.menuhandel_filters) {
                        menuLinks.push(<li key={count++} data-toggle="collapse" data-target="#menu"
                        >
                            <NavLink to=''
                                     onClick={e => this.context.callApiSearcResultshFromCardFiltersOr(item.menuhandel_filters)}>
                                {item.menu_title}
                            </NavLink></li>);
                    }
                }
            } else if (route.menu_link_to) {
                menuLinks.push(<li key={count++} className="main_links" data-toggle="collapse" data-target="#menu">
                    <NavLink to={route.menu_link_to}>{route.menu_title}</NavLink></li>);
            }
        }
        return menuLinks;
    }

    handleShowFavoriteList(){
      let favoritesFromCookie = CookieUtil.getCookieFavoritesDatasetsCurrent();
      let datasetsForFilter = [];

      //original weiter
      if(favoritesFromCookie !== null){
        let datasetIds = Object.keys(favoritesFromCookie);
        datasetIds.forEach((datasetId, i) => {
          console.log("handleShowFavoriteList ",datasetId, i);
          let value = favoritesFromCookie[datasetId];
          let isFavorite = value["js_favorite"];
          if(isFavorite){
            datasetsForFilter.push(datasetId);
          }
        });
      }

      this.context.resetAllFilters().then( () => {
        this.context.setFilterDatasets(datasetsForFilter).then( () => {
          this.context.searchApiOffersWithFilters();
        });
      });
    }

    render() {

        let hero_url, hero_name;
        //set header img
        if(this.context.header_img){
            hero_url = this.context.header_img.url;
            hero_name = this.context.header_img.name;
        }
        else{
            hero_url = this.props.heroData.img;
            hero_name = "default";
        }

        let searchFormEnhanced = '';
        if (this.props.p_showSearchFormEnhanced == null || this.props.p_showSearchFormEnhanced) {
            searchFormEnhanced = (<SearchFormEnhanced/>);
        }

        let menuitems = this.createMenuItems(ConfigCards.MENU_LINKS_ROUTE);
        let header_class = this.props.searchNavTop ? ' search-nav-top' : '';

        //add image name to header class
        header_class += ' header-img-' + hero_name;

        let header_bg_style = {
            backgroundImage: "url(" + hero_url +")"
        };
        let logo_bg_style = {
            backgroundImage: "url(" + Config.ImgHeaderLogoMandant + ")"
        };

        let elemFavoriteList = "";
        if(Config.favoritesOn){
          elemFavoriteList = (<button onClick={() => this.handleShowFavoriteList()}><i className="fa fa-star" style={{color:'orange'}}></i></button>);
        }

        return (

            <header className={"site-header" + header_class} style={header_bg_style}>
                <div className="container">
                    <div className="menuwrapper">
                        <div className="menurow collapse" id="menu">
                            <div className="menucol">
                                <div className="menucloser">
                                    <i className="fa fa-times navbar-toggler"
                                       data-toggle="collapse" data-target="#menu"></i>
                                </div>
                                <h4 className="title">Menu-Titel</h4>
                                <ul className="menuitems">
                                    {menuitems}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row openingtext">
                        <div className="col d-flex justify-content-start site-opening">
                            <div className="row introrow">
                                <div className="logocol">
                                    <div className="logo">
                                        <a className="logobg" onClick={Helper.reload} title="zur Startseite"
                                           style={logo_bg_style}
                                        >
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="justify-content-end site-menus">
                            <div className="desktopfunctionscol">

                                <ContrastChanger contrast_mode={this.context.high_contrast}>Kontrast</ContrastChanger>
                                <FontResizer components={Config.FONT_RESIZE_COMPONENTS}
                                             resize={this.context.font_resize}/>
                                <button onClick={() => window.print()}><i className="fa fa-print"></i></button>
                                <button onClick={() => this.setBookmark()}><i className="fa fa-bookmark"></i></button>
                                <button className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"><i className="fa fa-share-alt"></i></button>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <EmailShareButton url={window.location.href}>
                                        <EmailIcon size={24} round={true}/>
                                    </EmailShareButton>
                                    <FacebookShareButton url={window.location.href}>
                                        <FacebookIcon size={24} round={true}/>
                                    </FacebookShareButton>
                                    <WhatsappShareButton url={window.location.href}>
                                        <WhatsappIcon size={24} round={true}/>
                                    </WhatsappShareButton>
                                </div>
                                <button>
                                    <i className="fa fa-lock"></i>
                                </button>
                                {elemFavoriteList}

                            </div>
                            <div className="mainmenucol">
                                <nav className="navbar justify-content-end">
                                    <div className="navbar-header">
                                        <button type="button" className="navbar-toggler collapsed"
                                                data-toggle="collapse" data-target="#menu" aria-expanded="false">
                                            <span className="sr-only">Toggle navigation</span>
                                            <i className="fa fa-bars"></i>
                                        </button>
                                    </div>
                                </nav>
                                <div className="text">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {searchFormEnhanced}

                </div>

            </header>

        );
    }
}

Header.contextType = AppContext;
export default Header;
