import React from 'react';

/**
 * This is the context for all components, which makes it possible to call these functions from everywhere in the app without passing them down.
 */
export const AppContext = React.createContext({
	//Filter für Detailssuche, filters (mit options), alle aus Config.FILTER_IDS
	filters: {},
	//gehört zu der Detailssuche
	location_filter: null,
	//Suche von Kacheln
	context_filters_or:  [],
	//exptra Filter für Datum, wird bei jede neue Suche zusätzlich abgefragt
	filterJoin_date: null,
	//extra Filter für Regionsuche, wird bei jede neue Suche zusätzlich abgefragt
	// district, ist extra doppelt initialisiert:
	// 1. hier extra für eine globale (zentrale) Suche
	// 2. in den filters für die Kacheln Entweder-Oder mit Und-Verknüpfung für die 'locale' einmalige Suche (siehe erste Konfiguration unter FGIF ConfigCards)
	filterJoin_district: {},




	search_results: '',
	search_detail: '',
	quicksearch_results: '',
	provider_results: '',

	getActiveFiltersAsJSX: () => {},
	checkFiltersActive: () => {},
	getDetailsFromApi: () => {},
	getProviderDetailsFromApi: () => {},
	callApiSearchResultsFromDetails: () => {},
	callApiSearcResultshFromCardFiltersOr: () => {},
	callApiSearchResultsWithoutAllFilters: () => {},
	callApiSearchWithCurFilters: () => {},

	resetAllFilters: () => {},
	resetAllFiltersAndSearch: () => {},
	resetAllFiltersAndQuickSearch: () => {},
	resetFilter: () => {},
	resetFilterWithID: () => {},
	searchText: () => {},
	searchLast: () => {},
	gotoOfferDetail: () => {},
	gotoProvider: () => {},

	setDateFilter: () => {},
	setDateFilterAndQuickSearch: () => {},
	setFilterAndQuickSearch: () => {},
	setFilterAndSearch: () => {},
	setMultipleFilters: () => {},

	toggleFilterAndSearch: () => {},
	toggleFilterAndQuickSearch: () => {},
	quickSearch: () => {},

	font_resize: null,
	high_contrast: null,
	switchContrast: () => {},
	setFontResize: () => {},

	setMainTextfilter: () => {}
	,getMainTextfilter: () => {}
	,getMainDatefilter: () => {}

	,setMainFilterDateAndSearch: () => {}
	,setKommunefilterAndSearch: () => {}
	,filtersLoaded: () => {}

	,callApiSearchResultsFavorites: () => {}

	,s_cookies: []
	,refreshCookieState: () => {}

	,s_historylocal: []
});
