import React, { Component } from 'react';
import { Link } from "react-router-dom";

import Config from '../../Config.js';
import {AppContext} from '../app/AppContext';
import CookieUtil from '../tools/CookieUtil.js';
import SvgIcons from "./SvgIcons.js";
import {SvgIconsConstants} from './SvgIconsConstants.js';

/**
 * CookieBanner
 * V2, da das Kontaktformular muss auf Dauer rausgenommen werden
 * hier ist vorausgearbeitet
 *
 * @class      CookieBanner (name)
 */
export default class CookieBanner extends Component {

	constructor(props){
		super(props);

		//immer an
		this.m_contentFunktional = '';
		//wählbar
		this.m_contentAnalyse = '';
		this.m_contentGoogleMaps = '';
		this.m_isAvailableGoogleMap = false;
		this.m_isAvailableMotomo = false;

		this.state = {
			 s_showViewBanner: false
			,s_cookieGmap: false
			,s_cookieMotomo: false
			,s_showViewEinstellungen: false
		}

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSaveEinstellungen = this.handleSaveEinstellungen.bind(this);
	}

	//braucht man ein compoenentUpdate ?
	componentDidMount(){
		this.m_configCookiedefault = Config.COOKIE_DEFAULT_VALUE;
		this.m_isAvailableGoogleMap = this.m_configCookiedefault.hasOwnProperty('cookie_gmap');
		this.m_isAvailableMotomo = this.m_configCookiedefault.hasOwnProperty('cookie_motomo');

		let currentCookies = CookieUtil.getCookiesCurrent();

		this.setState({
			s_showViewBanner: !currentCookies[CookieUtil.g_cookieAccepted],
			s_cookieGmap: this.m_isAvailableGoogleMap ? currentCookies[CookieUtil.g_cookieGoogleMap] : false,
			s_cookieMotomo: this.m_isAvailableMotomo ? currentCookies[CookieUtil.g_cookieMotomo] : false
		});
	}
	handleAllesZustimmen(){
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieAccepted, true);
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieGoogleMap, this.getTrueIfAvailableGoogleMap());
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieMotomo, this.getTrueIfAvailableMototmo());

		this.setState({
			s_showViewBanner: false,
			s_cookieGmap: this.getTrueIfAvailableGoogleMap(),
			s_cookieMotomo: this.getTrueIfAvailableMototmo()
		}, this.cookiesUpdate());
	}
	handleAllesAblehnen(){
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieAccepted, false);
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieGoogleMap, false);
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieMotomo, false);
		this.setState({
			s_showViewBanner: false,
			s_cookieGmap: false,
			s_cookieMotomo: false
		}, this.cookiesUpdate());
	}
	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}
	handleSaveEinstellungen(){
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieAccepted, true);
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieGoogleMap, this.m_isAvailableGoogleMap ? this.state.s_cookieGmap : false);
		CookieUtil.saveCookieBanner(CookieUtil.g_cookieMotomo, this.m_isAvailableMotomo ?this.state.s_cookieMotomo : false);

		this.setState({
			s_showViewEinstellungen: false,
			s_showViewBanner: false
		}, this.cookiesUpdate());
	}

	cookiesUpdate(){
		let updateCookieValues = CookieUtil.getCookiesCurrent();
		this.context.refreshCookieState(updateCookieValues);
	}

	showViewEinstellungen(pEvent){
		this.setState({
			s_showViewBanner: true
		});
	}

	getTrueIfAvailableGoogleMap(){
		return this.m_isAvailableGoogleMap ? true : false;
	}
	getTrueIfAvailableMototmo(){
		return this.m_isAvailableMotomo ? true : false;
	}

	getContentGoogleMap(){
		return this.m_isAvailableGoogleMap
		? (<div className="col c_contentpart">
				<div className="row">
					<h3>Google Maps</h3>
					<input type="checkbox" name="s_cookieGmap"	checked={this.state.s_cookieGmap}	onChange={this.handleInputChange}  />
					{this.m_contentGoogleMaps}
				</div>
			</div>)
		: (<div className="col c_contentpart">
				<div className="row">
					<h3>Google Maps</h3>
					<input type="checkbox" disabled  />
					{this.m_contentGoogleMaps}
				</div>
			</div>);
	}

	handleShowDetails(pEvent){
		let showToggleSettings = this.state.s_showViewEinstellungen;
		if(showToggleSettings){
			this.m_contentFunktional = (<div className="c_contentpartOpened"><p>Die essentiellen Funktionen dieser Webseite sind nicht abwählbar, damit die Grundfunktionen gegeben sind.</p>
				<p>Weitere Informationen erhalten Sie in der <a href="/datenschutz/" target="_blank">Datenschutzerklärung.</a></p></div>);
			this.m_contentAnalyse = (<div className="c_contentpartOpened">Für unsere Webseiten verwenden wir Matomo / PIWIK, dabei handelt es sich um einen sogenannten Webanalysedienst, der uns die anonyme Auswertung Ihres Nutzungsverhaltenen auf unseren Webseiten ermöglicht. Matomo / Piwik verwendet Cookies, die auf Ihrem Computer in Ihrem aktuellen Browser gespeichert werden. Zu diesem Zweck werden die erzeugten Nutzungsinformationen (einschließlich Ihrer gekürzten IP-Adresse) an unseren Server übertragen. Ihre IP-Adresse wird bei diesem Vorgang umgehend anonymisiert, sodass Sie als Nutzer für uns anonym bleiben. Die durch ein Cookie erzeugten Informationen über Ihre Nutzung unserer Webseiten werden nicht an Dritte weitergegeben.</div>);

			this.m_contentGoogleMaps = (<div className="c_contentpartOpened">
					<p>Auf unseren Webseiten binden wir Inhalte von Google Maps ein, um Ihnen den genauen Standort des Angebotes oder des Anbieters vereinfacht darstellen zu können. Dadurch wird automatisch eine Verbindung von Ihrem Browser zum Google Maps Server aufgebaut und somit auch Ihre IP-Adresse übermittelt. Sollten Sie parallel zum Besuch unserer Webseiten auch bei Google mit einem vorhandenen Benutzerkonto eingeloggt sein, kann Google womöglich auch den Besuch unserer Webseiten Ihrem Nutzerverhalten zuordnen. Zudem können weitere Cookies durch Google an Ihren Browser gesendet werden. Auf diese Vorgehensweise haben wir keinen Einfluss und erhalten auch keine Informationen von Google über die übermittelten Inhalte.</p>
					<p>Anbieter ist die Google Inc. 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.</p>
					<p>Weitere Informationen zum Datenschutz bei der Google Inc. finden Sie unter:</p>
					<p><a href="https://www.google.com/intl/de/policies/privacy/" target="_blank">https://www.google.com/intl/de/policies/privacy/</a></p>
				</div>);
		}else{
			this.m_contentFunktional = ('');
			this.m_contentAnalyse = ('');
			this.m_contentGoogleMaps = ('');
		}
		this.setState({
			s_showViewEinstellungen: !showToggleSettings
		});
	}

	getViewCookieBanner(pEvent){
		let cClassBtnDetails = '';
		if(this.state.s_showViewEinstellungen){
			cClassBtnDetails = ' active ';
		}


		let contentGoogleMaps = this.getContentGoogleMap();




	  //let hide_banner = this.state.s_showViewBanner ? 'hide_banner' : '';
		//<div id="CookieBanner" className={hide_banner}>
		return (
				<div id="CookieBanner">
					<div className="CookieBanner-bg"></div>
					<div className="CookieBanner-content c_center_horizontal">
						<div className="container c_cookie_content">
							<h2>Ihre Cookie-Einstellungen</h2>
							<p>
								Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer finden Sie in unserer <a href="/datenschutz/" target="_blank" style={{textDecorationLine:'underline'}}>Datenschutzerklärung.</a> Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren und direkt unsere Website ohne Einschränkungen besuchen zu können.
							</p>
							<p>Cookies lassen sich jederzeit <a href="#" onClick={(e) => this.handleAllesAblehnen(e)} style={{/*fontWeight:'bold', */textDecorationLine:'underline'}} >alles ablehnen</a> oder in den Einstellungen anpassen.</p>
							<div className="cCookieSettingsHeader row">
								<div className="col c_contentpart">
									<div className="row">
										<h3>Essentiell</h3>
										<input type="checkbox" checked disabled />
										{this.m_contentFunktional}
									</div>
								</div>

								{contentGoogleMaps}


								<div className="col c_contentpart">
									<div className="row">
										<h3>Analyse/Motomo</h3>
										<input type="checkbox"
											   name="s_cookieMotomo"
											   checked={this.state.s_cookieMotomo}
											   onChange={this.handleInputChange}/>
										{this.m_contentAnalyse}
									</div>
								</div>
							</div>
							<div className={"c_btn_details "+cClassBtnDetails} onClick={(e) => this.handleShowDetails(e)} >Details</div>


							<div className="d-flex justify-content-between">
								{/*className="col" -> über die ganze Breite aufgeteilt*/}
								<button type="button" className="btn btn-lg btn btn-primary cAcceptSelected" onClick={(e) => this.handleSaveEinstellungen(e)}>Auswahl bestätigen</button>
								<button type="button" className="btn btn-lg cBbtnAcceptAll" onClick={(e) => this.handleAllesZustimmen(e)}>Alle auswählen und übernehmen</button>
							</div>

							<div className="d-flex flex-row-reverse"><a href="/impressum/" target="_blank" >Impressum</a></div>
						</div>
					</div>
				</div>
			);
	}

  render() {
		if(this.state.s_showViewBanner){
			document.body.classList.add('c_cookie_banner_show');
			return this.getViewCookieBanner();
		}
		document.body.classList.remove('c_cookie_banner_show');
		return (
			<div id="CookieBanner">
				<div className="cCookieBannerImg">
					<div className="container"
						onClick={(e) => this.showViewEinstellungen(e)}>
						<SvgIcons className="c_svg_handshace"
		                            p_IconKey={SvgIconsConstants.g_IconHandshake}
		                            p_Tooltip="Datenschutz Einstellungen"
		                            width="64"
		                            fill={"grey"}
		                            />
					</div>
				</div>
			</div>
		);
  }
}

//bind to AppContext
CookieBanner.contextType = AppContext;
