import React, { Component } from 'react';

class MerkmalWertebereich extends Component {

	render() {

		let wertebereich_data = this.props.wertebereich_data.MultipleMerkmalId;
		let label = this.props.wertebereich_data.Label;

		let bereiche = [];
		for(const [index, snippet] of wertebereich_data.entries())
		{
			bereiche.push(<p key={index} className="wertebereich">von: {snippet.Values[0].Value} bis: {snippet.Values[1].Value}</p>);
		}

		return bereiche;
	}
}

export default MerkmalWertebereich;
