import React, { Component } from 'react';
import { Route } from "react-router-dom";

/**
 * The RouteWrapper function takes a component and wrapps it in a layout.
 *
 * @class      RouteWrapper (name)
 * @param      {Component}  component  The component
 * @param      {Layout}  layout   The layout
 */
export default function RouteWrapper({
  component: Component, 
  layout: Layout, 
  ...layoutRouterProps
}) {
  return (
    <Route {...layoutRouterProps} render={(props) =>
        <Layout {...props} {...layoutRouterProps}>
          <Component {...props}/>
        </Layout>
      }
    />
  );
}