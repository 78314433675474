import React, {Component} from 'react';

class PopUpMessage extends Component {
    render() {
        let class_show = (this.props.show) ? 'show' : 'hidden';

        return (
            <div className={"pop-up-message " + class_show}>
                <div className="message-header">
                    <span>&copy;</span>Copyright
                        <button type="button" className="message-btn-close" onClick={() => this.props.toggle()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                <div className="message-body">
                    Frederik Laux, Stuttgart
                </div>
            </div>
        );
    }
}

export default PopUpMessage;

