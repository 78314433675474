import React from 'react';
import PropTypes from 'prop-types';

import Config from '../../Config';
import ScssVariables from '../../ConfigScss.scss';
import Favorite from '../../common/components/Favorite.js';
import SvgIcons from "../components/SvgIcons.js";
import {SvgIconsConstants} from "../components/SvgIconsConstants.js";
import ResultSetComponents from '../../common/components/ResultSetComponents';
import Helper from '../../common/tools/Helper';
import {Link} from "react-router-dom";

/**
 * This component renders the search results
 *
 * @class      ResultSet (name)
 * @props 		topic, region, description, date, time, offer_name, max_length, orga_name, address
 * @prop 		{function} callBack - function should make an API call to load the detail
 * @prop 		{function} callBackMap - function calls map to show address
 */
export default class ResultSet extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {

		let topic = null;
		if(this.props.topic){
			topic = (
					<div className="eventtopic">
						{this.props.topic}
					</div>
				);
		}

		let region = null;
		if(this.props.region)
		{
			region = (
					<div className="eventregion">
						{this.props.region}
					</div>
				);
		}

		let description = null;
		if(this.props.description)
		{
			let description_short = Helper.shorten(this.props.description, 400);
			description = (
					<div className="eventdescription">
						{description_short}
					</div>
				);
		}

		let icons = [];
		let all_icons = null;
		let icon_map = Config.IconSvgs_Map;

		if(this.props.accessibility_data)
		{
			let sonstiges = '';
			for(const entry of this.props.accessibility_data)
			{
				let entryId = entry.id;
				if(icon_map[entryId])
				{
					let fill_color = '#fff';
					//get color from Config
					if(Config.ACCESSIBILITY_ICON_COLOR)
						fill_color = Config.ACCESSIBILITY_ICON_COLOR;

					icons.push(
						<SvgIcons p_IconKey={icon_map[entryId]} p_Tooltip={entry.alt}  fill={fill_color} key={entryId} />
					);
				}else{
					sonstiges += ' ' + entry.alt;
				}
			}

			let keineAngaben = '';//icons.length === 0 ? 'keine Angabe' : '';
			sonstiges = (<span>{sonstiges.length > 0 ? sonstiges : keineAngaben} </span>);

			//<SvgIcons className="icon_rollstuhl" p_IconKey={SvgIconsConstants.g_IconSvgRollstuhl} p_Tooltip="Barrierefreiheit" fill='#fff' key='-1'/>
			all_icons = (<div className="card-accessibilty-icons">
											{icons}
											{sonstiges}
										</div>);
		}

		let classNameResultSets = "result-set";
		if(this.props._cFixHight){
			classNameResultSets += " " + this.props._cFixHight;
		}

		let elemNew = ResultSetComponents.getElementNewDatasetIcon(this.props.p_datePublished);
		let elemFavorite = (<Favorite p_id={this.props.id} p_favoriteSet={this.props.p_favoriteSet} />);

		//no address found? Add class to eventregion
		let class_eventregion = (this.props.address.street || this.props.address.city)? '' : 'no-address';

		return (
			<div className={classNameResultSets} data-id={this.props.id}>
				<div className="card-content-wrapper">
					<div className="card-img-top"></div>
					<div className="card-body">
						{elemNew}
						{elemFavorite}
						<div className="event">
							<div className="eventdatetime">
								<div className="eventdate">
									{this.props.date}
								</div>
								<div className="eventtime">
									{this.props.time}
								</div>
							</div>
							<div className="eventinfo">
								{topic}
								{description}
								<div className="eventoffername" title={this.props.offer_name}>
									{/*Angebotsname wird verlinkt*/}
									<span className="linkTitel" onClick={e => this.props.callBack(this.props.id)}>
										{Helper.strMaxLength(this.props.offer_name, this.props.max_length)}
									</span>
								</div>
								<div className="eventorganame">
									<Link to={"/detailorganisation/" + this.props.orga_id}>{this.props.orga_name}</Link>
									{this.props.p_elemspezialprovideroffer}
								</div>
								{region}
							</div>
						</div>
					</div>
					<div className="card-footer">
						<div className={"eventregion " + class_eventregion}>
							<div className="eventmapbtn" onClick={
									(e) => {
										if(this.props.callBackMap)
											this.props.callBackMap(this.props.id)
									}
								}>
								<SvgIcons p_IconKey={SvgIconsConstants.g_IconResultSetSvgOrt_Filled} p_Tooltip="Ort" fill={ScssVariables.iconsSvg_color} />
							</div>
							<div className="eventaddress" onClick={
									(e) => {
										if(this.props.callBackMap)
											this.props.callBackMap(this.props.id)
									}
								}>
								<p className="title">Adresse</p>
								<p>{this.props.address.street} <br/>{this.props.address.city}</p>
							</div>
						</div>
						<div className="eventmorebar">
							<div className="separator"></div>
							<button onClick={(e) => this.props.callBack(this.props.id)}>mehr</button>
						</div>
					</div>
				</div>

				{all_icons}

			</div>
		);
	}
}
ResultSet.propTypes = {
  p_datePublished: PropTypes.string.isRequired,
	p_favoriteSet: PropTypes.bool.isRequired
};
