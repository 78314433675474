import axios from 'axios';
import Helper from '../tools/Helper';
import Config from '../../Config';
import ConfigCards from '../../ConfigCards';
import ApiSearchOptions from "./ApiSearchOptions";
import FilterSendKontaktForm from './FilterSendKontaktForm';

/**
 * This class makes all the API calls and returns the results, when ready.
 * The API url is read from the Config.js
 *
 * @class      ApiConnector (name)
 */
class ApiConnector {

    static getFamFreundOrteOffers(callback_result, callback_error) {
        let jsonFamFreundOrteConfig = Config.FAMFREUNDORTS_OFFERTYPE_CONFIG;
        if (!jsonFamFreundOrteConfig || jsonFamFreundOrteConfig.length === 0) {
            // handle error
            console.log("BUG: API getFamFreundOrteOffers FAMFREUNDORTS_OFFERTYPE_CONFIG ist leer");
            if (callback_error)
                callback_error("Konfiguration fehlt");
            else
                ApiConnector.showError("Konfiguration fehlt");
            return;
        }

        let offerTypeIds = [];
        Helper.getValuesFromKeyJsonRecursiv(jsonFamFreundOrteConfig, "ConfOfferTypeId", offerTypeIds);
        let filterOfferTypes = this.buildOfferTypesFilter(offerTypeIds);
        let search_options = {
            method: 'post',
            url: Config.API_URL,
            data: [filterOfferTypes],
            headers: {
                Authorization: 'Bearer ' + Config.BEARER_TOKEN
            }
        };

        //make api call
        axios(search_options)
            .then(function (response) {
                // handle success
                callback_result(response.data.Body);
            })
            .catch(function (error) {
                // handle error
                console.error("API getFamFreundOrteOffers error");
                if (callback_error)
                    callback_error(error);
                else
                    ApiConnector.showError(error);

            })
    }

    // *******************************************************************
    static getBesondereOrte(callback_result, callback_error) {
        let configBesOrte = ConfigCards.SITE_BESONDERE_ORTE;
        if (!configBesOrte || configBesOrte.length === 0) {
            // handle error
            console.error("BUG: API getBesondereOrte SITE_BESONDERE_ORTE ist leer");
            if (callback_error)
                callback_error("Konfiguration fehlt");
            else
                ApiConnector.showError("Konfiguration fehlt");
            return;
        }
        let filterOption = configBesOrte.cardhandel_filters;
        if (!filterOption || filterOption.length === 0) {
            // handle error
            console.error("BUG: API getFamFreundOrteOffers FAMFREUNDORTS_OFFERTYPE_CONFIG.cardhandel_filters ist leer");
            if (callback_error)
                callback_error("Konfiguration fehlt");
            else
                ApiConnector.showError("Konfiguration fehlt");
            return;
        }

        let valueOfferType = filterOption[0].offer_types;
        let filterOfferTypes = this.buildOfferTypesFilter(valueOfferType);
        let search_options = {
            method: 'post',
            url: Config.API_URL,
            data: [filterOfferTypes],
            headers: {
                Authorization: 'Bearer ' + Config.BEARER_TOKEN
            }
        };

        //make api call
        axios(search_options)
            .then(function (response) {
                // handle success
                callback_result(response.data.Body);
            })
            .catch(function (error) {
                // handle error
                console.log("API getBesondereOrte error");
                if (callback_error)
                    callback_error(error);
                else
                    ApiConnector.showError(error);

            })
    }


    /**
     * Makes a search call to the api for a DatasetId
     *
     * @param      {string}    dataset_id       - The dataset identifier
     * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
     * @param      {Function}  callback_error   - error function
     */
    static async searchDatasetID(dataset_id) {

        let search_options = new ApiSearchOptions();
        search_options.data.push({
            "Type": "DatasetId",
            "MerkmalTypeIDs": [],
            "Fields": [],
            "Values": [dataset_id],
            "OperatorType": ""
        });

        //make api call
        let axios_promise =  axios(search_options);

        //handle error
        axios_promise.catch(function (error) {
                // handle error
                console.log("API searchDatasetID error");
                ApiConnector.showError(error);
            })

        return axios_promise;
    }

    /**
     * Makes a search call to the api for a hashcode for the backend preview
     *
     * @param      {string}    dataset_id       - The dataset identifier
     * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
     * @param      {Function}  callback_error   - error function
     */
    static async searchHashcode(hashcode, callback_result, callback_error) {

        let search_options = new ApiSearchOptions();
        search_options.data.push(
            {
                "Type": "hashcode",
                "MerkmalTypeIDs": [],
                "Fields": [],
                "Values": [hashcode],
                "OperatorType": ""
            }
        );

        //make api call
        return axios(search_options)
            .then(function (response) {
                // handle success
                console.log("API searchHashcode success", response.data.Body);
                callback_result(response.data.Body);
            })
            .catch(function (error) {
                // handle error
                console.log("API searchHashcode error", error);
                if (callback_error)
                    callback_error(error);
                else
                    ApiConnector.showError(error);

            });
    }

    /** Damit können die Schritte dieser Anwendung in einer Datei auf dem Server protokoliert werden  */
    static async LogMsgFromFrontend(message) {
        let search_options = new ApiSearchOptions();
        search_options.url = Config.API_URL + "/LogMsgFromFrontend";
        search_options.data = message;

        //make api call
        return axios(search_options).catch(function (error) {
                // handle error
                console.log("API searchHashcode error", error);
                ApiConnector.showError(error);
            });
    }

    /**
     * Makes a search call with a search string to the API
     *
     * @param      {Array || Object}    filter               - The search filters > these need to be made "API ready" with one of the ApiConnector.build... functions
     * @param      {Function}  callback_result            - function is called when result arrives with result json as parameter
     * @param      {Function}  callback_error            - error function
     * @param      {string}    [alternate_bearer_token=null]  - The funcion accepts an alternate_bearer_token for the quicksearch
     */
    static async search(filter, alternate_bearer_token = null) {

        let bearer_token = Config.BEARER_TOKEN;
        //check for alternate_bearer_token
        if (alternate_bearer_token)
            bearer_token = alternate_bearer_token;

        let search_options = new ApiSearchOptions();

        //add filter
        if (filter) {
            if (Array.isArray(filter)) {
                //merge arrays
                search_options.data = [...search_options.data, ...filter];
            } else {
                //we asume filter is a single object, that we can push into the array
                search_options.data.push(filter);
            }
        }

        console.log("ApiConnector search_options", JSON.stringify(search_options));
        console.log("ApiConnector search_data", JSON.stringify(search_options.data));

        let axios_promise = axios(search_options);

        // catch error
        axios_promise.catch(function (error) {
                console.error("API searchText error", error);
                ApiConnector.showError(error);
            });

        return axios_promise;
    }

    static async getListItems(merkmal_type_id) {
        console.log('getListItems:', merkmal_type_id);

        let search_options = new ApiSearchOptions();
        search_options.data.push(ApiConnector.buildListItemsFilter(merkmal_type_id));

        console.log(JSON.stringify(search_options.data));

        //make api call
        //returns AxiosPromise
        //handle with:
        //  .then(function (response) {
        //      let data = response.data.Body
        //   })
        let axios_promise =  axios(search_options)
            .catch(function (error) {
                // handle error
                console.error("ApiConnector getListItems error: ", error);
                ApiConnector.showError(error);
            });

        return axios_promise;
    }

    static async getDataSetList(merkmal_types) {
        console.log('getDataSetList:', merkmal_types);

        let search_options = new ApiSearchOptions();
        search_options.data.push(ApiConnector.buildDataSetListFilter(merkmal_types));

        console.log(JSON.stringify(search_options.data));

        //make api call
        let axios_promise =  axios(search_options)
            .catch(function (error) {
                // handle error
                console.error("ApiConnector getDataSetList error: ", error);
                ApiConnector.showError(error);
            });

        return axios_promise;
    }

    /**
     * Gets the offer types.
     *
     * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
     * @param      {Function}  callback_error   - error function
     * @return      {Promise}  axios promise
     */
    static async getOfferTypes(callback_result, callback_error) {
        console.log('getOfferTypes');

        let search_options = new ApiSearchOptions();
        search_options.data.push(ApiConnector.buildOfferTypesItemsFilter());

        //make api call
        //returns AxiosPromise
        //handle with:
        //  .then(function (response) {
        //      let data = response.data.Body
        //   })
        let axios_promise =  axios(search_options)
            .catch(function (error) {
                // handle error
                console.error("ApiConnector getOfferTypesItems error: ", error);
                ApiConnector.showError(error);
            });

        return axios_promise;
    }

    /**
     * Gets the list items from TableName, for Example KontaktFormular
     *
     * @param      {string}    tablename  - Tablename with datas
     * @param      {Function}  callback_result  - function is called when result arrives with result json as parameter
     * @param      {Function}  callback_error   - error function
     */
    static async getListItemsFromTable(tablename, callback_result, callback_error) {
        console.log('getListItemsFromTable: ' + tablename);

        let search_options = new ApiSearchOptions();
        search_options.data.push(
            {
                "Type": "TableData",
                "MerkmalTypeIDs": "",
                "Fields": "",
                "Values": [tablename],
                "OperatorType": "ListItems"
            }
        );

        //make api call
        let axios_promise = axios(search_options);

        axios_promise.then(function (response) {
                // handle success
                console.log("getListItemsFromTable success", response.data);
                callback_result(response.data.Body);
            })
            .catch(function (error) {
                // handle error
                console.log("getListItemsFromTable error", error);
                if (callback_error)
                    callback_error(error);
                else
                    ApiConnector.showError(error);
            });

        return axios_promise;
    }

    static async getProviders(pProviderId, callback_result, callback_error) {
        console.log('getProviders');
        var filterProvider = "";
        if (pProviderId === window.undefined || pProviderId === null || pProviderId.length === 0) {
            filterProvider = [
                {
                    "Type": "ProviderListItems",
                    "MerkmalTypeIDs": [],
                    "Fields": [],
                    "Values": [],
                    "OperatorType": "ALL"
                }
            ]
        } else {

            let ids = [];
            if(Array.isArray(pProviderId))
            {
                ids = [...pProviderId];
            }
            else
            {
                ids.push(pProviderId);
            }

            filterProvider = [
                {
                    "Type": "ProviderListItems",
                    "MerkmalTypeIDs": ids,
                    "Fields": [],
                    "Values": [],
                    "OperatorType": ""
                }
            ]
        }
        let search_options = new ApiSearchOptions();
        search_options.data = filterProvider;

        console.log(JSON.stringify(search_options.data));

        //make api call
        let axios_promise = axios(search_options);

        axios_promise.then(function (response) {
                // handle success
                if(callback_result)
                    callback_result(response.data.Body);
            })
            .catch(function (error) {
                // handle error
                console.error("API getProviders error");
                if (callback_error)
                    callback_error(error);
                else
                    ApiConnector.showError(error);

            });

        return axios_promise;
    }

    /**
     * Sends a mail from the contact form.
     * The sendmail url is read from the Config.js
     *
     * @param      {<type>}    mail_data  The mail data
     * @param      {Function}  callback   The callback
     */
    static async sendMail(mail_data, callback) {
        let mailDataForApi = null;
        if(Config.MAIL_URL.indexOf('/sendmail')>0){
            //Sendfunction 1.0, nicht mehr zu verwenden, z.Z. basis_t
            mailDataForApi = mail_data;
        }else {
            //Sendversion 2.0, als FilterType, z.Z. fgifhorn_t
            mailDataForApi = new FilterSendKontaktForm(mail_data).buildForAPI();
        }
        console.log("ApiConnectorST sendMail: ", mailDataForApi);

        let mail_options = {
            method: 'post',
            url: Config.MAIL_URL,
            //https://stackoverflow.com/questions/36690451/timeout-feature-in-the-axios-library-is-not-working
            timeout: 1000*5, //Wait for 5 seconds
            data: [mailDataForApi],
            headers: {
                Authorization: 'Bearer ' + Config.BEARER_TOKEN
            }
        };

        let axios_promise = axios(mail_options);

        axios_promise.then(
                function (response) {
                    // handle success
                    console.log("Mail Server responds", response);
                    if (response.status === 202)
                        callback(true);
                    else
                        callback(false);
                },
                function (reason) {
                    // handle error
                    console.log("Mail send error", reason);
                    callback(false);
                }
            );

        return axios_promise;
    }

    /**
     * Standard error function.
     * The error is logged on the console and an error alert is displayed.
     *
     * @param      {Object}  error - The error object
     */
    static showError(error) {
        if (!ApiConnector.last_error || ApiConnector.last_error.message != error.message) {
            ApiConnector.last_error = error;
            console.error(ApiConnector.last_error);
            window.alert('Es gab einen Fehler mit der Serververbindung. Bitte versuchen Sie es später noch mal.');
        }
    }

    /**
     * Builds a list items filter for the API.
     *
     * @param      {String}  merkmal_type_id - The filter identifier
     * @return     {Object}  The list items filter.
     */
    static buildListItemsFilter(merkmal_type_id) {
        return {
            "Type": "ListItems",
            "MerkmalTypeIDs": [merkmal_type_id],
            "Fields": "",
            "Values": "",
            "OperatorType": ""
        };
    }

    /**
     * Builds a DataSetList filter for the API.
     *
     * @param      {Array}  values - an array of datasetIDs
     * @return     {Object}  The DataSetList filter.
     */
    static buildDataSetListFilter(values) {
        return {
            "Type": "DatasetList",
            "MerkmalTypeIDs": "",
            "Fields": "",
            "Values": values,
            "OperatorType": ""
        };
    }


    /**
     * Builds an offer types filter for the API.
     *
     * @param      {<type>}  values  The values
     * @return     {Object}  The offer types filter.
     */
    static buildOfferTypesFilter(values) {
        return {
            "Type": "OfferType",
            "MerkmalTypeIDs": "",
            "Fields": "",
            "Values": values,
            "OperatorType": ""
        };
    }


    /**
     * Builds an offer types items filter for the API.
     *
     * @return     {Object}  The offer types filter.
     */
    static buildOfferTypesItemsFilter() {
        return {
            "Type": "OfferTypeItems",
            "MerkmalTypeIDs": [],
            "Fields": [],
            "Values": [],
            "OperatorType": ""
        };
    }


    /**
     * Builds a Provider filter for the API.
     * OrganisationsId format "guid"
     *
     * @param      {string}  providerId    OrganisationsId
     * @return     {Object}  Organisationsfilter
     */
    static buildOrganisationFilter(providerId) {
        return {
            "Type": "ProviderId",
            "MerkmalTypeIDs": [providerId],
            "Fields": [],
            "Values": [],
            "OperatorType": ""
        };
    }

}

ApiConnector.last_error = null;

export default ApiConnector;
