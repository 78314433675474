import React from 'react';
import MatomoTracker from 'matomo-tracker';
import Config from '../../Config';
import { withRouter } from 'react-router';

/**
 * This is a wrapper for the MatomoTracker.
 * It listens for history changes and sends them to the Matomo Server.
 * The server data is read from the Config.js
 *
 * @class      Tracker (name)
 * @prop 		{boolean} active
 */
class Tracker extends React.Component {

	constructor(props) {
		super(props);

		//initiate tracking
		if(this.props.active){
			this.matomo = new MatomoTracker(Config.TRACKER.id, Config.TRACKER.url);
			this.matomo.track(window.location.href);			
		}
	}

	componentDidMount() {
		this.unlisten = this.props.history.listen((location, action) => {
			// console.log('You changed the page to: ', location.pathname);
			if(this.props.active)
				this.matomo.track(window.location.href);
		});
	}
	
	componentWillUnmount() {
	    this.unlisten();
	}

	render() {
		return null;
	}
}
export default withRouter(Tracker);
