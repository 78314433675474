import Config from "../../Config";

export default class ApiSearchOptions{

    constructor()
    {
        this.method = 'post';
        this.url = Config.API_URL;
        this.data = [];
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Config.BEARER_TOKEN
        }
    }
}


