import cloneDeep from 'lodash/cloneDeep';
import ResultMerkmal from "../model/ResultMerkmal";


/* https://gist.github.com/sphvn/dcdf9d683458f879f593
*  0 => json
*  fn => FUnktionname woher der Aufruf stattfindet
* scope ist erst leer, beinhaltet sonst die Unterobjecte
**/
	const traverse = function(o, fn, scope = []) {
	  for (let i in o) {
			//gibt der Funktion die Werte zurück
	    var stop = fn.apply(this, [i, o[i], scope]);
			//if(stop = "break"){return;}
			//nachdem beenden der Funktion, kommt wieder hierhin zurück und macht weiter:
	    if (o[i] !== null && (typeof o[i] === "object")) {
	      traverse(o[i], fn, scope.concat(i));
	    }
	  }
	}

/**
 * Helper class for searching in json ResultSets from the API
 *
 * @class      ResultHelper (name)
 */
export default class ResultHelper{

	//search for Merkmal with needle (MerkmalTypeId or Label)
	static searchMerkmalId(dataset, needle, is_group = false)
	{
		let merkmal_found = null;
		if(needle)
			needle = needle.toUpperCase();

		//iterate through groups
		if(!is_group)
		{
			if(dataset && dataset.Groups)
			{
				for(let group of dataset.Groups)
				{
					for(let merkmal of group.Merkmale)
					{
						if(merkmal.MerkmalTypeId.toUpperCase() === needle || merkmal.Label.toUpperCase() === needle)
						{
							merkmal_found = merkmal;
							break;
						}
					}

					//break second loop if found
					if(merkmal_found)
						break;
				}
			}
		}
		else{
			for(let merkmal of dataset.Merkmale)
			{
				if(merkmal.MerkmalTypeId.toUpperCase() === needle || merkmal.Label.toUpperCase() === needle)
				{
					merkmal_found = merkmal;
					break;
				}
			}
		}

		return merkmal_found;
	}

	static getFirstValue(merkmal)
	{
		if(merkmal && merkmal.MultipleMerkmalId)
			return merkmal.MultipleMerkmalId[0].Values[0].Value;
		else
			return null;
	}

	static getValueFormatted(pMerkmal, pClassName)
	{
		if(pMerkmal && pMerkmal.MultipleMerkmalId)
		{
			let merkmal = new ResultMerkmal();
			merkmal.setContent(pMerkmal, pClassName);
			return merkmal.m_body;
		}
		else
			return null;
	}

	static getValueAtNum(merkmal, num = 0)
	{
		if(merkmal && merkmal.MultipleMerkmalId)
			return merkmal.MultipleMerkmalId[0].Values[num].Value;
		else
			return null;
	}

	static getFirstFieldData(merkmal)
	{
		if(merkmal && merkmal.MultipleMerkmalId)
			return merkmal.MultipleMerkmalId[0].Values[0].FieldData;
		else
			return null;
	}

	static getValueOnPosition(merkmal, position)
	{
		if(merkmal && merkmal.MultipleMerkmalId && merkmal.MultipleMerkmalId[position])
			return merkmal.MultipleMerkmalId[position].Values[0].Value;
		else
			return null;
	}

	static getMerkmalTypeID(merkmal)
	{
		if(merkmal)
			return merkmal.MerkmalTypeId;
	}

	static getAddress(merkmal)
	{
		let address = {
				street: null,
				city: null
			};

		if(merkmal && merkmal.MultipleMerkmalId)
		{
			let values = merkmal.MultipleMerkmalId[0].Values;
			let street = values[0].Value + " " + values[1].Value;
			let city = values[2].Value + " " + values[3].Value;
			address.street = street;
			address.city = city;
		}

		return address;
	}

	static lengthMerkmal(merkmal)
	{
		if(merkmal && merkmal.MultipleMerkmalId)
			return merkmal.MultipleMerkmalId.length;
		else
			return null;
	}


	static getDatasetsFromApiResultsWhere(pApiResultsJson, pMerkmalTypeIdValue, pFieldDataValue, pSearchInGroup = true){
		pMerkmalTypeIdValue = pMerkmalTypeIdValue.toUpperCase();
		let pResults = [];
		let count = pApiResultsJson.length;
		for(let i = 0; i < count; i++){
			let datasetJson = cloneDeep(pApiResultsJson[i]);
			traverse(datasetJson, (key, value, scope) => {
				if(value && typeof value !== "object"){
					if (value.toString().toUpperCase() === pMerkmalTypeIdValue) {
				    //console.log(`Position: myObject[${scope.concat(key).map(k => isNaN(k) ? `'${k}'` : k).join('][')}]`);
						let objMerkmalTypeId = datasetJson;
						scope.forEach((item, i) => {
							objMerkmalTypeId = objMerkmalTypeId[item];
						});
						let fieldDataValue = this.getFirstFieldData(objMerkmalTypeId);
						if(fieldDataValue && fieldDataValue.toString().toUpperCase() === pFieldDataValue.toString().toUpperCase()){
							pResults.push(datasetJson);
							console.log("getDatasetsFromApiResultsWhere", pResults.length);
							//verlasse die traverse()
							//return "break";
						}
				  }
				}
			});
		}
		return pResults;
	}
}
