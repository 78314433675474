import React, {Component} from 'react';
import {Link, NavLink, Redirect} from "react-router-dom";

import {AppContext} from '../app/AppContext';

import FilterDropDownImmediately from './FilterDropDownImmediately';
import Config from "../../Config";

class SearchCard extends Component {
    static defaultProps = {
        card_link_text: "mehr anzeigen",

        // class_extension = .column-3, bedeutet 3 Spaltigkeit anwenden statt 4 wie auf der Startseite
        class_extension: ''
    }

    constructor(props) {
        super(props);

        this.searchFromCard = this.searchFromCard.bind(this);
    }

    searchFromCard(cardFilters) {
        this.context.setCardFiltersAndSearchApiOffers(cardFilters);
    }

    render() {
        let config = this.props.config;

        this.class_name = "search-card";

        let card_link = <span className="card-link">{this.props.card_link_text}</span>;
        if (!this.props.card_link_text) {
            card_link = "";
        }

        if (config.cardhandel_filterdropdown && config.cardhandel_filterdropdown['card_filter_id'].length > 0) {
            card_link = (<FilterDropDownImmediately
                p_label={config.cardhandel_filterdropdown['card_filter_label']}
                p_SearchFilter={this.context.getFilter(Config.FILTER_IDS.district)}
                is_search_card={true}
            />);
            this.class_name = this.class_name.replace('no-link', '');
        }

        //set img, if we have an url, otherwise set default box
        let card_img;
        if(config.cardimg)
            card_img = <img className="img-fluid" src={config.cardimg} alt={config.cardtitle}/>;
        else
            card_img = <div className="img-placeholder img-fluid">Bild nicht vorhanden</div>;

        this.card = (
            <div className="card">

                <div className="card-body intro">
                    {card_img}
                    <h2 className="card-title">{config.cardtitle}</h2>
                </div>

                <div className="card-body description">
                    <article className="card-text">{config.cardbody}</article>
                </div>

                <div className="card-body more-bar">
                    {card_link}
                </div>
            </div>
        );

        let card_out = null;
        let cssClasses = `${this.class_name} ${this.props.class_extension}`;

        if (config.message && config.message.length > 0) {
            card_out = (
                <section className={cssClasses} onClick={() => {
                    alert(config.message);
                }}>
                    {this.card}
                </section>
            );
        }
        else if (config.cardhandel_filters) {
            card_out = (
                <section className={cssClasses} onClick={() => this.searchFromCard(config.cardhandel_filters)}>
                    {this.card}
                </section>
            );
        }
        else if (config.cardhandel_link_to) {
            card_out = (
                <section className={cssClasses}>
                    <Link to={config.cardhandel_link_to}>
                        {this.card}
                    </Link>
                </section>
            );
        }
        else if (this.props.p_callback && this.props.p_callbackparam) {
            this.callBack = (e) => this.props.p_callback(this.props.p_callbackparam);
            card_out = (<section className={cssClasses} onClick={this.callBack}>
                {/*p_classextension = .column-3, bedeutet 3 Spaltigkeit anwenden statt 4 wie auf der Startseite*/}
                {this.card}
            </section>);
        }
        else if (config.cardhandel_redirect_to) {
            card_out = (
                <section className={cssClasses}>
                    <NavLink to={config.cardhandel_redirect_to}>
                        {this.card}
                    </NavLink>
                </section>
            );
        }
        else if (config.cardhandel_link_to_external) {
            card_out = (
                <section className={cssClasses}>
                    <a href={config.cardhandel_link_to_external} target="_blank">
                        {this.card}
                    </a>
                </section>
            );
        }
        else {
            this.class_name = `${this.class_name}  no-link`;
            card_out = (
                <section className={cssClasses}>
                    {this.card}
                </section>
            );
        }

        return card_out;
    }
}

SearchCard.contextType = AppContext;

export default SearchCard;
