import React, {Component} from 'react';

import {AppContext} from '../../common/app/AppContext';
import Config from "../../Config";
import ResultHelper from "../results/ResultHelper";
import SearchCard from "./SearchCard";
import {Link} from "react-router-dom";

class SearchCardsHolder extends Component {

    constructor(props) {
        super(props);

        this.initialised = false;

        this.state = {
            title: '',
            description: 'Daten werden geladen',
            search_cards: null
        }
    }

    componentDidMount() {
        this.initialise();
    }

    componentDidUpdate(prevProps) {
        this.initialise();
    }

    initialise(){

        //get data for Header && Cards
        if(this.props.card_data && !this.initialised)
        {
            let promises = [];
            promises.push(this.context.getCMSCardData(this.props.card_data));

            //get child data
            for (const child_data of this.props.card_data.children) {
                promises.push(this.context.getCMSCardData(child_data));
            }

            //update state when all data parts are loaded
            Promise.all(promises).then(() => {
                this.createTitleAndCards();
            });

            this.initialised = true;
        }
    }

    async createTitleAndCards(){
        console.log("createTitleAndCards", this.context.cms_card_data);

        let merkmal;
        // let json = {
        //     stammdaten: this.props.card_data.data.Groups[0],
        //     themenseite: this.props.card_data.data.Groups[1]
        // }
        let json = this.props.card_data.data;

        //get title from "Stammdaten"
        merkmal = ResultHelper.searchMerkmalId(json, Config.CMS_IDS.TOPIC_HEADLINE);
        let data_title = ResultHelper.getFirstValue(merkmal);

        //get description
        merkmal = ResultHelper.searchMerkmalId(json, Config.CMS_IDS.TOPIC_DESCRIPTION);
        let data_description = ResultHelper.getValueFormatted(merkmal);

        //create SearchCards
        let cards = [];
        let card_title, card_body, card_img, card_img_id, card_action_token;
        let key = 0;

        for (const child of this.props.card_data.children) {

            merkmal = ResultHelper.searchMerkmalId(child.data, Config.CMS_IDS.CARD_HEADLINE);
            card_title = ResultHelper.getFirstValue(merkmal);

            merkmal = ResultHelper.searchMerkmalId(child.data, Config.CMS_IDS.CARD_BODY);
            card_body = ResultHelper.getFirstValue(merkmal);

            card_img_id = ResultHelper.searchMerkmalId(child.data, Config.CMS_IDS.CARD_IMG);
            if(card_img_id){
                let img_path = ResultHelper.getValueAtNum(card_img_id, 1);
                card_img = (img_path) ? Config.CMS_IMG_PATH + img_path : null;
            }
            else{
                card_img = null;
            }

            merkmal = ResultHelper.searchMerkmalId(child.data, Config.CMS_IDS.CARD_ACTION);
            card_action_token = ResultHelper.getFirstFieldData(merkmal);

            //push if we have anything
            if(card_title || card_body || card_img_id) {
                let card_config = {
                    cardtitle: card_title,
                    cardimg: card_img,
                    cardbody: card_body
                };

                //create card action
                //if there are children -> set link to next /thema/
                if (child.children && child.children.length) {
                    card_config.cardhandel_link_to = '/thema/' + child.slug;
                }
                //no children -> is there a card action?
                else if(card_action_token){
                    switch (card_action_token){
                        case '1'://'Seiteninhalt':
                            card_config.cardhandel_link_to = '/inhalt/' + child.slug;
                            break;
                        case '2'://'Direktsuche':
                            //try to get filter
                            let filter_merkmal = ResultHelper.searchMerkmalId(child.data, Config.CMS_IDS.CARD_FILTERS);
                            let card_filters = ResultHelper.getFirstValue(filter_merkmal);
                            if(card_filters) {
                                //filters found -> try parsing
                                let filter_json;
                                try{
                                    filter_json = JSON.parse(card_filters);
                                }
                                catch (e) {
                                    card_config.message = 'Filter JSON fehlerhaft.';
                                    break;
                                }

                                //set filters
                                card_config.cardhandel_filters = filter_json;
                            }
                            else{
                                card_config.message = "Direktsuche -> keine Filter gefunden.";
                            }
                            break;
                        default:
                            card_config.message = "Karte noch im Aufbau.";
                            break;
                    }

                }
                //message
                else{
                    card_config.message = "Karte noch im Aufbau.";
                }

                cards.push(<SearchCard config={card_config} key={key++}></SearchCard>);

                // console.log('---------------------------');
                // console.log('new card: ' + card_config.cardtitle, card_config);
                // console.log("card data: ", child);
            }


        }

        //set header img
        merkmal = ResultHelper.searchMerkmalId(json, Config.CMS_IDS.HEADER_IMG);
        let header_img_url = Config.CMS_IMG_PATH + ResultHelper.getValueAtNum(merkmal, 1);
        let header_img_name = ResultHelper.getFirstValue(merkmal);


        await this.context.setHeaderImg(header_img_url, header_img_name);

        this.setState({
            title: data_title,
            description: data_description,
            search_cards: cards
        });
    }

    render() {
        let elemMsg = "";

        if (Config.TextMainStartMsg !== null && Config.TextMainStartMsg !== window.undefined) {
            elemMsg = Config.TextMainStartMsg;
        }

        let parent_link;
        if(this.props.card_data && this.props.card_data.parent){
            let link_slug;
            //is the parent == HOME?
            if(this.props.card_data.parent.ID === Config.CMS_IDS.CARDS)
            {
                link_slug = "/"
            }
            else{
                link_slug = "/thema/" + this.props.card_data.parent.slug;
            }

            parent_link = <Link to={link_slug} className={"link-back"}>&lt;&lt; zurück</Link>;
        }

        return (
            <div className='search-cards-wrapper'>
                {parent_link}
                <header>
                    <h1 className="title">{this.state.title}</h1>
                    <h2 className="desc">{this.state.description}</h2>
                </header>

                <section className="cMainStartMsg">
                    {elemMsg}
                </section>

                <div className="search-cards-row">
                    {this.state.search_cards}
                </div>
            </div>
        );
    }
}
SearchCardsHolder.contextType = AppContext;
export default SearchCardsHolder;
