import React, {Component} from 'react';
import {scroller} from 'react-scroll';

import {AppContext} from '../../common/app/AppContext';
import MessageSharePartial from '../../common/components/MessageSharePartial';
import ResultHelper from "../../common/results/ResultHelper";
import Config from "../../Config";
import {Link} from "react-router-dom";
import CmsCardOffer from "../../common/components/CMSCardOffer";
import ApiConnector from "../../common/model/ApiConnectorST";


/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewCMSContent (name)
 */
class ViewCMSContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: null,
            offers: null,
            initialised: false
        }
    }

    componentDidMount(prevProps, prevState) {

        this.initialise();

        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    componentDidUpdate() {
        this.initialise();
    }

    async initialise(){
        if(this.context.filters_loaded && !this.state.initialised){

            this.setState({
                initialised: true
            })

            //this.props.match is coming from the Router
            let slug = this.props.match.params.slug;

            //get data for content
            let cms_data_node = await this.context.getCMSCardBySlug(slug);
            let provider_id = cms_data_node.ID;

            //get offers
            let cms_offers = await this.context.getOffersForProvider(provider_id);

            //sort by longname
            let compareFunc = (a,b) => {
                if(a.LongName < b.LongName ){
                    return -1;
                }
                if(a.LongName > b.LongName ){
                    return 1;
                }
                return 0;
            }
            cms_offers.sort(compareFunc);

            //get details
            let offer_details = [];
            for (const cms_offer of cms_offers) {
                let detail_json = await ApiConnector.searchDatasetID(cms_offer.DatasetId);
                let detail_data = detail_json.data.Body;
                offer_details.push(detail_data[0]);
            }

            this.setState({
                content: cms_data_node,
                offers: offer_details
            });
        }

        //todo: set parent header

    }

    searchAllOffers(filters) {
        this.context.setCardFiltersAndSearchApiOffers(filters);
    }

    renderOffers()
    {
        let offers_out = [];

        if(this.state.offers)
        {
            console.log('Offers', this.state.offers);
            let merkmal, headline, subheadline, offer_text_tag, offer_links, all_results;
            let offer_links_json, all_results_json;
            let counter = 0;

            for (const offer_data of this.state.offers) {
                console.log('------ OFFER --------');
                console.log(offer_data);

                merkmal = ResultHelper.searchMerkmalId(offer_data, Config.CMS_IDS.OFFER_HEADLINE);
                headline = ResultHelper.getFirstValue(merkmal);

                merkmal = ResultHelper.searchMerkmalId(offer_data, Config.CMS_IDS.OFFER_SUBHEADLINE);
                subheadline = ResultHelper.getFirstValue(merkmal);

                merkmal = ResultHelper.searchMerkmalId(offer_data, Config.CMS_IDS.OFFER_TEXT);
                offer_text_tag = ResultHelper.getValueFormatted(merkmal);

                merkmal = ResultHelper.searchMerkmalId(offer_data, Config.CMS_IDS.OFFER_LINKS);
                offer_links = ResultHelper.getFirstValue(merkmal);

                merkmal = ResultHelper.searchMerkmalId(offer_data, Config.CMS_IDS.OFFER_ALL_RESULTS);
                all_results = ResultHelper.getFirstValue(merkmal);

                //convert links
                if(offer_links)
                {
                    try {
                        offer_links_json = JSON.parse(offer_links);
                    }
                    catch (e) {
                        console.error(e);
                        offer_links_json = "Links: json parse error";
                    }
                }
                else{
                    offer_links_json = "";
                }

                //convert all_results
                if(all_results)
                {
                    try {
                        all_results_json = JSON.parse(all_results);
                    }
                    catch (e) {
                        console.error(e);
                        all_results_json = "Alle Ergebnisse: json parse error";
                    }
                }
                else{
                    all_results_json = "";
                }

                offers_out.push(<CmsCardOffer
                                    headline={headline}
                                    subheadline={subheadline}
                                    p_offer_text_tag={offer_text_tag}
                                    offer_links_json={offer_links_json}
                                    all_results_json={all_results_json}
                                    key={counter++}
                                />);
            }
        }

        let structure_out = <div className="offers-wrapper">{offers_out}</div>;

        return structure_out;
    }

    render() {
        let out, merkmal, headline, subheadline, content_img, content_img_path, descriptionTag,
            all_results, all_results_json, all_results_link;

        //get page data
        if (this.state.content) {
            //main headline
            merkmal = ResultHelper.searchMerkmalId(this.state.content.parent.data, Config.CMS_IDS.TOPIC_HEADLINE);
            headline = ResultHelper.getFirstValue(merkmal);

            //content img
            merkmal = ResultHelper.searchMerkmalId(this.state.content.data, Config.CMS_IDS.CONTENT_IMAGE);
            if(merkmal){
                let cms_img_path = ResultHelper.getValueAtNum(merkmal, 1);
                content_img_path = (cms_img_path) ? Config.CMS_IMG_PATH + cms_img_path : null;
                content_img = <img className="img-fluid" src={content_img_path} alt={headline}/>;
            }

            //subheadline
            merkmal = ResultHelper.searchMerkmalId(this.state.content.data, Config.CMS_IDS.TOPIC_HEADLINE);
            subheadline = ResultHelper.getFirstValue(merkmal);

            //description
            merkmal = ResultHelper.searchMerkmalId(this.state.content.data, Config.CMS_IDS.CONTENT_DESCRIPTION);
            descriptionTag = ResultHelper.getValueFormatted(merkmal, 'description');

            //all results
            merkmal = ResultHelper.searchMerkmalId(this.state.content.data, Config.CMS_IDS.OFFER_ALL_RESULTS);
            all_results = ResultHelper.getFirstValue(merkmal);

            //convert all_results
            if(all_results)
            {
                try {
                    all_results_json = JSON.parse(all_results);
                }
                catch (e) {
                    console.error(e);
                    all_results_json = "Alle Ergebnisse: json parse error";
                }
            }
            else{
                all_results_json = "";
            }

            if(typeof all_results_json === "string")
            {
                all_results_link = all_results_json;
            }
            else if(all_results_json)
            {
                all_results_link = <span className='all-results-link' onClick={() => this.searchAllOffers(all_results_json)}>
                &gt;&gt; Alle Ergebnisse
            </span>;
            }

            //parent_link
            let parent_link, link_slug;
            //is the parent == HOME?
            if(this.state.content.parent.ID === Config.CMS_IDS.CARDS)
            {
                link_slug = "/"
            }
            else{
                link_slug = "/thema/" + this.state.content.parent.slug;
            }
            parent_link = <Link to={link_slug} className="link-back">&lt;&lt; zurück</Link>;

            //create offers
            let offers = this.renderOffers();

            //create page
            out = (
                <div className="view-cmscontent" name="scroll_start">
                    {parent_link}
                    <header>
                        <h1 className="title">{headline}</h1>
                        {content_img}
                        <h2 className="subheadline">{subheadline}</h2>
                        {descriptionTag}
                    </header>

                    <p className="all-results">{all_results_link}</p>

                    <section className={"offers"}>
                        {offers}
                    </section>
                </div>
            );
        } else {
            //if cms_card_data is not ready >> show Message
            out = <MessageSharePartial children={"Daten werden geladen."} />
        }

        return out;
    }
}

ViewCMSContent.contextType = AppContext;
export default ViewCMSContent;
