
import cookies from 'react-cookies';
import Config from '../../Config';

export default class CookieUtil{

  static g_cookieAccepted = "cookie_accepted";
  static g_cookieGoogleMap = "cookie_gmap";
  static g_cookieMotomo = "cookie_motomo";

  static g_cookieFavoriteDataset = "cookie_favorite_dataset";

  /**
  * @pCookieKey: key für einen Bestimmten Cookie Wert
  * @pAkzept: true-> setzen, fals-> löschen
  */
  static saveCookieBanner(pCookieKey, pAkzept){
    // let allCookies = cookies.load(pCookieKey, true);
    // if(allCookies.hasOwnProperty(pCookieKey)){
    //   cookies.remove(pCookieKey, { path: '/' });
    // }

    let expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    cookies.save(pCookieKey, pAkzept, {path: '/', expires, secure: true});
  }

  static removeCookie(pCookieKey){
    cookies.remove(pCookieKey, { path: '/' });
  }

  static getCookie(pCookieKey){
    let cookieValue = cookies.load(pCookieKey, true);
    if(cookieValue === 'undefined' || cookieValue === window.undefined)
      return null;
    switch (pCookieKey) {
      case this.g_cookieAccepted:
      case this.g_cookieGoogleMap:
      case this.g_cookieMotomo:
        return cookieValue.toLowerCase() === 'true';//NOT Boolean('false'); -> ergibt true!
      case this.g_cookieFavoriteDataset:
        let valueArray = JSON.parse(cookieValue);
        return Object.keys(valueArray).length>0 ? valueArray : null;
      default:
        return cookieValue;
    }
  }

  static getCookiesCurrent(){
    //cookies.remove(CookieUtil.g_cookieAccepted, { path: '/' });
		let cookieValue = this.getCookie(CookieUtil.g_cookieAccepted);

		let cookieValueGmap = true;
		let cookieValueMotomo = true;

		let defaultValues = Config.COOKIE_DEFAULT_VALUE;
		if(cookieValue === null || cookieValue === window.undefined){
			cookieValue = false;
			//Cookies sind noch nicht gesetzt, nehme default Einstellungen und zeige den Banner an:
			cookieValueGmap = defaultValues[CookieUtil.g_cookieGoogleMap];
			cookieValueMotomo = defaultValues[CookieUtil.g_cookieMotomo];
		}
		else{
			cookieValue = true;

			let valueGmap = this.getCookie(CookieUtil.g_cookieGoogleMap);
			cookieValueGmap = (valueGmap === window.undefined ? defaultValues[CookieUtil.g_cookieGoogleMap] : valueGmap);

			let valueMotomo = this.getCookie(CookieUtil.g_cookieMotomo);
			cookieValueMotomo = (valueMotomo === window.undefined ? defaultValues[CookieUtil.g_cookieMotomo] : valueMotomo);
		}

    return {
      [CookieUtil.g_cookieAccepted]: cookieValue,
      [CookieUtil.g_cookieGoogleMap]: cookieValueGmap,
      [CookieUtil.g_cookieMotomo]: cookieValueMotomo
    };
  }





  static prSaveCookieFavoriteDataset(pFavoritesAllObj){
    let favoriteEntryNewStr = JSON.stringify(pFavoritesAllObj);

    let expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
    cookies.save(this.g_cookieFavoriteDataset, favoriteEntryNewStr, {path: '/', expires, secure: true});

    //console.log("saveCookie checkSaved:", this.getCookie(this.g_cookieFavoriteDataset));
  }
  static prRemoveCookieFavoriteDataset(pCookieFavoritesObj, pDatasetId){
    if(pCookieFavoritesObj !== null && pCookieFavoritesObj.hasOwnProperty(pDatasetId)){
      delete pCookieFavoritesObj[pDatasetId];
      this.prSaveCookieFavoriteDataset(pCookieFavoritesObj);
    }
  }

  static getCookieFavoritesDatasetsCurrent(){
    return this.getCookie(this.g_cookieFavoriteDataset);
  }

  static isCookieFavoriteDatasetSetCur(pDatasetId){
    let pCookiesFavoritesObj = this.getCookieFavoritesDatasetsCurrent();
    return this.prGetFavoriteEntrySet(pCookiesFavoritesObj, pDatasetId);
  }
  static isCookieFavoriteDatasetSet(pCookiesFavoritesObj, pDatasetId){
    return this.prGetFavoriteEntrySet(pCookiesFavoritesObj, pDatasetId);
  }
  static toogleCookieFavoriteDatasetValue(pDatasetId, pFavoriteSet){
    let cookieFavoritesObj = this.getCookie(this.g_cookieFavoriteDataset);
    pFavoriteSet
      ? this.prWriteCookieFavoriteDataset(cookieFavoritesObj, pDatasetId, pFavoriteSet)
      : this.prRemoveCookieFavoriteDataset(cookieFavoritesObj, pDatasetId)
  }
  static prCreateFavoriteEntry(pDatasetId, pValue){
    //Beispiel
    //[
    //"343cab3f-d66c-46b7-ac94-451b89a89d85":{'js_favorite':true, 'js_bezeichnung': "TitleText"}
    //"343cab3f-d66c-46b7-ac94-278375437865":{'js_favorite':true, 'js_bezeichnung': "TitleText2"}
    //]

    // var dict = {};
    // dict['key'] = "testing";
    // console.log(dict);Object {key: "testing"}
    let dictCookieValue = {};
    dictCookieValue[pDatasetId]={"js_favorite": pValue};
    return dictCookieValue;
  }
  static prGetFavoriteEntrySet(pCookiesFavoritesObj, pDatasetId){
    if(pCookiesFavoritesObj !== null && pCookiesFavoritesObj.hasOwnProperty(pDatasetId)){
      let value = pCookiesFavoritesObj[pDatasetId];
      return value["js_favorite"];
    }
    return false;
  }
  static prWriteCookieFavoriteDataset(pCookieFavoritesObj, pDatasetId, pValue){
    let favoriteEntryNew = this.prCreateFavoriteEntry(pDatasetId, pValue);
    if(pCookieFavoritesObj !== null ){
      if(pCookieFavoritesObj.hasOwnProperty(pDatasetId)){
        delete pCookieFavoritesObj[pDatasetId];
      }
    }else{
      pCookieFavoritesObj = new Array();
    }
    favoriteEntryNew = Object.assign({}, pCookieFavoritesObj, favoriteEntryNew);
    this.prSaveCookieFavoriteDataset(favoriteEntryNew);
  }


  static BeispielJasonCreate(){
    var sitePersonel = {};
    var employees = []
    sitePersonel.employees = employees;
    console.log(sitePersonel);

    var firstName = "John";
    var lastName = "Smith";
    var employee = {
      "firstName": firstName,
      "lastName": lastName
    }
    sitePersonel.employees.push(employee);

    var manager = "Jane Doe";
    sitePersonel.employees[0].manager = manager;
    console.log(sitePersonel);
    console.log(JSON.stringify(sitePersonel));
  }
};
