import React from 'react';
import AppBase from "../../common/app/AppBase";
import Tracker from "../../common/model/Tracker";
import Config from "../../Config";
import {AppContext} from "../../common/app/AppContext";
import {Switch} from "react-router-dom";
import RouteWrapper from "../../common/structure/RouteWrapper";
import CookieUtil from '../../common/tools/CookieUtil';
import ViewHome from "./ViewHome";
import ViewResults from "./ViewResults";
import ViewDetail from "./ViewDetail";
import ViewPreviewSTU from './ViewPreviewSTU';
import ViewProvider from "./ViewProvider";
import ViewThema from "./ViewThema";
import LayoutDefault from "./LayoutDefault";


import ViewStaticAnsprechparterInnen from "./ViewStaticAnsprechparterInnen";
import ViewStaticChecklisten from "./ViewStaticChecklisten";
import ViewStaticNotfallnummern from "./ViewStaticNotfallnummern";
import ViewStaticUeberDieseSeite from "./ViewStaticUeberDieseSeite";
import ViewStaticWasSindFrueheHilfen from "./ViewStaticWasSindFrueheHilfen";
import View404 from "./View404";

import ViewStaticNews from "./ViewStaticNews";
import ViewCMSContent from "./ViewCMSContent";
import ViewKontaktDefault from "../../common/viewparts/ViewKontaktDefault";
import ViewStaticDatenschutz from "./ViewStaticDatenschutz";
import ViewStaticImpressum from "./ViewStaticImpressum";
//import ViewStaticNutzungsbedingungen from "./ViewStaticNutzungsbedingungen";
import ViewStaticWeitereLinks from "./ViewStaticWeitereLinks";

export default class App extends AppBase {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        super.componentDidMount();
    }

    render(){

        let componentTracker = "";
        if(this.state.s_cookies[CookieUtil.g_cookieMotomo]){
          componentTracker = (<Tracker active={Config.TRACKER.active} />);
        }

        return (
            <div id="app-wrapper">
                {/*<BusyAnimationSharePartial loading={this.state.loader_active}></BusyAnimationSharePartial>*/}
        				{componentTracker}
                <AppContext.Provider value={this.state}>
                    {/*redirect_to_home */}
                    {this.state.redirect}

                    <Switch>
                        <RouteWrapper path="/" exact component={ViewHome} cssClass={"wrapper-home"} layout={LayoutDefault} />
                        <RouteWrapper path="/detail/:id" component={ViewDetail} cssClass={"wrapper-detail"} layout={LayoutDefault} />
                        <RouteWrapper path="/detailorganisation/:id" component={ViewProvider} cssClass={"wrapper-organisation"} layout={LayoutDefault} />
                        <RouteWrapper path="/thema/:topic" component={ViewThema} cssClass={"wrapper-thema"} layout={LayoutDefault} />
                        <RouteWrapper path="/inhalt/:slug" component={ViewCMSContent} cssClass={"wrapper-cmscontent"} layout={LayoutDefault} />
                        <RouteWrapper path="/ergebnisse/" exact component={ViewResults} cssClass={"wrapper-results"} layout={LayoutDefault} />
                        <RouteWrapper path="/ergebnisse/:page_num" component={ViewResults} cssClass={"wrapper-results"} layout={LayoutDefault} />

                        <RouteWrapper path="/news" component={ViewStaticNews} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/preview/" component={ViewPreviewSTU} layout={LayoutDefault} layoutRouterProps={{p_addwatermark:true}}/>
                        {/*<Route path="/ueber_uns/" component={ViewUeberUns} />*/}
                        <RouteWrapper path="/kontakt/" component={ViewKontaktDefault} layout={LayoutDefault} layoutRouterProps={{p_kontaktform_btnReset:false}}/>

                        {/*Statische Seiten: */}
                        <RouteWrapper path="/impressum/" component={ViewStaticImpressum} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/datenschutz/" component={ViewStaticDatenschutz} cssClass={"wrapper-static"} layout={LayoutDefault}/>
                        <RouteWrapper path="/ansprechpartner-innen/" component={ViewStaticAnsprechparterInnen} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/checklisten/" component={ViewStaticChecklisten} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/notfallnummern/" component={ViewStaticNotfallnummern} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/weitere-links/" component={ViewStaticWeitereLinks} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/ueber_diese_seite/" component={ViewStaticUeberDieseSeite} cssClass={"wrapper-static"} layout={LayoutDefault} />
                        <RouteWrapper path="/was-sind-fruehe-hilfen/" component={ViewStaticWasSindFrueheHilfen} cssClass={"wrapper-static"} layout={LayoutDefault} />

                        <RouteWrapper component={View404} cssClass={"wrapper-404"} layout={LayoutDefault} />
                    </Switch>
                </AppContext.Provider>
            </div>
        );
    }
}
