import React, {Component} from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import iconDownload from './media/icons/download-200.png';
import imgWissenswert from "../goettingen_kita_t/media/static_pages/wissenswertes_g.jpg";

/**
 * Shows a ViewStaticNotfallnummern Page
 *
 * @class      ViewStaticNotfallnummern (name)
 */
export default class ViewStaticNotfallnummern extends Component {

    render() {
        return (
            <div className="view-static">
                <h1 className={"title"}>Für den Notfall</h1>
                    <p>&nbsp;</p>
                    <table>
                        <tbody>
                        <tr>
                            <td><strong>Polizei</strong></td>
                            <td><strong>110</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Feuerwehr</strong></td>
                            <td><strong>112</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Notarzt, Rettungsdienst</strong></td>
                            <td><strong>112</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Notfall- und Bereitschaftsdienst</strong></td>
                            <td><strong>116117</strong></td>
                        </tr>
                        <tr>
                            <td>
                                <p><strong>Giftnotruf</strong></p>
                                <p>(Vergiftungs-Informations-Zentrale Freiburg)</p>
                            </td>
                            <td><strong>0761 19240</strong></td>
                        </tr>
                        <tr>
                            <td>Notaufnahme Olgahospital</td>
                            <td>0711 278-73011</td>
                        </tr>
                        <tr>
                            <td>Kinderärztlicher Notfalldienst Olgahospital</td>
                            <td>
                                <p>0180 6071-112</p>
                                <p>(Montag bis Freitag 19 bis 22 Uhr;</p>
                                <p>Samstag, Sonntag und feiertags 9 bis 22 Uhr)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Augenärztlicher Notfalldienst</td>
                            <td>116117</td>
                        </tr>
                        <tr>
                            <td>Psychiatrischer Notfalldienst</td>
                            <td>116117</td>
                        </tr>
                        <tr>
                            <td>Apotheken-Notdienstfinder</td>
                            <td>
                                <p>0800 0022833</p>
                                <p>oder <a href="http://www.lak-bw.de/notdienstportal">www.lak-bw.de/notdienstportal</a>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <td>Zahnärztlicher Notdienst im Klinikum Stuttgart</td>
                            <td>
                                <p>0711 278-33368</p>
                                <p>(Montag bis Sonntag 18 bis 8 Uhr;</p>
                                <p>Samstag, Sonntag und feiertags 8 bis 18 Uhr)</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Zahnarzt Notdienst der Kassenzahnärztlichen Vereinigung
                                Baden-Württemberg
                            </td>
                            <td>0711 7877711</td>
                        </tr>
                        <tr>
                            <td>Frauenhaus Stuttgart</td>
                            <td>0711 4142430</td>
                        </tr>
                        <tr>
                            <td>Autonomes Frauenhaus</td>
                            <td>0711 542021</td>
                        </tr>
                        <tr>
                            <td>Hilfetelefon Gewalt gegen Frauen</td>
                            <td>08000 116016</td>
                        </tr>
                        <tr>
                            <td>Weißer-Ring e.V. Opfer-Notruftelefon</td>
                            <td>116006</td>
                        </tr>
                        <tr>
                            <td>Krisennotfalldienst</td>
                            <td>01805 110444</td>
                        </tr>
                        <tr>
                            <td>Telefonseelsorge evangelisch</td>
                            <td>0800 1110111</td>
                        </tr>
                        <tr>
                            <td>Telefonseelsorge katholisch</td>
                            <td>0800 1110222</td>
                        </tr>
                        <tr>
                            <td>Nummer gegen Kummer – Elterntelefon</td>
                            <td>0800 110550</td>
                        </tr>
                        </tbody>
                    </table>

                <br/><br/>

                <h1 className={"title"}>Medizinische Notdienste</h1>

                <hr/>

                <h2>Ärztlicher Bereitschaftsdienst<br/>Bereitschaftsdienst der kassenärztlichen Vereinigungen</h2>
                <p>
                    Montag bis Sonntag und feiertags 0 bis 24 Uhr
                    <br/>Telefon 116117 (bundesweit)
                    <br/>Fax 0800 5895210 (für hör- und sprachbehinderte Menschen)
                    <br/>Bitte wenden Sie sich an den Bereitschaftsdienst, wenn Ihre Hausärztin/Ihr Hausarzt nicht
                    erreichbar ist.
                    <br/>
                    Der Bereitschaftsdienst hilft weiter bei nicht lebensgefährlichen Situationen.
                </p>

                <hr/>

                <h2>Kostenfreie Online-Sprechstunde</h2>
                <p>
                    Montag bis Freitag 9 bis 19 Uhr: docdirekt – Kostenfreie Online-Sprechstunde von Haus-, Kinder- und Jugendärzten,
                    <br />nur für gesetzlich Versicherte unter Telefon 116117 oder unter <a href={"http://www.docdirekt.de"} target={"_blank"}>www.docdirekt.de</a>
                </p>

                <hr/>

                <h2>Notfallpraxis der Stuttgarter Ärzteschaft im Marienhospital Stuttgart<br />Allgemeinmediziner/Internisten, Orthopäden/Chirurgen</h2>
                <p>Montag bis Donnerstag 19 bis 1 Uhr; Freitag 14 bis 1 Uhr; Samstag, Sonntag und feiertags 7 bis 1 Uhr
                    <h3>HNO-Ärzte</h3>
                    Samstag, Sonntag und feiertags 9 bis 18 Uhr
                    <br /><a href="http://www.notfallpraxis-stuttgart.de" target="_blank">www.notfallpraxis-stuttgart.de</a>
                    <br />Bitte kommen Sie ohne vorherige Anmeldung direkt in die medizinische Notfallpraxis im Marienhospital.
                </p>

                <hr/>

                <h2>Notfallambulanzen<br />Klinikum Stuttgart, Katharinenhospital</h2>
                <p>Montag bis Sonntag und feiertags 0 bis 24 Uhr
                    <br />Telefon 0711 278-30303<br />Fax 0711 278-30309
                    <br /><a href={"http://www.klinikum-stuttgart.de/ihr-aufenthalt/notfall"} target="_blank">www.klinikum-stuttgart.de/ihr-aufenthalt/notfall</a>
                    <br />Die Notaufnahme befindet sich im Katharinenhof (Gebäude E).
                </p>
                <h2>Klinikum Stuttgart, Krankenhaus Bad Cannstatt</h2>
                <p>Montag bis Sonntag und feiertags 0 bis 24 Uhr
                    <br />Telefon 0711 278-62700<br /><a href={"http://www.klinikum-stuttgart.de/ihr-aufenthalt/notfall"} target={"_blank"}>www.klinikum-stuttgart.de/ihr-aufenthalt/notfall</a>
                </p>

                <hr/>

                <h2>Zahnärztlicher Notdienst
                    <br />Behandlung von Notfällen und zahnärztlicher Notdienst im Klinikum Stuttgart, Katharinenhospital (Haus K, EG)</h2>
                <p>Montag bis Sonntag 18 bis 8 Uhr; Samstag, Sonntag und feiertags 8 bis 18 Uhr
                    <br />Telefon 0711 278-33368 (alle Kassen)<br /><a href={"http://www.klinikum-stuttgart.de/zahnarzt-notdienst"} target={"_blank"}>www.klinikum-stuttgart.de/zahnarzt-notdienst</a>
                </p>
                <h2>Zahnarzt Notdienst der Kassenzahnärztlichen Vereinigung Baden-Württemberg</h2>
                <p> Telefon 0711 7877711</p>

                <hr/>

                <h2>Augenärztlicher Notdienst<br />Niedergelassene Augenärzte und Klinikum Stuttgart, Katharinenhospital</h2>
                <p>Freitag 16 bis 22 Uhr; Samstag, Sonntag und feiertags 9 bis 22 Uhr
                    <br />Wochentags 8 bis 18 Uhr findet der Notdienst in der Praxis der niedergelassenen Augenärztinnnen/Augenärzte vor Ort statt. Mehr Informationen erhalten Sie unter Telefon 116117.
                </p>
                <p>Die Kassenärztliche Vereinigung Baden-Württemberg betreibt eine augenärztliche Notfallpraxis in der Ambulanz des Katharinenhospitals (Untergeschoss Haus K). Niedergelassene Augenärztinnen und Augenärzte versehen dort zu diesen Zeiten den Notdienst. Zu allen anderen Zeiten werden Sie in der Augenklinik des Katharinenhospitals versorgt.
                </p>
                <h2>Klinikum Stuttgart, Katharinenhospital</h2>
                <p>Montag bis Donnerstag 18 bis 20.30 Uhr in der Ambulanz, Untergeschoss Haus K
                    <br />Telefon 0711 278-33100<br /><a href={"http://www.klinikum-stuttgart.de/ihr-aufenthalt/notfall"} target={"_blank"}>www.klinikum-stuttgart.de/ihr-aufenthalt/notfall</a>
                </p>

                <hr/>

                <h2>HNO Notdienst<br />Klinikum Stuttgart, Katharinenhospital</h2>
                <p>Regelbetrieb: Montag bis Freitag 07.30 bis 16 Uhr
                    <br />Notfallbetrieb: Montag bis Freitag 16 bis 07.30 Uhr; Samstag, Sonntag und feiertags 0 bis 24 Uhr
                    <br />Telefon 0711 278-33297 (Montag bis Freitag 8 bis 16 Uhr)<br />Telefon 0711 278-01 (nach 16 Uhr)
                    <br /><a href={"http://www.klinikum-stuttgart.de/ihr-aufenthalt/notfall"} target={"_blank"}>www.klinikum-stuttgart.de/ihr-aufenthalt/notfall</a>
                    <br />Für Notfallpatientinnen und Notfallpatienten, die vor Ort sind, gibt es im Eingangsbereich Kriegsbergstraße und vor der
                    <br />Notfallbehandlungseinheit 4 eine Klingel, die direkt den Notfalldienst benachrichtigt.
                </p>

                <hr/>

                <h2>Kinderärztlicher Notdienst der niedergelassenen Kinder- und Jugendärzte Stuttgarts<br />(Kassenärztliche Vereinigung am Olgahospital)</h2>
                <p>Montag bis Freitag 19 bis 22 Uhr; Samstag, Sonntag und feiertags 9 bis 22 Uhr
                    <br />Telefon 0180 6071-112
                    <br />Zu den übrigen Zeiten nachts und am Wochenende ist die Notaufnahme des Olgahospitals zuständig.
                </p>
                <h2>Klinikum Stuttgart, Olgahospital</h2>
                <p>Telefon 0711 278-73011<br /><a href={"http://www.klinikum-stuttgart.de/ihr-aufenthalt/notfall"} target={"_blank"}>www.klinikum-stuttgart.de/ihr-aufenthalt/notfall</a> </p>

                <hr/>

                <h2>Stroke Unit<br />Klinikum Stuttgart, Katharinenhospital</h2>
                <p>Telefon 0711 278-170<br />Diese Einheit ist speziell für Schlaganfallpatientinnen und Schlaganfallpatienten zuständig.
                </p>

            </div>
        );
    }
}