import React from "react";

/**
 * Internal representation of the MailContact
 *
 * @class      FilterSendKontaktForm (name)
 */
export default class FilterSendKontaktForm {

    static TYPE = "TypeMailContact";

    constructor(pMailContantJson) {

        this.type = FilterSendKontaktForm.TYPE;
        this.m_mailContant = pMailContantJson;
    }

    buildForAPI(){
        return {
            "Type": "ContactForm",
            "MerkmalTypeIDs": [],
            "Fields": [],
            "Values": [],
            "ValuesObjects": [this.m_mailContant],
            "OperatorType": ""
        };
    }

}
