import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {scroller} from 'react-scroll';

import ConfigCards from '../../ConfigCards';

import {AppContext} from '../../common/app/AppContext';
import CreateSearchCardsWrapper from '../../common/components/CreateSearchCardsWrapper';

import MessageSharePartial from '../../common/components/MessageSharePartial';
import SearchCardsHolder from "../../common/components/SearchCardsHolder";


/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewThema (name)
 */
class ViewThema extends Component {

    constructor(props) {
        super(props);

        this.state = {
            content: null,
            initialised: false
        }
    }

    componentDidMount(prevProps, prevState) {
        // let topic = this.props.match.params.topic;
        // let page_data = ConfigCards.CARDS_LEVEL_ONE[topic];

        // if(page_data)
        //     this.context.setHeaderImg(page_data.cardheaderimg);

        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });

        this.initialise();
    }

    componentDidUpdate() {
        this.initialise();
    }

    initialise(){
        if(!this.state.initialised && this.context.filters_loaded){

            //this.props.match is coming from the Router
            let slug = this.props.match.params.topic;

            this.context.getCMSCardBySlug(slug).then((cms_data_node) => {
                //todo: check if cms_data_node is null -> throw 404
                this.setState({
                    content: cms_data_node
                });
            });

            this.setState({
                initialised: true
            });
        }
    }

    render() {
        let out;

        //get page data
        if (this.state.content) {

            //create page
            out = (
                <div className="view-thema" name="scroll_start">
                    <SearchCardsHolder card_data={this.state.content} />
                </div>
            );
        } else {
            // //redirect to 404
            // out = <Redirect to="/404"/>;
            out = <MessageSharePartial children={"Daten werden geladen."} />
        }

        return out;
    }
}

ViewThema.contextType = AppContext;
export default ViewThema;
