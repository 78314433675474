import React from 'react';
import Config from '../../Config';
import ScssVariables from '../../ConfigScss.scss';
import FilterDate from '../model/FilterDate';
import SvgIcons from '../../common/components/SvgIcons.js';
import {SvgIconsConstants} from '../../common/components/SvgIconsConstants.js';
/**
 * Has only static classes, that allow the creation of ResultSet components.
 * Also filters the API resuls for the MapResults
 *
 * @class      ResultSetComponents (name)
 */
export default class ResultSetComponents{


    /**
    * @pDateDatasetPublish  z.B: "30.05.2020"
    */
    static getElementNewDatasetIcon(pDateDatasetPublish){
      let elemNewDatasetIcon = '';
      if(pDateDatasetPublish && pDateDatasetPublish !== null)
      {
        let dateForOfferNew = this.getDateDifference(new Date(), Config.DATASET_PUBLISHED_DAYS_AGO);
        let datePublished = FilterDate.parseDate(pDateDatasetPublish);
        //datePublished = date_format(datePublished, "dd.mm.yyyy");
        //<img className="c_svg_datasetnew_size" src={SvgIconsConstants.g_IconResultSetNeu} alt="neu"></img>
        let cFloat = "c_svg_datasetnew_right";
        let iconName = SvgIconsConstants.g_IconResultSetNeu;
        if(Config.favoritesOn){
          cFloat = "c_svg_datasetnew_left";
          iconName = SvgIconsConstants.g_IconResultSetNeu_left;
        }
        if(datePublished.getTime() > dateForOfferNew.getTime())
        {
          elemNewDatasetIcon = (<SvgIcons className={"c_svg_datasetnew_size "+cFloat}
                                    p_IconKey={iconName}
                                    p_Tooltip="Neu"
                                    width="40"
                                    fill={ScssVariables.iconSvgNeu_color} />);
        }
      }else{
        console.error("pDateDatasetPublish is leer");
      }
      return elemNewDatasetIcon;
    }
    
    /**
    * pDateStart: z.B. 'new Date()'
    * pDays: z.B. '-28' (Tage)
    */
    static getDateDifference (pDateStart, pDays){
    	let dateForOfferNew = pDateStart;
      dateForOfferNew.setDate(dateForOfferNew.getDate() + pDays);
    	// let date_format = require('dateformat');
    	// let dateFromNew = date_format(dateForOfferNew, "dd.mm.yyyy");
    	return dateForOfferNew;
    }

}
