import React from "react";

export default class FilterListDatasets {

    static TYPE = "TypeDatasetsListe";
    /**
    * pDatasetIdsArray: []
    */
    constructor(pDatasetIdsArray) {
        this.type = FilterListDatasets.TYPE;
        this.m_datasetIds = pDatasetIdsArray;
    }

    buildForAPI(){
        if(this.m_datasetIds)
        {
            return {
                "Type": "DatasetList",
                "MerkmalTypeIDs": "",
                "Fields": "",
                "Values": this.m_datasetIds,
                "OperatorType": ""
            };
        }

        return false;
    }

    isSet(){
        return this.m_datasetIds.length > 0;
    }


    toJSX(key = 0){
        return (<span key={key}>Favoriten</span>);
    }
}
