import React, { Component } from 'react';

import {AppContext} from '../../common/app/AppContext';

import SearchResults from '../../common/components/SearchResults';
import MessageSharePartial from '../../common/components/MessageSharePartial';

/**
 * Wrapper/View component with MainTemplate for the SearchResults.
 *
 * @class      ViewResults (name)
 */
class ViewResults extends Component{

	render(){

		let result_view = null;
		if(this.context.search_results)
		{
			result_view = <SearchResults
								results={this.context.search_results}
								providers={this.context.search_results_provider_data}
							/>
		}
		else{
			result_view = <MessageSharePartial>Ergebnisse werden gesucht ...</MessageSharePartial>;
		}

		return(
			<div className="view-results">
				{result_view}
			</div>
		);
	}
}
ViewResults.contextType = AppContext;
export default ViewResults;
