import React from "react";

export default class FilterText {

	static TYPE = "TypeTextFilter";

	constructor(text = "") {
		this.type = FilterText.TYPE;
		this.text = text;
	}

	toString(){
		return this.text;
	}

	toJSX(key = 0, deactivateSelf){
		return (<span key={key} onClick={()=>{deactivateSelf()}}>{this.toString()}</span>);
	}

	isSet(){
		return Boolean(this.text);
	}

	buildForAPI() {
		if(this.text)
		{
			return {
				"Type": 'Text',
				"MerkmalTypeIDs": [],
				"Fields": [],
				"Values": [this.text],
				"OperatorType": ""
			};
		}

		return false;
	}
}

