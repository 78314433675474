import React, { Component } from 'react';
import Mailto from 'react-protected-mailto';

export default class MerkmalText extends Component {

	//https://stackoverflow.com/questions/37684/how-to-replace-plain-urls-with-links
	//https://javascript.info/regexp-introduction
	linkify(inputText) {
		if(inputText !== window.undefined && inputText !== null){
			  var replacedText, replacePattern1, replacePattern2, replacePattern3;

			// inputText = "irgend EIn text miesf234 [cms_link:'hier ist der erste Link'='https://www.stuttgart.de/einschulungsuntersuchungen'] casd sef4h";
			// inputText += "\n EIn text miesf234 [cms_link:'hier ist der zweite Link'='https://www.stuttgart.de'] casd  awt erg srth dh drxtfgnxrfthndf";

			// Finde folgende Konversation
			// [cms_link:'hier'='https://www.stuttgart.de/einschulungsuntersuchungen']
			let replacePattern0 = /(\[cms_link\:'[a-zA-z0-9\s]+'='\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]'\])/gim;
			var tmpMatch;
			do {
				//https://jsfiddle.net/7yS2V/
				tmpMatch = replacePattern0.exec(inputText);

				if (tmpMatch) {
					//tmpMatch ist ein Array mit mehreren Infos: console.log(tmpMatch);
					let linkParts = tmpMatch[0].split('=');

					//es sind Buchstaben + Zahlen + Leerzeichen erlaubt
					let regPlaceHolder = /('[a-zA-z0-9\s]+')/gim;
					let partPlaceHolder = linkParts[0]; //"[cms_link:'hier'"
					let textPlaceholder = partPlaceHolder.match(regPlaceHolder).toString();
					textPlaceholder =  textPlaceholder.replace(/'/g, '');//string.trim("'")

					let regUrl = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
					let partUrl = linkParts[1]; //"https://www.stuttgart.de/einschulungsuntersuchungen]"
					let textUrl = partUrl.match(regUrl).toString();
					textUrl =  textUrl.replace(/'/g, '');//string.trim("'")

					let hrefForReplace = `<a class="c_cms_link" href="${textUrl}" title="${textUrl}" target="_blank">${textPlaceholder}</a>`;
					inputText = inputText.replace(tmpMatch[0], hrefForReplace);
					//console.log("ersetztes Text: ", inputText);
				}
			} while (tmpMatch);


		    // URLs starting with http://, https://, or ftp://, aber ohne " am Ande, weil
			// !!! Achtung:
			// der Muster: [cms_link:'hier'=https://www.stuttgart.de]
			// umgewandelt in <a href="https://www.stuttgart.de" target="_blank">hier</a> wurde
			// und dieser ("https://www.stuttgart.de") darf nicht noch mal mit <a href="$1" target="_blank">$1</a> ersetzt werden
		    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]^\")/gim;
			replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');


		    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
			//and without " am Ende der URL
			replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$)^\")/gim;
		    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

		    //Change email addresses to mailto:: links.
		    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
		    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
		    return replacedText;
		}
		return inputText;
	}

	//from https://stackoverflow.com/questions/11300906/check-if-a-string-starts-with-http-using-javascript
	getValidUrl(url = ''){
		let newUrl = window.decodeURIComponent(url);
		newUrl = newUrl.trim().replace(/\s/g, '');
		if (/^(:\/\/)/.test(newUrl)) {
			return `http${newUrl}`;
		}
		if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
			return `http://${newUrl}`;
		}
		return newUrl;
	};

	//also from stackoverflow
	validateEmail(email) {
		// eslint-disable-next-line
		var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(email);
	}

	render() {

		let text_data = this.props.text_data;
		let propsclassname = this.props.p_class_name ? this.props.p_class_name : '';
		let classNames = [propsclassname];

		let text_arr = [];
		let merkmal_out = "";
		if(text_data && text_data.DataEditor)
		{
			switch(text_data.DataEditor)
			{
				case 'Freitext':
				case 'nur lesen':
				case 'mehrzeilig':
					let str;
					classNames.push(text_data.DataEditor);
					classNames = classNames.join(' ');
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						str = this.linkify(snippet.Values[0].Value);

						//konvertieren erst nach dem regex in linkify() angewendet wird!
						//convert new lines and insert html >> is it really save?
						str = str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
						text_arr.push(<p key={index} className={classNames} dangerouslySetInnerHTML={{__html:str}}></p>);
					}
					merkmal_out = text_arr;
					break;
				case 'Datum':
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						text_arr.push(<p key={index} className={text_data.DataEditor}>{snippet.Values[0].Value}</p>);
					}
					merkmal_out = text_arr;
					break;
				case 'Link (www/eMail)':
					let link;
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						link = snippet.Values[0].Value;
						if(this.validateEmail(link))
						{
							text_arr.push(<p key={index}><Mailto email={link} /></p>);
						}
						else
						{
							text_arr.push(<p key={index} className="link_www" target="_blank"><a target="_blank" href={this.getValidUrl(link)}>{link}</a></p>);	
						}
					}
					merkmal_out = text_arr;
					break;
				case 'Liste (Benutzerdefiniert)':
				case 'Hierarchie (Benutzerdefiniert)':
					for(const [index, snippet] of text_data.MultipleMerkmalId.entries())
					{
						text_arr.push(<li key={index}>{snippet.Values[0].Value}</li>);
					}
					merkmal_out = <ul>{text_arr}</ul>;
					break;
				default:
					merkmal_out = null;
					break;
			}
		}

		return merkmal_out;
	}
}