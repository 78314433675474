import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileDownload} from '@fortawesome/free-solid-svg-icons';
import Config from '../../Config';

class MerkmalDokument extends Component {

	render() {

		let doc_data = this.props.doc_data.MultipleMerkmalId;
		// let label = this.props.doc_data.Label;
		let doc_arr = [];

		for(const [index, doc] of doc_data.entries())
		{
			doc_arr.push(
					<div className="col-sm-4" key={'doc-' + index}>
						<a href={Config.API_URL + '/getdocument?pfileguid=' + doc.Values[1].Value} className="document-link">
							<FontAwesomeIcon icon={faFileDownload} />
							<span>{doc.Values[0].Value}</span>
						</a>
					</div>
				);
		}

		return doc_arr;
	}
}
export default MerkmalDokument;