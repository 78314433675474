import React from 'react';

/**
 * This is the context for all components, which makes it possible to call these functions from everywhere in the app without passing them down.
 */
export const AppContext = React.createContext({

	filters_or: null,
	filters_join: null,

	filters_loaded: false,

	resetFiltersOr: () => {
		console.error('ERROR: resetFiltersOr - not implemented.');
	},
	//todo: add error messages to all functions!!
	resetFiltersJoin: () => {},
	resetAllFilters: () => {},

	//main function for searching offers
	searchApiOffersWithFilters: () => {},
	setFiltersAndSearchApiOffers: () => {},
	setCardFiltersAndSearchApiOffers: () => {},
	searchApiOffersFromSearchFormDetails: () => {},

	getFilter: () => {},
	toggleFilterAndSearch: () => {},
	setListFilter: () => {},
	setTextfilter: () => {},
	getTextfilter: () => {},
	setDateFilter: () => {},
	setFilterDatasets: () => {},

	getActiveFiltersAsJSX: () => {},
	checkFiltersActive: () => {},

	search_results: '',
	search_results_provider_data: '',
	searchdetail_results: '',
	provider_results: '',
	loader_active: null,

	getDetailsFromApi: () => {},

	gotoOfferDetail: () => {},
	gotoProvider: () => {},

	getProviderDetailsFromApi: () => {},
	getOffersForProvider: () => {},

	cms_card_data: null,
	getCMSCardData: () => {},
	getCMSCardBySlug: () => {},

	font_resize: null,
	header_img: null,
	high_contrast: null,
	switchContrast: () => {},
	setFontResize: () => {},
	setHeaderImg: () => {},

	s_cookies: [],
	refreshCookieState: () => {},

	/***************************************************************************************/
	// 	BASIS_T & MENDEN FUNCTIONS
	/***************************************************************************************/

	//Filter für Detailssuche, filters (mit options)
	// filters: {},
	// //Suche von Kacheln
	// context_filters_or:  [],
	// //gehört zu der Detailssuche
	// filter_location: null,
	// //exptra Filter für Datum, wird bei jede neue Suche zusätzlich abgefragt
	// filter_date: null,
	// //extra Filter für Regionsuche, wird bei jede neue Suche zusätzlich abgefragt
	// filter_district: {},

	// callApiSearcResultshFromCardFiltersOr: () => {},
	// callApiSearchResultsWithoutAllFilters: () => {},

	// filtersLoaded: () => {},

	// resetAllFiltersAndSearch: () => {},
	// resetAllFiltersAndQuickSearch: () => {},

	// resetFilter: () => {},

	// resetFilterWithID: () => {},

	// searchText: () => {},
	// searchLast: () => {},

	// setDateFilter: () => {},
	// setDateFilterAndQuickSearch: () => {},
	// setFilterAndQuickSearch: () => {},
	// setFilterAndSearch: () => {},
	// setMultipleFilters: () => {},

	// toggleFilterAndQuickSearch: () => {},
	// quickSearch: () => {},

	// gotoProvider: () => {},

	//refactor with goToProvider
	// getProviderDetailsFromApi: () => {},


});
