export default class FilterOption {
    // never changes, but may be used in other places, such as parent components
    static ClientCodeUnknown = "clientcode_unknown";

    constructor(ID, value, pPosition, pClientCode = null) {
        this.ID = ID;
        this.value = value;
        this.position = pPosition;
        this.active = false;
        this.m_clientCode = pClientCode ? pClientCode : FilterOption.ClientCodeUnknown;
    }

}
