import React from "react";

export default class FilterLocation {

	static TYPE = "TypeLocationFilter";

	constructor(location = "", radius = 1) {
		this.type = FilterLocation.TYPE;
		this.location = location;
		this.radius = radius;
	}

	buildForAPI(){
		if(this.location)
		{
			return {
				"Type": "Geography",
				"MerkmalTypeIDs": [],
				"Fields": [],
				"Values": [this.location, this.radius],
				"OperatorType": ""
			};
		}

		return false;
	}

	isSet(){
		return Boolean(this.location);
	}

	toJSX(key = 0, deactivateSelf){
		return (<span key={key} onClick={() => deactivateSelf()}>{this.toString()}</span>);
	}

	toString(){
		return this.location + " / " + this.radius + "km";
	}
}
