import React, {Component} from 'react';

import Header from "./Header";
import Footer from "./Footer";
import SearchListBoxes from "./SearchListBoxes";

import {AppContext} from "../../common/app/AppContext";
import AddWatermarkSharePartial from "../../common/components/AddWatermarkSharePartial";
import CookieBanner from '../../common/components/CookieBanner';
import PopUpMessage from './PopUpMessage';

/**
 * This is the main template component and renders the Header + Footer and the @children in between.
 *
 * @class      LayoutDefault (name)
 * @prop        {object} heroData - {img, title, body}
 */
class LayoutDefault extends Component {

    static defaultProps = {
        searchNavTop: true,
        p_showSearchFormEnhanced: true
    }

    constructor(props) {
        super(props);

        this.state = {
            show_pop_up: false
        };

        this.togglePopUp = this.togglePopUp.bind(this);
    }

    togglePopUp(){
        this.setState({
            show_pop_up: !this.state.show_pop_up
        })
    }

    render() {

        let addWatermark = '';
        if (this.props.layoutRouterProps && this.props.layoutRouterProps.p_addwatermark) {
            addWatermark = (<AddWatermarkSharePartial/>);
        }

        let elemCookieBanner = (<CookieBanner/>);
        if(window.location.href.indexOf("/datenschutz/")>0
            || window.location.href.indexOf("/impressum/")>0
            || window.location.href.indexOf("/preview")>0)
          {
            elemCookieBanner = '';
          }

        return (

            <div id="layout-default">
                {addWatermark}

                <Header
                    heroData={this.props.heroData}
                    searchNavTop={this.props.searchNavTop}
                    p_showSearchFormEnhanced={this.props.p_showSearchFormEnhanced}
                />

                <main>
                    <div className={this.props.cssClass}>
                        <div className="content">
                            {this.props.children}
                        </div>
                    </div>
                </main>

                <SearchListBoxes />

                <Footer togglePopUp={this.togglePopUp} />

                <PopUpMessage show={this.state.show_pop_up} toggle={this.togglePopUp} />

                {elemCookieBanner}

            </div>

        );
    }
}
LayoutDefault.contextType = AppContext;
export default LayoutDefault;