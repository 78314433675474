import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {AppContext} from '../app/AppContext';
import MapResult from './MapResult';
import CookieUtil from '../tools/CookieUtil.js';

/**
 * Wrapper component for ResultSets.
 * Contains FilterButtons & MapResults.
 *
 * @class      SearchResultsWithMap (name)
 * @prop 		{json} api_results - these should already be filtered to show only offers
 */
class SearchResultsWithMap extends Component {

	constructor(props){
		super(props);

		this.state = {
			tileview: 'tileview',
			mapview: 'mapview'
		}

		this.show_map = true;
		this.ref_map_result = React.createRef();
	}

	setTileView(on_off){
		this.setState({
			tileview: (!on_off) ? '' : 'tileview'
		});
	}

	toggleMapView(){
		this.setState({
			mapview: (this.state.mapview) ? false : true
		});
	}

	showMap(val){
		this.show_map = val;
	}

	render() {

		let map_result = 	<MapResult
			p_cookiesGmapAccepted={this.context.s_cookies[CookieUtil.g_cookieGoogleMap]}
			width="100%"
			datasets={this.props.api_results}
			callBack={this.context.gotoOfferDetail}
			zoomToID={this.props.zoomToID}
			ref={this.ref_map_result}>
		</MapResult>;

		//todo >> MapResult.getShowMap()
		// let show_map = false;
		// if(this.ref_map_result.current)
		// 	show_map = this.ref_map_result.current.getShowMap();

		let map_button = <button id="mapview" onClick={(e) => {this.toggleMapView()}}><i className="fas fa-map"></i></button>;

		let map_view = '';
		if(this.state.mapview)
		{
			map_view = 'mapview';
		}

		return(

			<div className="search-results-with-map row">

				<section className="result-sets">

					{this.props.children}

				</section>

				{map_result}

			</div>

		);
	}
}

SearchResultsWithMap.contextType = AppContext;
//withRouter gives the component access to props.history
export default SearchResultsWithMap;
