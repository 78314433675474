import React, {Component} from 'react';
import {Link} from "react-router-dom";

class ProviderSet extends Component {

    render() {
        let link_url = "/detailorganisation/" + this.props.id;

        return (
            <div className="provider-set">
                <Link to={link_url}>{this.props.name}</Link>
            </div>
        );
    }
}

export default ProviderSet;