import React, {Component} from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import iconDownload from './media/icons/download-200.png';
import imgWissenswert from "../goettingen_kita_t/media/static_pages/wissenswertes_g.jpg";

/**
 * Shows a ViewStaticWeitereLinks Page
 *
 * @class      ViewStaticWeitereLinks (name)
 */
export default class ViewStaticWeitereLinks extends Component {

    render() {
        return (
            <div className="view-static view-static-weitere-links">
                    <h1 className={"title"}>Weitere Links</h1>
                    <p>
                        Hinweis zu externen Links: Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten sind stets die jeweiligen Anbieter*innen oder Betreiber*innen verantwortlich.
                    </p>
                <h2>Hier finden Sie eine Auswahl von Links zum Thema „Leben in Stuttgart“:</h2>
                <ul class={"list-margin"}>
                    <li>
                        Allgemeine Themen und aktuelle Veranstaltungen in Stuttgart <br/>
                        <a href="https://www.stuttgart.de/" target={'_blank'}>https://www.stuttgart.de/</a>
                    </li>
                    <li>
                        Luftballon – Elternzeitung der Region Stuttgart <br/>
                        <a href="https://www.elternzeitung-luftballon.de/" target={'_blank'}>https://www.elternzeitung-luftballon.de/</a>
                    </li>
                </ul>

                <h2>Hier finden Sie eine Auswahl von Links zum Thema „Frühe Hilfen“:</h2>
                <ul class={"list-margin"}>
                    <li>
                        Das Nationale Zentrum Frühe Hilfen (NZFH) <br/>
                        <a href="https://www.fruehehilfen.de/das-nzfh/" target={'_blank'}>https://www.fruehehilfen.de/das-nzfh/</a>
                    </li>
                    <li>
                        Internetportal des NZFH für Schwangere und junge Eltern <br/>
                        <a href="https://www.elternsein.info/" target={'_blank'}>https://www.elternsein.info/</a>
                    </li>
                    <li>
                        Bundesministerium für Familie, Senioren, Frauen und Jugend <br/>
                        <a href="https://www.bmfsfj.de/">https://www.bmfsfj.de/</a>
                    </li>
                    <li>
                        Homepage der Bundeszentrale für gesundheitliche Aufklärung (BZgA) <br/>
                        <a href="https://www.bzga.de/ueber-uns/" target={'_blank'}>https://www.bzga.de/ueber-uns/</a>
                    </li>
                    <li>
                        Alles zur Gesundheit von Kindern: kindergesundheit-info.de der BZgA <br/>
                        <a href="https://www.kindergesundheit-info.de/themen/" target={'_blank'}>https://www.kindergesundheit-info.de/themen/</a>
                    </li>
                    <li>
                        Kinderunfallprävention (BZgA) <br/>
                        <a href="https://www.kindersicherheit.de/servicestelle.html" target={'_blank'}>https://www.kindersicherheit.de/servicestelle.html</a>
                    </li>
                </ul>


            </div>
        );
    }
}