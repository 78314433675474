import React from "react";

import MerkmalText from "../results/MerkmalText";
import MerkmalZahl from "../results/MerkmalZahl";
import MerkmalWertebereich from "../results/MerkmalWertebereich";
import MerkmalJaNein from "../results/MerkmalJaNein";
import MerkmalAdresse from "../results/MerkmalAdresse";
import MerkmalDokument from "../results/MerkmalDokument";
import Helper from "../tools/Helper";


export default class ResultMerkmal {

    constructor() {
        this.m_label = '';
        this.m_body = '';
        this.m_class = '';
        this.m_merkmaltypeid  = '';
    }

    /**
     *
     * @param pMerkmalData
     */
    setContent(pMerkmalData, pClassName){
        this.m_merkmaltypeid = pMerkmalData.MerkmalTypeId;
        this.m_label = pMerkmalData.Label;
        //cut out "(FP) "
        this.m_label = Helper.cutStr(this.m_label, '(FP) ');

        switch(pMerkmalData.DataType){
            case 'Text':
                this.m_body = <MerkmalText text_data={pMerkmalData} p_class_name={pClassName}></MerkmalText>;
                this.m_class = ('merkmal-text');
                break;
            case 'Zahl':
                this.m_body = <MerkmalZahl zahl_data={pMerkmalData}></MerkmalZahl>;
                this.m_class = ('merkmal-zahl');
                break;
            case 'Wertebereich':
                this.m_body = <MerkmalWertebereich wertebereich_data={pMerkmalData}></MerkmalWertebereich>;
                this.m_class = ('merkmal-wertebereich');
                break;
            case 'JaNein':
                this.m_body = <MerkmalJaNein bool_data={pMerkmalData}></MerkmalJaNein>;
                this.m_class = ('merkmal-janein');
                break;
            case 'Adresse':
                this.m_body = <MerkmalAdresse address_data={pMerkmalData}></MerkmalAdresse>;
                this.m_class = ('merkmal-adresse');
                break;
            case 'Dokument':
                this.m_body = <MerkmalDokument doc_data={pMerkmalData}></MerkmalDokument>;
                this.m_class = ('merkmal-dokument');
                break;
            default:
                this.m_body = null;
                break;
        }

    }
}