export default class FilterSearchOption {

	constructor(ID, value, pPosition, pClientCode) {
		this.ID = ID;
		this.value = value;
		this.position = pPosition;
		this.active = false;
		this.client_code = pClientCode;
	}
}
