import React, {Component} from 'react';
import {withRouter} from "react-router";

import Config from '../../Config';
import GmapMsgShareAccepted from '../../common/components/GmapMsgShareAccepted.js';
import ResultHelper from '../../common/results/ResultHelper';

import Group from '../../common/results/Group';
import MapDetail from '../../common/components/MapDetail';
import MerkmalText from '../../common/results/MerkmalText';

/**
 * This component renders the search details
 *
 * @class      CreateDetailsProvider (name)
 * @prop        {json} dataset - api result set
 */
class CreateDetailsProvider extends Component {

    constructor(props) {
        super(props);

        this.m_configResultIdAnbieters = Config.RESULT_IDS_PROVIDER;

        this.initializeComponent();
        this.state = {
            s_cookieGmapAccepted: this.props.p_cookieGmapAccepted
        };
    }

    initializeComponent() {
        if (this.props.p_cookieGmapAccepted === window.undefined) {
            console.error("DevError [CreateDetailsProvider]: p_cookieGmapAccepted is undefined");
        }
    }

    // componentDidMount() {
    //     scroller.scrollTo('scroll_start', {
    //         duration: 1000,
    //         smooth: true
    //     });
    //
    //     window.addEventListener('scroll', this.listenToScroll);
    // }

    componentWillReceiveProps(nextProps) {
        let cookieGmapAcctepted = false;
        cookieGmapAcctepted = nextProps.p_cookieGmapAccepted;
        if (cookieGmapAcctepted !== this.state.s_cookieGmapAccepted) {
            this.setState({
                s_cookieGmapAccepted: cookieGmapAcctepted
            });
        }
    }

    render() {

        let dataset = this.props._datasetId;
        let groups = [];
        let counter = 0;
        let maps_view = null;
        let headerContent = null;
        let logo;

        console.log("CreateDetailsProvider dataset", dataset);

        if (dataset) {
            let ausnahmeMerkmalTypeIds = Config.DETAILVIEW_MERKMALTYPE_OFF_PROVIDER;

            //get groups
            // let merkmal_label;
            // let merkmal_data_value;
            let guidShortDescription = this.m_configResultIdAnbieters.short_description;

            //get logo
            let merkmal = ResultHelper.searchMerkmalId(dataset, Config.RESULT_IDS.logo_provider);
            let logo_id = ResultHelper.getValueAtNum(merkmal, 1);
            if(logo_id){
                let logo_url = Config.CMS_IMG_PATH + ResultHelper.getValueAtNum(merkmal, 1);
                logo = <img src={logo_url} className={'img-fluid logo-provider'} alt={"anbieter logo"}/>;
            }

            //iterate through groups and render group
            for (let group_data of dataset.Groups) {
                //Kontaktdaten in header anzeigen
                if (Config.RESULT_IDS.contact_group && group_data.GroupId === Config.RESULT_IDS.contact_group) {
                    let merkmal_data = group_data.Merkmale;
                    headerContent =
                        (
                            <div className="summary">
                                <h4 className="summary-title">{group_data.GroupDesignation}</h4>
                                <table>
                                    <tbody>
                                    {merkmal_data.map((row, index) => (
                                        <tr key={index}>
                                            <td className="summery-label" key="td-1">{row.Label}</td>
                                            <td className="summery-value" key="td-2">{<MerkmalText
                                                text_data={row}></MerkmalText>}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )
                } else {
                    groups.push(
                        <Group group_data={group_data} key={'group-' + ++counter}
                               p_merkmaltypeid_off={ausnahmeMerkmalTypeIds}></Group>
                    );
                    //try to find shortdescription
                    if (!headerContent) {
                        let merkmal_data = ResultHelper.searchMerkmalId(group_data, guidShortDescription, true);
                        if (merkmal_data) {
                            headerContent = (
                                <div className="summary">
                                    <h4 className="summary-title-provider">Kurzbeschreibung</h4>
                                    <MerkmalText text_data={merkmal_data}></MerkmalText>
                                </div>
                            );
                        }
                    }
                }
            }

            let mapHeight = "400px";
            if (this.state.s_cookieGmapAccepted) {
                //create maps view, when lat or lng != 0
                let latitude = parseFloat(dataset.Latitude);
                let longitude = parseFloat(dataset.Longitude);
                if (latitude || longitude) {
                    maps_view = (
                        <section className="maps mb-5">
                            <MapDetail width="100%" height={mapHeight} lat={latitude} lng={longitude}
                                       p_cookieGmapAccepted={this.state.s_cookieGmapAccepted}></MapDetail>
                        </section>
                    );
                }
            } else {
                let letMapContainerStyle = {
                    height: mapHeight
                };
                maps_view = (<GmapMsgShareAccepted pMapContainerStyle={letMapContainerStyle}/>);
            }
        }

        return (
            <div>
                <div className="eventinfo" name="scroll_start">
                    <header className="row information">
                        <div className="offer-name-col">
                            <div className="offer-name">
                                <h2 className="title">{dataset.LongName}</h2>
                                <span style={{display: 'none'}}>{dataset.DatasetId}</span>
                            </div>
                            {logo}
                        </div>
                        {headerContent}
                    </header>
                    <article className="groups">
                        {groups}
                    </article>
                </div>
                {maps_view}
            </div>
        );

    }

}

export default withRouter(CreateDetailsProvider);
