
import React from 'react';

import IImgHeaderLogoMandant from './sites/stuttgart/media/logo.svg';
import IImgFooterGebit from './sites/thueringen/media/logo_gebit.png';

import IImgHeaderHauptbild from './sites/stuttgart/media/header/header_start.jpg';
import IImgUeberUns from './sites/stuttgart/media/header/header_home.jpg';

import {AppConstants} from './common/app/AppConstants.js'; //ToDo: mit AppConstants ersetzen !
import {SvgIconsConstants} from './common/components/SvgIconsConstants.js';

export default class Config{

	static isLocalhost = window.location.hostname.toLowerCase().indexOf("localhost")>-1 || window.location.hostname.toLowerCase().indexOf("127.0.0.1")>-1;
	static DEBUG = this.isLocalhost;
	//todo: change tracker url
	static doTRACKER = false;//window.location.hostname.toLowerCase().indexOf("fruehehilfen-stuttgart.de")>-1;

	//*****************************************************
	//*		Stuttgart
	//*		1. bugfixing: loading in CMS-description für 'Organisation'
	//*		2. update/fixing: FilterOffer wird nur mit den AT aus STU geladen, STU_SMC/STURI rausgefiltert
	//*		3. update: 'Statische Seiten'
	//*****************************************************
	static LASTUPDATE = '2021.12.10';
	static VERSION = '2.0.0.0';
	static TRACKER = {
		url: 'https://analytics.gebit-ms.de/piwik.php',
		id: 0,
		active: this.doTRACKER
	};

	static BEARER_TOKEN = '77a709937b2c40eab4a4287c054f4539';

	//stuttgart
	static doGMAP = window.location.hostname.toLowerCase().indexOf("fruehehilfen-stuttgart.de")>-1;
	static MAPS_API_KEY = this.doGMAP ? "AIzaSyC8cgBlmSNE4GZ9V5xa_N98D7AjQp0VB68" : "AIzaSyAGduDfYNwFPXz3Dks0pJKoHSsbt04nsDQ";

	//LIVE
	static API_URL = 'https://iapi-stuttgart.fabis.pro/api';
	static ClientCode_Backend = 'STU';

	// DEV
	//static API_URL = 'https://relaunch.fabis.pro/api';
	//static API_URL = 'http://localhost:58579/api';

	//MAIL
	static MAIL_URL = 'https://stuttgart.fabis.pro/api/sendmail';//`${this.API_URL}`;`http://localhost:58579/api/sendmail`;//
	static CAPTCHA_KEY = "6LcKa8gaAAAAALjeFRYNgkBIbevzI15TXFuetUv2"; //STU life + localhost

	// **************************************
	// Zentrale Konstanten [Start]
	// **************************************
	static PROJECT_FOLDER_NAME = "stuttgart";

	// Aus dieser Tabelle werden Kommunen für das Kontaktformular geladen
	// Wenn da eine Kommune vorhanden ist, wird die intern für das sendmail() benutzt,
	// sonst muss der Nutzer aktiv eine Kommune auswähöen, an wen die Anfrage gerichtet ist
	static TABLE_KOMMUNEN = "coreKommunen";

	//https://www.npmjs.com/package/http-status-codes
	static REQUESTED_RANGE_NOT_SATISFIABLE = "416";
	static REQUESTED_ERROR_GEOGRAPHY_INVALID = {
		c_id: "0335",
		c_message: "Leider konnten keine Angebote mit der Umkreis-Suche gesucht werden. Bitte korrigieren oder ergänzen Sie Ihre Ort/PLZ-Eingabe!"
	};

	// **************************************
	// Zentrale Konstanten [ENDE]
	// **************************************
	static HERO_TITLE = "Frühe Hilfen in Stuttgart";
	static HERO_BODY = "Informationen für Familien in den ersten Lebensjahren";

	//CMS IDs
	static CMS_IDS = {
		CARDS: '5cd643a0-8adc-4257-a066-f9417f36c92f',
		CARD_IMG: '9d9eb478-fdf7-4f12-9c30-cb9e82b1d417',
		CARD_HEADLINE: 'fddc33b3-bb11-42ae-8873-e2261f50812e',
		CARD_BODY: 'b2af024a-6553-4a57-aa28-119e0d6d31a1',
		CARD_ACTION: 'f19931d4-8fea-47d4-9ed9-b65d21b168fb',
		CARD_FILTERS: '228ff897-eba8-45c3-b2a3-114e65ca168c',
		HEADER_IMG: 'a1233ab1-8af0-4e4f-8d3a-d4a8673f14e6',
		TOPIC_DESCRIPTION: 'f3ed71ab-cb7b-475b-b6f3-799b159376fd',
		TOPIC_HEADLINE: 'fddc33b3-bb11-42ae-8873-e2261f50812e',
		CONTENT_IMAGE: '3037521a-806f-48ed-ae05-b8b54f005f34',
		CONTENT_DESCRIPTION: '1a4f59f9-13a4-4a8d-950e-b5ab9d14f71d',
		OFFER_HEADLINE: '2076d5db-57b8-4c51-9545-d17821c5f9c7',
		OFFER_SUBHEADLINE: 'fb2b690c-ab9d-4f0f-b5ab-42c7e53d5926',
		OFFER_TEXT: 'c0c46b57-33cb-48de-9589-d8f330641e95',
		OFFER_LINKS: 'db57491a-a55a-4dee-9840-dea10135b095',
		OFFER_ALL_RESULTS: '701650a5-a18c-4bd3-b901-5d838fdf48a4'
	};

	static CMS_IMG_PATH = Config.API_URL + "/getdocument?pFileGuid=";

	//all filter IDs for loading and identifying in the app/context
	static FILTER_SLUGS = {
		LOCATION: 'location',
		TEXT: 'text',
		DATE: 'date',
		OFFER_TYPES: 'offer_types',
		LIST_DISTRICT: 'district',
		LIST_AUDIENCE: 'audience',
		LIST_TOPIC: 'topic',
		LIST_KIDS_AGE: 'kids_age',
		LIST_DATASETS: 'datasets',

		THEMA_BEGEGNUNG : 'thema_begegnung',
		THEMA_GESUNDHEIT: 'thema_gesundheit',
		THEMA_SCHWANGERSCHAFT: 'thema_schwangerschaft',
		THEMA_GELD: 'thema_geld',
		THEMA_UNTERSTUETZUNG: 'thema_unterstuetzung',
		THEMA_KINDERBETREUUNG: 'thema_kinderbetreuung',
		THEMA_BERATUNG: 'thema_beratung'
	};

	static FILTER_IDS = {
		//don't change strings!!
		[Config.FILTER_SLUGS.LOCATION]: "filter_id_location",
		[Config.FILTER_SLUGS.TEXT]: "filter_id_text",
		[Config.FILTER_SLUGS.DATE]: "filter_id_date",
		[Config.FILTER_SLUGS.OFFER_TYPES]: "filter_id_offer_types",

		//these can be changed
		[Config.FILTER_SLUGS.LIST_DISTRICT]: "9d46e9f4-44e1-4e29-9b38-9079fde9da2f", //<=MerkmalId, Regionalsuche, Kommune
		[Config.FILTER_SLUGS.LIST_AUDIENCE]: "9d29a84c-0ec9-4c9f-bd0c-2764837f8d84", //<=MerkmalId, Zielgruppe, ListenId => '29bf5d88-6bd0-4dee-83cd-8bef1f4873d2'
		[Config.FILTER_SLUGS.LIST_TOPIC]: "4a7b4cfe-4b9a-42a5-ac4a-065dd703c1fe", //Themen MerkmalTypeId, ListenId => '5450032c-3aa4-452e-836b-69d172c49bf2'
		[Config.FILTER_SLUGS.LIST_KIDS_AGE]: "18edf28d-2479-4702-8f7b-61c7cdad71a1",

		//Themenbäume für CMS Suche > Alle Ergebnisse
		[Config.FILTER_SLUGS.THEMA_BEGEGNUNG]: "03af4836-f78c-4f82-9b74-e2dcfdf6eda5",
		[Config.FILTER_SLUGS.THEMA_GESUNDHEIT]: "0b33059e-ffd9-4db1-bb88-e1c4aec7cb75",
		[Config.FILTER_SLUGS.THEMA_SCHWANGERSCHAFT]: '3c026ee0-d155-40e1-b82f-5827cb9aab20',
		[Config.FILTER_SLUGS.THEMA_GELD]: "8bfd0dec-3b8c-48b0-84a2-b6d3ef10e906",
		[Config.FILTER_SLUGS.THEMA_UNTERSTUETZUNG]: "9d5d75a8-85e8-4f61-9e6c-e7344880b49e",
		[Config.FILTER_SLUGS.THEMA_KINDERBETREUUNG]: "d3dcb69c-3caf-4bfe-9480-87d9e8150c05",
		[Config.FILTER_SLUGS.THEMA_BERATUNG]: "daa6cc10-686e-4b1a-a625-f796eb4c7869"
	};

	static FILTER_OFFER_TYPES = "offer_types";

	//MerkmalTypeId's  for Result Content
	static RESULT_IDS = {
		short_description: '5E1D6598-4504-4E06-8384-82A5AA58F22E',//Kurzbeschreibung des Angebotes;
		topic: 'a1bd9d4b-a7d3-4205-a6d5-28aa94586df5',
		region: '32eebb0a-e888-4048-83b3-dff359b76877',
		address: 'a34b1312-590e-49db-932d-844fa3558ced',//MerkmalTypeId Angebot und Organisation mit [flagIsLocation] in [coreMerkmaleDefinition]
		email: 'd8c5dd3f-d424-4fda-93fd-baae0d5505df',
		mobil: '4f786c2d-9e26-4ae3-94f8-b6a639952d6d',
		contact_name: '8ddf6677-e89b-4913-990d-f2b84ce9ba6f',

		event_start_date: '3e0fe833-bc7d-4f40-92ce-4fc558154b1c',
		event_start_time: '4dd8d128-f826-4ccb-b466-8493719d8552',
		event_end_date: '91f69948-50bc-4406-a996-1b9cc39ca1c5',
		event_end_time: 'b2e3a2b3-6e40-4561-a705-bf076d12032b',
		accessibility: '3a1e5f8c-6f8f-4081-b6b2-35a84921be31',
		logo_provider: '3bcd0c6b-f0c6-4883-b9b8-ec1318cb5f4c'
		// accessibility: '49e95931-0aaa-45c1-b478-febf204e4c00'
		//Kontakt-Gruppe von Organisationen um in der Detailansicht einer Organisation die Kontaktdaten im Header zu schreiben
		//,contact_group: '86a5bb9d-81cf-4fc8-a684-00317368a079'
		// //öffnungszeiten für FFO
		// ,offer_opening_hours: 'f64100b4-31ad-4614-b127-4d40917777ba'
		// ,offer_phone: '4f2fdc99-0f03-49a4-a5fc-c34e5026e6be'
	};
	static RESULT_IDS_PROVIDER = {
		short_description: 'B3EF6AB5-EE63-4E83-8DB1-C60D7EDC2DA0',
		contact_group: '6e82be26-df97-43b3-880b-c6e242b989f2'
	};

	//todo: wo wird das benutzt?
	//Diese Merkmaltypen sind Aussnahmen. Sie kommen vom Backend, werden aber nicht in der Detailansicht angezeigt.
	//Alle weiteren Merkmale werden aus den Gruppen in der Ansicht geladen
	static DETAILVIEW_MERKMALTYPE_OFF = ';'+this.RESULT_IDS.event_start_date+';'
		+this.RESULT_IDS.event_start_time+';'
		+this.RESULT_IDS.event_end_date+';'
		+this.RESULT_IDS.event_end_time+';'
		+this.RESULT_IDS.short_description+';'
		+this.RESULT_IDS_PROVIDER.short_description+';'
		+'';
	static DETAILVIEW_MERKMALTYPE_OFF_PROVIDER = ';'+this.RESULT_IDS_PROVIDER.short_description+';';


	//css-Klassennamen, z.B. SearchCard befindet sich gerade in CreateSearchCard.js
	static FONT_RESIZE_COMPONENTS = ['SearchCard', 'SearchTopic', 'SearchProvidersHierarchy', 'SearchAudience', 'ResultSet', 'SearchDetails', 'LayoutDefault'];

	//Filtertexte
	static FILTERTEXT_REGION_PLACEHOLDER_HEADER = "Kommune";

	// Medien
	static ImgHeaderLogoMandant = IImgHeaderLogoMandant;

	static ImgFooterGebit = IImgFooterGebit;
	static FooterTextLinks = "";//gefördert durch:
	static FooterTextRechts = "gefördert von:";
	static FooterUmsetzungText = "";

	static ImgHeaderHauptbild = IImgHeaderHauptbild;
	static ImgUeberUns = IImgUeberUns;

	static ACCESSIBILITY_ICON_COLOR = '#26262C';
	static IconSvgs_Map = {
		'4': SvgIconsConstants.g_IconSvgRollstuhl_Stu_imkreis,
		'1': SvgIconsConstants.g_IconSvgParkplatz_Stu_imkreis,
		'2': SvgIconsConstants.g_IconSvgWc_Stu_imkreis,
		// '00040': SvgIconsConstants.g_IconSvgTaub,
		// '00050': SvgIconsConstants.g_IconSvgBlind,
		// '00060': SvgIconsConstants.g_IconSvgAufzug,
		'3': SvgIconsConstants.g_IconSvgKinderwagen_Stu_imkreis
	};

	static QUICKSEARCH_ITEMS = 10;
	static PAGINATION_ITEMS = 5;
	static DISABLE_DEFAULT_DATEFILTER = false;

	static DATASET_PUBLISHED_DAYS_AGO = -28;

	//API-Constants
	static ApiKey_DatasetSubTypeOffer = "Offer";

	// Konfiguration Favoriten
	// Favoriten-funktion ist an, falls favoritesOn existiert und true gesetzt ist
	static favoritesOn = false;
	//Richtlinien: default alle OFF
	static COOKIE_DEFAULT_VALUE = {
		cookie_accepted: false,
		cookie_gmap: false,
		cookie_motomo: false
	}

	//render Merkmal Datei with title (Stuttgart)
	static MERKMAL_DOKUMENT_WITH_TITLE = "Weitere Informationen";

}
