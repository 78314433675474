import React, { Component } from 'react';

class MerkmalAdresse extends Component {

	render() {

		let address_data = this.props.address_data.MultipleMerkmalId;
		let address_arr = [];
		let label = this.props.address_data.Label;

		// address_arr.push(<p key="label">{label}</p>);

		for(const [index, snippet] of address_data.entries())
		{
			address_arr.push(<p key={index} className="address">{snippet.Values[0].Value} {snippet.Values[1].Value}, {snippet.Values[2].Value} {snippet.Values[3].Value}</p>);
		}

		return address_arr;
	}
}

export default MerkmalAdresse;