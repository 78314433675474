import React, {Component} from 'react';
import {Link} from "react-router-dom";

/**
 * Shows a 404 Page
 *
 * @class      View404 (name)
 */
class View404 extends Component {
    render() {
        return (
            <div className="view-404">
                <h1 className="text-center mt-5">Diese Seite existiert leider nicht.</h1>

                <Link to="/">&gt;&gt; zurück zur Homepage</Link>
            </div>

        );
    }
}

export default View404;
