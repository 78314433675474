import React, {Component} from 'react';
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import iconDownload from './media/icons/download-200.png';
import imgWissenswert from "../goettingen_kita_t/media/static_pages/wissenswertes_g.jpg";

/**
 * Shows a ViewStaticAnsprechparterInnen Page
 *
 * @class      ViewStaticAnsprechparterInnen (name)
 */
export default class ViewStaticAnsprechparterInnen extends Component {

    render() {
        return (
            <div className="view-static">

                <h1 className={"title"}>Ansprech&shy;partner*innen für Frühe Hilfen in Stuttgart</h1>
                <p>In allen Stadtteilen in Stuttgart stehen Eltern und Fachkräften Ansprechpartner*innen für das
                    Thema Frühe Hilfen zur Verfügung. Die jeweiligen Ansprechpersonen haben den Überblick über die
                    aktuellen Angebote vor Ort und sorgen für eine enge, stadtteilbezogene Vernetzung und die
                    Kooperation verschiedener Institutionen aus dem Gesundheitswesen und der Jugendhilfe.</p>
                <p>Die Ansprechpartner*innen, sogenannte Netzwerker*innen, informieren gerne alle Rat- und
                    Hilfesuchenden zu den Angeboten der Frühen Hilfen. Sie haben ihren Sitz in den Beratungszentren
                    Familie und Jugend.</p>
                <h2>Konkret bedeutet das für Familien: </h2>
                <p>Die Netzwerker*innen der Frühen Hilfen sind Expert*innen für Frühe Hilfen. Sie beraten werdende
                    Eltern und Familien mit Kindern bis drei Jahren zu passenden Angeboten in den jeweiligen
                    Stadtteilen und vermitteln bei Bedarf familienunterstützende Angebote durch eine
                    Familienhebamme, Familienkinderkrankenschwestern oder die Familienpflege. </p>
                <h2>für Fachkräfte: </h2>
                <p>Die Netzwerker*innen koordinieren und organisieren die regionalen Netzwerkkonferenzen, die als
                    systemübergreifende Informations- und Kommunikationsplattform für die Fachkräfte Frühe Hilfen
                    dienen. Durch diesen Austausch und die gemeinsame Abstimmung wird gewährleistet, den Familien
                    schnellere, unbürokratische und niedrigschwellige Zugänge zu den Frühen Hilfen zu ermöglichen
                    sowie bedarfsgerechte, passgenaue und wohnortnahe Angebote zu entwickeln und umzusetzen.</p>
                <div>
                    <h2>Die Netzwerker*innen in den elf Beratungszentren für Jugend und Familie:</h2>
                    <table className={"table-responsive"}>
                        <tbody>
                        <tr>
                            <td>Weilimdorf/Feuerbach</td>
                            <td>Bettina Horn-Kohl</td>
                            <td>Telefon:<strong> 0711 216-57357</strong></td>
                        </tr>
                        <tr>
                            <td>Zuffenhausen</td>
                            <td>Hiba Dawod</td>
                            <td>Telefon:<strong> 0711 216-98468</strong></td>
                        </tr>
                        <tr>
                            <td >Bad Cannstatt</td>
                            <td >Martin Jäger</td>
                            <td >Telefon: <strong>0711 216-98440</strong></td>
                        </tr>
                        <tr>
                            <td>Wangen</td>
                            <td>Christian Göbel</td>
                            <td>Telefon: <strong>0711 216-57684</strong></td>
                        </tr>
                        <tr>
                            <td >Möhringen</td>
                            <td >Edel Reichle-Kanthak</td>
                            <td >Telefon: <strong>0711 216-96924</strong></td>
                        </tr>
                        <tr>
                            <td >Vaihingen</td>
                            <td >Ann-Kathrin Glenz</td>
                            <td >Telefon:<strong> 0711 216-59287</strong></td>
                        </tr>
                        <tr>
                            <td >West/Botnang</td>
                            <td >Petra Kühn</td>
                            <td >Telefon: <strong>0711 216-57648</strong></td>
                        </tr>
                        <tr>
                            <td >Mitte/Nord</td>
                            <td >Regine Maier</td>
                            <td >Telefon: <strong>0711 216-55878</strong></td>
                        </tr>
                        <tr>
                            <td >Ost</td>
                            <td >Andrea Bernhard-Bieß</td>
                            <td >Telefon: <strong>0711 216-57831</strong></td>
                        </tr>
                        <tr>
                            <td >Süd</td>
                            <td >Arzu Karaca</td>
                            <td >Telefon: <strong>0711 216-57820</strong></td>
                        </tr>
                        <tr>
                            <td >Münster/Hallschlag</td>
                            <td >Frau Strauch</td>
                            <td >Telefon: <strong>0711 216-98472</strong></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <br/>
                <p>Die Zentrale Netzwerkkoordination ist für die stadtweite Vernetzung zuständig. Sie hat die Aufgabe, die flächendeckende Versorgung von Familien mit bedarfsgerechten Unterstützungsangeboten voranzutreiben und die Qualität der Vernetzung aller Leistungen zu verbessern:
                </p>
                <p>Natalia Glinker, Telefon: 0711 216-55289, E-Mail: natalia.glinker(at)stuttgart.de </p>
            </div>
        );
    }
}