import React, {Component} from 'react';

import {AppContext} from '../../common/app/AppContext';

import CreateDetailsProvider from '../../common/components/CreateDetailsProvider';
import SearchOffersFromProvider from '../../common/components/SearchOffersFromProvider';

import MessageSharePartial from '../../common/components/MessageSharePartial';
import CookieUtil from "../../common/tools/CookieUtil";


/**
 * Wrapper/View component for SearchDetails.
 *
 * @class      ViewProvider (name)
 */
class ViewProvider extends Component {

    constructor(props) {
        super(props);

        this.c_jsondata = null;

        this.state = {
            providerId: ''
        };
    }

    componentDidMount() {
        //search a dataset_id from the API
        //this.props.match is coming from the Router
        //@see App.js > render + @see https://reacttraining.com/react-router/web/api/match
        if (this.props.match && this.props.match.params.id) {
            this.setState({
                providerId: this.props.match.params.id
            });
            this.context.getProviderDetailsFromApi(this.props.match.params.id);
        }
        // if calls ViewPreview
        else if (this.props.p_datasetProvider) {
            this.setState({
                providerId: this.props.p_datasetProvider[0].DatasetId
            });
            this.c_jsondata = this.props.p_datasetProvider;
        }
    }

    componentDidUpdate() {
        if (this.props.match && this.state.providerId != this.props.match.params.id) {
            if (this.props.match.params.id) {
                this.setState({
                    providerId: this.props.match.params.id
                }, () => {
                    this.context.getProviderDetailsFromApi(this.props.match.params.id);
                });
            }
        }
    }

    render() {
        let details = null;
        let bWatermarkShow = false;
        let providerId = this.state.providerId;

        if (this.c_jsondata) {
            details = <CreateDetailsProvider _datasetId={this.c_jsondata[0]} p_cookieGmapAccepted={this.context.s_cookies[CookieUtil.g_cookieGoogleMap]}/>
            bWatermarkShow = true;
        } else if (this.context.provider_results) {
            details = <CreateDetailsProvider _datasetId={this.context.provider_results[0]} p_cookieGmapAccepted={this.context.s_cookies[CookieUtil.g_cookieGoogleMap]}/>
        } else {
            details = <MessageSharePartial>Organisation wird geladen ...</MessageSharePartial>;
        }

        let returnView = '';
        if (this.state.providerId) {
            returnView = (
				<div className="wrapper">
					{details}
					<SearchOffersFromProvider _providerId={providerId}/>
				</div>
			);
        } else {
            bWatermarkShow = false;
            returnView = (
				<div className="wrapper">
					<MessageSharePartial>Die Organisation wurde nicht gefunden</MessageSharePartial>
				</div>
			);
        }

        return (
            <div className="view-detail view-provider">
				<div className="row">
					<div className="search-details">
						{returnView}
					</div>
				</div>
            </div>
        );
    }
}

ViewProvider.contextType = AppContext;
export default ViewProvider;
