import React, { Component } from 'react';
import Helper from '../tools/Helper';
import ResultMerkmal from "../../common/model/ResultMerkmal";
import Config from "../../Config";

/**
 * Separates the different "merkmale" by DataType and call the matching components for rendering
 *
 * @class      Merkmal (name)
 * @prop 		{json} merkmal_data - API result data for one merkmal
 */
class Merkmal extends Component {

	render() {
		let merkmal_data = this.props.merkmal_data;

		let merkmal = new ResultMerkmal();
		merkmal.setContent(merkmal_data);

		let classes = ['merkmal', 'row', 'merkmal-label-' + Helper.makeSafeForCSS(merkmal.m_label)];
		let class_name = classes.join(' ')+" "+merkmal.m_class;

		let out = null;
		if(merkmal_data.DataType === 'Dokument')
		{
			if(!Config.MERKMAL_DOKUMENT_WITH_TITLE)
			{
				out = (
					<div className={class_name}>
						{merkmal.m_body}
					</div>
				);
			}
			else{
				out = (
						<div className={class_name}>
							<div className="merkmal-col label">
								<h4>{Config.MERKMAL_DOKUMENT_WITH_TITLE}</h4>
							</div>
							<div className="merkmal-col body">
								{merkmal.m_body}
							</div>
						</div>
					);
			}
		}
		else{
			out = (
					<div className={class_name}>
						<div className="col-md-5 col-xs-12 merkmal-col label">
							<h4>{merkmal.m_label}</h4>
						</div>
						<div className="col-md-7 col-xs-12 merkmal-col body">
							{merkmal.m_body}
						</div>
					</div>
			    );			
		}

		return out;		
	}
}

export default Merkmal;