import React, {Component} from 'react';
import {scroller} from 'react-scroll';
import {AppContext} from '../../common/app/AppContext';

import imgNews from './media/static/klimaschutz.jpg';

/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewStaticNews (name)
 */
class ViewStaticNews extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount(prevProps, prevState) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    render() {
        let out = null;

        //create page
        out = (

            <div className={"view-static-news"} name="scroll_start">
                <div className={"site-title"}>
                    <h1>Stadt zeichnet 28 Schulen für vorbildlichen Klimaschutz aus</h1>
                </div>
                <h2 className={"intro"}>
                    Im Rahmen der Jahresveranstaltung des Projekts „Lukratives Energiesparen in Stuttgarter Schulen – LESS“ am Mittwoch, 7. Juli, erhalten 28 erfolgreich teilnehmende Schulen Urkunden sowie Prämien und Sonderpreise für besondere Projekte in Höhe von insgesamt rund 17.000 Euro.
                </h2>

                <div className="img-wrapper">
                    <img src={imgNews} alt="Klimaschutz" className={"img-fluid content-img"}/>
                    <p className={"img-subtitle"}>
                        Ausgezeichnt wird das Engagement im Bereich Klimaschutz, Energie‐ und Ressourceneinsparung.
                    </p>
                </div>

                <p>
                    Das Engagement der Schülerinnen und Schüler im Bereich Klimaschutz, Energie‐ und Ressourceneinsparung erhält dadurch eine besondere Würdigung und dient den anderen Schulen als Anregung.
                </p>
                <p>
                    Der Bürgermeister für Städtebau, Wohnen und Umwelt, Peter Pätzold, gratulierte den Schulen zur erfolgreichen Teilnahme: „Klimaschutz ist das zentrale Thema unserer Zeit. Daher ist es wichtig, dass gerade die Schulen – die zukünftigen Generationen – sich mit den Themen auseinandersetzen, die dem Klimaschutz dienen.“
                </p>
                <p>
                    Die Schulen können frei über die Mittel verfügen. Die Teilnahme am Stuttgarter LESS‐Projekt ist eine Möglichkeit für Schulen, im Klimaschutz aktiv zu werden und ganz konkret ins Handeln zu kommen. Auf diesem Weg können Schulen auch die Forderungen aufnehmen und umsetzen, die von der jungen Generation im Rahmen der „Fridays for Future“‐Bewegung gestellt werden. Alle städtischen Schulen sind aufgerufen, sich an dem Projekt aktiv zu beteiligen.
                </p>
                <h3>
                    Einbinden der Themen in den Unterricht
                </h3>
                <p>
                    Das Projekt „Lukratives Energiesparen in Stuttgarter Schulen (LESS)“ wird seit zwölf Jahren vom Amt für Umweltschutz in Kooperation mit dem Schulverwaltungsamt angeboten und verankert die Themen Energieeinsparung, Ressourcen‐ und Klimaschutz fest in den Schulalltag. Sei es durch die Bildung eines LESS‐Teams, das sich neben Schülerinnen und Schülern aus der Schulleitung, den Lehrkräften und dem technischen Dienst zusammensetzt oder durch die Etablierung der Energiemanager, die im Klassenzimmer darauf achten, dass die Fenster nur im notwendigen Maß geöffnet und die Lichter abgeschaltet sind. Auch die Bildung von Arbeitsgruppen oder das Einbinden der Themen in den Unterricht sind Möglichkeiten, Schüler mitzunehmen und zu sensibilisieren. Die teilnehmenden Schulen erhielten für diese Aktivitäten einen durchschnittlichen Bonus von 553 Euro. Die Energieabteilung im Amt für Umweltschutz begleitet den Prozess, und je nach Umsetzungsgrad werden die Schulen dafür finanziell honoriert.
                </p>

            </div>

        );

        return out;
    }
}

ViewStaticNews.contextType = AppContext;
export default ViewStaticNews;
