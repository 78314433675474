import React, {Component} from 'react';
import {Switch, Route, Redirect} from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import promisifySetState from 'promisify-setstate';
import RouteWrapper from '../../common/structure/RouteWrapper';

import Config from '../../Config';
import CookieUtil from '../../common/tools/CookieUtil.js';

import ApiConnector from '../model/ApiConnectorST';
import FilterDate from '../model/FilterDate';
import HelperFilter from '../../common/model/HelperFilter';
import FilterLocation from '../model/FilterLocation';
import FilterList from '../model/FilterList';
import FilterListDatasets from '../model/FilterListDatasets';
import FilterOfferTypes from "../model/FilterOfferTypes";
import FilterText from "../model/FilterText";
import FilterOption from '../model/FilterOption';
import Tracker from '../../common/model/Tracker.js';

import {AppContext} from './AppContext';

import LayoutDefault from "../../sites/stuttgart/LayoutDefault";
import View404 from "../../sites/stuttgart/View404";
import CmsCardData from "../model/CmsCardData";
import Helper from "../tools/Helper";
//the views and router for the specific site are implemented in the concrete App.js
//e.g. src/sites/stuttgart/App.js

// import console from 'console-suppress';

/**
 * The main application class
 *
 * @class      App (name)
 */
class AppBase extends Component {

    /**
     * The constructor creates all bindings for the AppContext
     *
     * @see AppContext.js
     */
    constructor(props) {
        /* This is an abstract class providing all the basic functionality
         * Extend in /sites/$sitename/App.js
         */
        if (new.target === AppBase) {
            throw new TypeError("AppBase is abstract, please extend in sites/$sitename/App.js for specific implementation");
        }

        super(props);

        //the suppressors can be used to supress certain console logs
        // console.warn.suppress("href");
        // console.warn.suppress("simplebar");

        //The binding is necessary to make `this` work in the callback
        this.resetFiltersOr = this.resetFiltersOr.bind(this);
        this.resetFiltersJoin = this.resetFiltersJoin.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);
        this.setListFilter = this.setListFilter.bind(this);

        this.searchApiOffersWithFilters = this.searchApiOffersWithFilters.bind(this);
        this.searchApiOffersFromSearchFormDetails = this.searchApiOffersFromSearchFormDetails.bind(this);
        this.setFiltersAndSearchApiOffers = this.setFiltersAndSearchApiOffers.bind(this);
        this.setCardFiltersAndSearchApiOffers = this.setCardFiltersAndSearchApiOffers.bind(this);
        this.toggleFilterAndSearch = this.toggleFilterAndSearch.bind(this);

        this.resetAllFilters = this.resetAllFilters.bind(this);
        this.getActiveFiltersAsJSX = this.getActiveFiltersAsJSX.bind(this);
        this.checkFiltersActive = this.checkFiltersActive.bind(this);

        this.setTextfilter = this.setTextfilter.bind(this);
        this.getTextfilter = this.getTextfilter.bind(this);
        this.resetTextFilterAndSearch = this.resetTextFilterAndSearch.bind(this);
        this.setDateFilter = this.setDateFilter.bind(this);
        this.resetDateFilterAndSearch = this.resetDateFilterAndSearch.bind(this);
        this.resetLocationFilterAndSearch = this.resetLocationFilterAndSearch.bind(this);
        this.setFilterDatasets = this.setFilterDatasets.bind(this);

        this.gotoOfferDetail = this.gotoOfferDetail.bind(this);
        this.gotoProvider = this.gotoProvider.bind(this);
        this.getDetailsFromApi = this.getDetailsFromApi.bind(this);
        this.getProviderDetailsFromApi = this.getProviderDetailsFromApi.bind(this);
        this.getOffersForProvider = this.getOffersForProvider.bind(this);

        this.getCMSCardData = this.getCMSCardData.bind(this);
        this.getCMSCardBySlug = this.getCMSCardBySlug.bind(this);

        this.setFontResize = this.setFontResize.bind(this);
        this.switchContrast = this.switchContrast.bind(this);
        this.setHeaderImg = this.setHeaderImg.bind(this);

        this.refreshCookieState  = this.refreshCookieState.bind(this);

        //create default date filter
        this.default_date_filter = FilterDate.getDefault();

        //create holder for all initialised filters
        this.filters_init = {};

        //separate the filters into JOIN and OR filters
        this.FILTER_INIT_JOIN = [
            Config.FILTER_IDS[Config.FILTER_SLUGS.LIST_DISTRICT],
            Config.FILTER_IDS[Config.FILTER_SLUGS.TEXT],
            Config.FILTER_IDS[Config.FILTER_SLUGS.DATE]
        ];

        this.FILTER_INIT_OR = [
            Config.FILTER_IDS[Config.FILTER_SLUGS.OFFER_TYPES],
            Config.FILTER_IDS[Config.FILTER_SLUGS.LOCATION],
            Config.FILTER_IDS[Config.FILTER_SLUGS.LIST_AUDIENCE],
            Config.FILTER_IDS[Config.FILTER_SLUGS.LIST_TOPIC],
            Config.FILTER_IDS[Config.FILTER_SLUGS.LIST_KIDS_AGE],

            //Themenbäume für CMS Suche > Alle Ergebnisse
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_BEGEGNUNG],
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_GESUNDHEIT],
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_SCHWANGERSCHAFT],
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_GELD],
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_UNTERSTUETZUNG],
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_KINDERBETREUUNG],
            Config.FILTER_IDS[Config.FILTER_SLUGS.THEMA_BERATUNG]
        ];

        let letCurrentCookies = CookieUtil.getCookiesCurrent();

        this.state = {
            loader_active: false,
            header_img: null,

            //new filter system
            filters_loaded: false,
            filters_or: [],
            filters_join: {},

            resetFiltersOr: this.resetFiltersOr,
            resetFiltersJoin: this.resetFiltersJoin,
            resetAllFilters: this.resetAllFilters,
            getFilter: this.getFilter,
            getActiveFiltersAsJSX: this.getActiveFiltersAsJSX,
            checkFiltersActive: this.checkFiltersActive,
            toggleFilterAndSearch: this.toggleFilterAndSearch,

            setListFilter: this.setListFilter,
            setTextfilter: this.setTextfilter,
            getTextfilter: this.getTextfilter,
            setDateFilter: this.setDateFilter,
            setFilterDatasets: this.setFilterDatasets,

            searchApiOffersFromSearchFormDetails: this.searchApiOffersFromSearchFormDetails,
            searchApiOffersWithFilters: this.searchApiOffersWithFilters,
            setFiltersAndSearchApiOffers: this.setFiltersAndSearchApiOffers,
            setCardFiltersAndSearchApiOffers: this.setCardFiltersAndSearchApiOffers,

            search_results: '',
            search_results_provider_data: '',
            searchdetail_results: '',
            provider_results: '',
            redirect: null,

            gotoOfferDetail: this.gotoOfferDetail,
            gotoProvider: this.gotoProvider,
            getDetailsFromApi: this.getDetailsFromApi,
            getProviderDetailsFromApi: this.getProviderDetailsFromApi,
            getOffersForProvider: this.getOffersForProvider,

            cms_card_data: null,
            getCMSCardData: this.getCMSCardData,
            getCMSCardBySlug: this.getCMSCardBySlug,

            setHeaderImg: this.setHeaderImg,
            setFontResize: this.setFontResize,
            switchContrast: this.switchContrast,
            high_contrast: false,
            font_resize: 0,

            s_cookies: letCurrentCookies,
            refreshCookieState: this.refreshCookieState

        };

        console.log("location", window.location.hostname);
        console.log('Letzte Aktualisierung: ' + Config.LASTUPDATE);
        console.log('Version: ' + Config.VERSION);
        console.log('API URL: ' + Config.API_URL);
        console.log('Matomo Tracker active: ' + Config.TRACKER.active);

        if (!Config.DEBUG)
            console.log = function () {
        };
    }

    componentDidMount() {

        //keine Filter laden bei preview
        // todo: check -> unzuverlässig?
        if (window.location.href !== null && window.location.href.indexOf("/preview") >= 0) {
            this.showLoader(false);
            return;
        }

        let promises_init = [];

        //initialise all filters
        for (let slug in Config.FILTER_IDS) {

            let filter_id = Config.FILTER_IDS[slug];

            switch(slug)
            {
                //local filters
                case Config.FILTER_SLUGS.LOCATION:
                    promises_init.push(this.initialiseLocationFilter(filter_id));
                    break;

                case Config.FILTER_SLUGS.TEXT:
                    promises_init.push(this.initialiseTextFilter(filter_id));
                    break;

                case Config.FILTER_SLUGS.DATE:
                    promises_init.push(this.initialiseDateFilter(filter_id));
                    break;

                //API filters
                case Config.FILTER_SLUGS.OFFER_TYPES:
                    promises_init.push(this.initialiseOfferTypesFilter(slug, filter_id));
                    break;

                //ListFilters
                default:
                    promises_init.push(this.initialiseListFilter(slug, filter_id));
            }
        }

        //initialised cms data
        promises_init.push(this.initialiseCMSData());

        //check if all promises are fulfilled and continue
        Promise.all(promises_init).then( val => {
            this.showLoader(false);

            this.setState({
               filters_loaded: true
            });

            console.log('--- ALL FILTERS LOADED ---', this.filters_init);

            //reset filters or to create this.state.filters_or and this.state.filters_and;
            this.resetAllFilters().then(() => {
                console.log('state.filters_join created', this.state.filters_join);
                console.log('state.filters_or created', this.state.filters_or);
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        //stop redirect loop
        if (this.state.redirect) {
            this.setState({
                redirect: null
            });
        }
    }

    /**
     * Initialises a list filter in this.filters_init
     * for id @see: Config.FILTER_IDS
     *
     * @param      {string}  slug - name of filter
     * @param      {string}  ID - id of filter (see Config.FILTER_IDS)
     * @return      {string}  ID - id of filter (see Config.FILTER_IDS)
     */
    async initialiseListFilter(slug, ID) {
        this.showLoader(true);

        let response = await ApiConnector.getListItems(ID);

        let api_data = response.data.Body;
        console.log("initialiseListFilter(api_data) " + slug, ID, api_data);

        //filter the filter
        if (slug === 'district') {
            api_data = HelperFilter.selectFirstLevelFromServerHierarchie(api_data);
        }

        //create FilterOptions
        let options = {};
        for (const filter_data of api_data) {
            let option = new FilterOption(filter_data.Token, filter_data.Designation, filter_data.Position);
            options[option.ID] = option;
        }

        let filter = new FilterList(ID, slug, options);

        //add filter to this.filters_init
        this.filters_init[ID] = filter;

        return response;
    }

    /**
     * Gets the offer types from the API and builds a FilterList
     */
    async initialiseOfferTypesFilter(slug, ID) {
        this.showLoader(true);

        let response = await ApiConnector.getOfferTypes();

        let api_data = response.data.Body;
        console.log('Offer Types Api-Data alle:', api_data);

        let options = {};
        for (const filter_data of api_data) {
            let option = new FilterOption(filter_data.Id, filter_data.Designation, filter_data.Position, filter_data.ClientCode);
            options[option.ID] = option;
        }

        let filter = new FilterOfferTypes(ID, slug, options);

        //add filter to this.filters_init
        this.filters_init[ID] = filter;

        return response;
    }

    async initialiseLocationFilter(ID) {
        //create and add filter
        let filter = new FilterLocation();
        //add filter to this.filters_init
        filter.ID = ID;
        this.filters_init[ID] = filter;

        return filter;
    }

    async initialiseTextFilter(ID){
        //create and add filter
        let filter = new FilterText("", this.resetTextFilterAndSearch);
        //add filter to this.filters_init
        filter.ID = ID;
        this.filters_init[ID] = filter;

        return filter;
    }

    async initialiseDateFilter(ID){
        //create and add filter
        let filter = cloneDeep(this.default_date_filter);
        //add filter to this.filters_init
        filter.ID = ID;
        this.filters_init[ID] = filter;

        return filter;
    }

    async initialiseCMSData(){
        let response = await ApiConnector.getProviders(Config.CMS_IDS.CARDS);
        let api_data = response.data.Body;

        //function for traversing the json data
        let recursive_traverse = function(card_data, parent){
            let card_obj = new CmsCardData(card_data.Id, card_data.Title, parent);
            if(card_data.ChildNodes)
            {
                for (const child_node of card_data.ChildNodes) {
                    card_obj.children.push(recursive_traverse(child_node, card_obj));
                }
            }
            return card_obj;
        }

        //start tree traverse
        let cards_data = recursive_traverse(api_data[0], null);
        console.log("CMS Data", cards_data);

        return this.setState({
           cms_card_data: cards_data
        });
    }

    async getCMSCardData(cms_card_data){

        //todo: this function mutates a state variable -> REWRITE!!
        //search ID of cms_card_data in state.cms_card_data

        //check if data is already there (cache)
        if(!cms_card_data.data)
        {
            let response = await ApiConnector.searchDatasetID(cms_card_data.ID);
            let content = response.data.Body;

            //store data
            cms_card_data.data = content[0];
        }

        return cms_card_data.data;
    }

    async getCMSCardBySlug(slug)
    {
        if(this.state.filters_loaded)
        {
            let data_node;

            let searchfunc = (card_data) => {

                if(card_data.slug === slug){
                    data_node = card_data;
                    return true;
                }

                for(const child of card_data.children)
                {
                    if(searchfunc(child))
                        return true;
                }

                return false;
            }

            searchfunc(this.state.cms_card_data);

            //make sure data is loaded
            await this.getCMSCardData(data_node);

            //get parent data
            if(data_node.parent)
                await this.getCMSCardData(data_node.parent);

            return data_node;
        }
        else return false;
    }

    /**
     * Resets this.state.filters_or
     *
     * @return      {Promise}  Promise is fulfilled, after state is set
     */
    async resetFiltersOr(){
        let new_filters_or = this.getNewFilterOr();

        //set resetted_filters_or in an array
        return this.setState({
            filters_or: [new_filters_or]
        })
    }

    /**
     * Return new filters_or as copy of this.filters_init
     *
     * @return      {Object}  new_filters_or
     */
    getNewFilterOr(){
        let new_filters_or = {};

        //loop through FILTER_INIT_OR
        for (const id of this.FILTER_INIT_OR) {
            new_filters_or[id] = cloneDeep(this.filters_init[id]);
        }

        return new_filters_or;
    }

    /**
     * Resets this.state.filters_join
     *
     * @return      {Promise}  Promise is fulfilled, after state is set
     */
    async resetFiltersJoin(){
        let resetted_filters_join = {};

        //loop through FILTER_INIT_JOIN
        for (const id of this.FILTER_INIT_JOIN) {
            resetted_filters_join[id] = cloneDeep(this.filters_init[id]);
        }

        return this.setState({
            filters_join: resetted_filters_join
        })
    }

    /**
     * Resets all filters
     *
     */
    async resetAllFilters() {
        let promises = [];

        //reset filters_or
        promises.push(this.resetFiltersOr());
        //reset filters_join
        promises.push(this.resetFiltersJoin());

        //return promise, that resolves, when all filters are resetted
        return Promise.all(promises);
    }

    /**
     * Returns filter from filters_join or filters_or
     *
     * @return      filter
     */
    getFilter(ID){
        let filter = false;

        //create filterCheck function
        let filterCheck = (search_filter) => {
            for (const filter_id in search_filter)
            {
                if(filter_id === ID)
                    return search_filter[filter_id];
            }
        }

        //check filters join
        filter = filterCheck(this.state.filters_join);

        //todo: maybe return collection of filters, if there are more than one in filters_or?
        //nothing found in join, let's check the first entry of the filters_or array
        if(!filter)
            filter = filterCheck(this.state.filters_or[0]);

        return filter;
    }

    async setFilter(filter_id, filter){
        //is filter a JOIN or OR filter?
        if(this.FILTER_INIT_OR.includes(filter_id)) {
            let new_filters_or = cloneDeep(this.state.filters_or);
            //loop through new_filters_or and set new filter if it is already there
            for(const new_filter_or of new_filters_or){
                if(new_filter_or[filter_id]){
                    new_filter_or[filter_id] = filter;
                }
            }
            return this.setState({
                    filters_or: new_filters_or
                }
            );
        }
        else if(this.FILTER_INIT_JOIN.includes(filter_id)){
            let new_filters_join = cloneDeep(this.state.filters_join);
            new_filters_join[filter_id] = filter;
            return this.setState({
                    filters_join: new_filters_join
                }
            );
        }

        return Promise.reject('setFilter > Filter not found: ' + filter_id);
    }

    async setTextfilter(text) {
        let new_text_filter = new FilterText(text, this.resetTextFilterAndSearch);

        return this.setFilter(Config.FILTER_IDS.text, new_text_filter);
    }

    getTextfilter() {
        let filter = this.state.getFilter(Config.FILTER_IDS.text);
        if(filter)
            return filter;
        else
            return "";
    }

    async setDateFilter(date_from_picker)
    {
        let new_date_filter;

        if (date_from_picker && date_from_picker.length)
        {
            //create new date filter
            new_date_filter = FilterDate.getFilterFromDates(date_from_picker[0], date_from_picker[1]);
        }
        else{
            new_date_filter = cloneDeep(this.default_date_filter);
        }

        return this.setFilter(Config.FILTER_IDS.date, new_date_filter);
    }

    toggleFilterAndSearch(filter_id, option_id)
    {
        let filter = this.getFilter(filter_id);
        let active = !(filter.options[option_id].active);

        this.setListFilter(filter_id, option_id, active, this.searchApiOffersWithFilters);
    }

    async resetTextFilterAndSearch()
    {
        await this.setTextfilter('');
        await this.searchApiOffersWithFilters();
    }

    async resetDateFilterAndSearch()
    {
        await this.setDateFilter();
        await this.searchApiOffersWithFilters();
    }

    async resetLocationFilterAndSearch()
    {
        console.log("reset Location");
        let new_location_filter = new FilterLocation();
        await this.setFilter(Config.FILTER_IDS.location, new_location_filter);
        await this.searchApiOffersWithFilters();
    }

    /**
     * Sets a list filter to active / inactive
     *
     * @param      {string}   filter_id - see Config.FILTER_IDS
     * @param      {int}   option_id - option_id are created on runtime @see this.initialiseListFilter
     * @param      {boolean}  [active=true] - activate || deactivate
     */
    async setListFilter(filter_id, option_id, active = true, search_func = () => {})
    {
        let filter;
        let is_join = false;

        //get filter
        filter = this.getFilter(filter_id);

        //check if filter exists
        if(filter && filter.options[option_id])
        {
            //check if filter is in join
            is_join = this.FILTER_INIT_JOIN.includes(filter_id);

            //filter is a JOIN filter, let's update
            if(is_join)
            {
                //make a copy of filters for state update
                let new_filters_join = cloneDeep(this.state.filters_join);
                //change filter option to active
                new_filters_join[filter_id].options[option_id].active = active;
                //update state && return promise
                return this.setState({
                        filters_join: new_filters_join
                    },
                    //if there is a search_func in the arguments, execute after state change
                    search_func
                );
            }

            //filter is an OR filter
            else{
                //make a copy of filters for state update
                let new_filters_or = cloneDeep(this.state.filters_or);

                //loop through array and set options
                for (const filter_or of new_filters_or) {
                    filter_or[filter_id].options[option_id].active = active;
                }

                //update state && return promise
                return this.setState({
                        filters_or: new_filters_or
                    },
                    //if there is a search_func in the arguments, execute after state change
                    search_func
                );
            }
        }

        return 'no filter set';
    }

    async searchApiOffersWithFilters() {
        await this.setState({
                search_results: null,
                redirect: <Redirect push to='/ergebnisse/'/>
            }
        );

        return this.prepareAndSendApiSearchOffers();
    }

    /**
    * Favorites
    */
    async setFilterDatasets(pDatasetIds = [])
    {
        if (pDatasetIds.length)
        {
            let new_filters_or = cloneDeep(this.state.filters_or);
            //put location filter into new_filters_or
            new_filters_or[0][Config.FILTER_IDS.LIST_DATASETS] = new FilterListDatasets(pDatasetIds);

            return this.setState({
                    filters_or: new_filters_or
                }
            );
        }
        else
            return Promise.reject("Es gibt keine Einträge für die Anzeige");
    }


    async prepareAndSendApiSearchOffers() {

        let single_api_filter;
        let or_api_filters;
        let join_api_filters = [];
        let all_api_filters = [];
        let join_filter, or_filter;

        //collect join filters
        for (const join_filter_id in this.state.filters_join)
        {
            join_filter = this.state.filters_join[join_filter_id];
            single_api_filter = join_filter.buildForAPI();
            if(single_api_filter)
                join_api_filters.push(single_api_filter);
        }

        //loop through or-filters
        for (const or_filters of this.state.filters_or)
        {
            or_api_filters = [];

            //loop through or-filter
            for (const or_filter_id in or_filters)
            {
                //the filter converts itself
                or_filter = or_filters[or_filter_id];
                single_api_filter = or_filter.buildForAPI();
                if(single_api_filter)
                    or_api_filters.push(single_api_filter);
            }

            //add join filters if there are any or_api_filters
            if(or_api_filters.length)
            {
                or_api_filters.push(...join_api_filters);

                //add filter
                all_api_filters.push(or_api_filters);
            }
        }

        //when all_api_filters is empty, push at least the join filters
        if(!all_api_filters.length)
        {
            all_api_filters.push(...join_api_filters);
        }

        console.log("API Filters: ", all_api_filters);

        //start search with ApiConnector
        let response = await ApiConnector.search(all_api_filters);
        let json_data = response.data.Body;

        console.log('OFFERS: ', json_data);
        //get all providers
        let provider_set = new Set();
        for (const offer of json_data) {
            provider_set.add(offer.ParentId);
        }
        //convert to Array
        provider_set = [...provider_set];

        //get providers from API
        // response = await ApiConnector.getProviders(provider_set);
        // let provider_data = response.data.Body[0];

        //Lina -> Better method
        response = await ApiConnector.getDataSetList(provider_set);
        let provider_data = response.data.Body;

        console.log('Provider API Result', provider_data);

        this.showLoader(false);

        return this.setState({
            search_results: json_data,
            search_results_provider_data: provider_data
        });
    }

    /**
     * Helper function, converts filter array to card_filter
     *
     * @param      {Object}   card_filters - @see ConfigCards.js
     */
    async setFiltersAndSearchApiOffers(filters = [])
    {
        return this.setCardFiltersAndSearchApiOffers(HelperFilter.createCardsFilter(filters));
    }

    /**
     * Sets the card filters as filters_or and starts search
     *
     * @param      {Object}   card_filters - @see ConfigCards.js
     */
    async setCardFiltersAndSearchApiOffers(card_filters = {})
    {
        //get first key of card_filters
        let key = Object.keys(card_filters)[0];

        let new_filters_or = [];

        //this is a card_filter
        if(key === "FILTERS_OR")
        {
            let new_filter_or, filter_id, options;

            //loop through FILTERS_OR
            for (const card_filter_or of card_filters.FILTERS_OR) {

                //create new filter_or
                new_filter_or = this.getNewFilterOr();

                //loop through FILTERS_AND
                for (const card_filter_and of card_filter_or.FILTERS_AND) {
                    //get filter id
                    filter_id = Object.keys(card_filter_and)[0];
                    //get options array
                    options = card_filter_and[filter_id];

                    //loop through options array and set to active
                    for (const option_id of options) {
                        //set option
                        new_filter_or[filter_id].options[option_id].active = true;
                    }
                }

                //push or filter
                new_filters_or.push(new_filter_or);
            }
        }
        //card_filters seems not to be properly formated, let's take an empty filter_or then
        else{
            new_filters_or.push(this.getNewFilterOr());
        }

        //set new filters_or and start search
        await this.setState({
                filters_or: new_filters_or
            }
        );

        return this.searchApiOffersWithFilters();
    }


    /**
     * Gets the active filters as array with <span> wrappers.
     *
     * @return     {Array}  The active filters as jsx.
     */
    getActiveFiltersAsJSX() {

        let active_filters = [];
        let filter;
        let key_count = 0;

        //local function for adding filters
        let filterToJSX = (id) => {
            filter = this.getFilter(id);

            if(filter.isSet())
            {
                //convert filters and set reset function
                switch(filter.type){
                    case FilterText.TYPE:
                        active_filters.push(filter.toJSX(key_count++, this.resetTextFilterAndSearch));
                        break;
                    case FilterList.TYPE:
                    case FilterOfferTypes.TYPE:
                        active_filters.push(filter.toJSX(key_count++, this.toggleFilterAndSearch));
                        break;
                    case FilterDate.TYPE:
                        active_filters.push(filter.toJSX(key_count++, this.resetDateFilterAndSearch));
                        break;
                    case FilterLocation.TYPE:
                        active_filters.push(filter.toJSX(key_count++, this.resetLocationFilterAndSearch));
                        break;
                    default:
                        active_filters.push(filter.toJSX(key_count++));
                }

            }
        }

        //loop through filters_join
        for (const filter_id in this.state.filters_join) {
            filterToJSX(filter_id);
        }

        //loop through filters_or
        for (const filter_or_collection of this.state.filters_or) {
            for (const filter_id in filter_or_collection) {
                filterToJSX(filter_id);
            }
        }

        return active_filters;
    }


    /**
     * Checks if any filters are active
     *
     * @return     {Boolean}  true when there are active filters
     */
    checkFiltersActive() {

        let active_filters = this.getActiveFiltersAsJSX();

        return Boolean(active_filters.length);
    }


    /**
     * redirects to a detail page
     *
     * @param      {string}  dataset_id  - id is coming from the api search results
     */
    async gotoOfferDetail(dataset_id) {
        //redirect to ViewDetail
        let route = '/detail/' + dataset_id;

        return this.setState({
            redirect: <Redirect push to={route}/>
        });
    }

    async gotoProvider(dataset_id) {
        //redirect to ViewDetail
        let route = '/detailorganisation/' + dataset_id;

        return this.setState({
            redirect: <Redirect push to={route}/>
        });
    }


    /**
     * Gets the detail data from ApiConnector.
     *
     * @param      {string}  dataset_id - id is coming from the api search results
     */
    async getDetailsFromApi(dataset_id) {
        this.showLoader(true);

        //search a dataset_id with the ApiConnector
        let result = await ApiConnector.searchDatasetID(dataset_id);
        let json_data = result.data.Body;

        this.showLoader(false);

        return this.setState({
            searchdetail_results: json_data
        });
    }


    /**
     * Gets the provider detail view from ApiConnector.
     *
     * @param      {string}  dataset_id - id is coming from the api search results
     */
    async getProviderDetailsFromApi(dataset_id) {
        this.showLoader(true);

        //search a dataset_id with the ApiConnector
        let result = await ApiConnector.searchDatasetID(dataset_id);
        let json_data = result.data.Body;

        this.showLoader(false);

        return this.setState({
            provider_results: json_data
        });
    }

    /**
     * Gets the offers for a provider
     *
     * @param      {string}  provider_id - ID of Provider
     */
    async getOffersForProvider(provider_id) {

        let filter = ApiConnector.buildOrganisationFilter(provider_id);

        //search a dataset_id with the ApiConnector
        let result = await ApiConnector.search(filter);
        let json_data = result.data.Body;

        return json_data;
    }


    /**
     * searchApiOffersFromSearchForm is called from SearchFormEnhanced
     * Filters are extracted and applied and the API is searched
     *
     * @param      {string}  search_val - The search string
     * @param      {object}  form_state - All data collected from the form
     */
    // This function converts the form data and updates the state with the new data
    // In the end the main search function is called
    async searchApiOffersFromSearchFormDetails(form_state) {
        //reset filters_or
        await this.resetFiltersOr();

        //make copy of resetted filters
        let new_filters_or = cloneDeep(this.state.filters_or);

        let form_filter, filter_id;

        //add filters from form
        for (let form_filter_name in form_state) {

            //make an exception for the location filter
            if(form_filter_name === "location")
            {
                let radius = form_state.radius;
                //set default for search radius
                if (!radius) {
                    radius = 10;
                }

                //todo: check if location filter is an OR or AND filter
                //put location filter into new_filters_or
                new_filters_or[0][Config.FILTER_IDS.location] = new FilterLocation(form_state.location, radius);
            }

            //here are all the list filters
            else{
                form_filter = form_state[form_filter_name];
                filter_id = Config.FILTER_IDS[form_filter_name];

                if (filter_id) {
                    for (const filter_data of form_filter) {
                        if (filter_data)
                        {
                            //put data into first or filter >> new_filters_or[0]
                            new_filters_or[0][filter_id].options[filter_data.value].active = true;
                        }
                    }
                }
            }
        }

        //set state and make search call
        await this.setState({
            filters_or: new_filters_or
        });

        //return promise from async searchApiOffersWithFilters
        return this.searchApiOffersWithFilters();
    }


    showLoader(active = true) {
        this.setState({
            loader_active: active
        });
    }

    setFontResize(size) {
        let max_font_resize = 3;

        if (0 <= size && size <= max_font_resize) {
            this.setState({
                font_resize: size
            });
        }
    }

    switchContrast() {
        this.setState({
            high_contrast: !this.state.high_contrast
        });
    }

    async setHeaderImg(url, name) {

        let new_img = {
            url: url,
            name: Helper.makeSafeForCSS(name)
        }

        if(new_img !== this.state.header_img)
        {
            return this.setState({
                header_img: new_img
            });
        }
    }

    /**
    * @pCookies: array, {cookieKey: cookieValue, ... }
    */
    refreshCookieState(pCookiesAllNew){
      let newCookies = cloneDeep(this.state.s_cookies);
      console.log("refreshCookieState newCookies",newCookies);
      for (var key in pCookiesAllNew) {
        if (this.state.s_cookies.hasOwnProperty(key)) {
          newCookies[key] = pCookiesAllNew[key];
        }else{
          console.error("Konfigurationsfehler: füge neue Key in Config.js COOKIE_DEFAULT_VALUE hinzu");
        }
      }

      //update state
      this.setState({
        s_cookies: newCookies
      });
    }


    /**
     * This is the main render function.
     * The App gives its state to the AppContext, so that all components have access to search & filter functions.
     * Also the Router for all urls is initialised.
     *
     * For specific site implementation @see: /sites/$sitename/App.js
     */
    render() {
        return (
            <div id="app-wrapper">
                {/*<BusyAnimationSharePartial loading={this.state.loader_active}></BusyAnimationSharePartial>*/}
                <Tracker active={Config.TRACKER.active}/>
                <AppContext.Provider value={this.state}>
                    {/*redirect_to_home */}
                    {this.state.redirect}
                    <Switch>
                        <RouteWrapper component={View404} cssClass={"wrapper-404"} layout={LayoutDefault}/>
                    </Switch>
                </AppContext.Provider>
            </div>
        );
    }

}
export default promisifySetState(AppBase);
