import React, {Component} from 'react';
import {scroller} from 'react-scroll';
import {AppContext} from '../../common/app/AppContext';

import imgNews from './media/static/klimaschutz.jpg';

/**
 * Shows the sub page for the selected topic
 *
 * @class      ViewStaticImpressum (name)
 */
class ViewStaticImpressum extends Component {


    constructor(props) {
        super(props);
    }

    componentDidMount(prevProps, prevState) {
        scroller.scrollTo('scroll_start', {
            duration: 1000,
            smooth: true
        });
    }

    render() {
        let out = null;

        //create page
        out = (

            <div className={"view-static-impressum"} name="scroll_start">
                <h1 class={"title"}>Impressum</h1>
                <p>Landeshauptstadt Stuttgart;<br /> K&ouml;rperschaft des &ouml;ffentlichen Rechts;<br /> sie wird vertreten durch Dr. Oberb&uuml;rgermeister Frank Nopper.</p>
                <p>Hausanschrift:<br /> Rathaus<br /> Marktplatz 1<br /> 70173 Stuttgart</p>
                <p>Postanschrift:<br /> Landeshauptstadt Stuttgart<br /> 70161 Stuttgart</p>
                <p>Telefon: 0711 216&minus;0<br /> E‐Mail: <a href="mailto:&nbsp;post@stuttgart.de&nbsp;">&nbsp;post@<em>stuttgart.</em>de&nbsp;</a></p>
                <p>Umsatzsteuer‐Identifikationsnummer:<br /> DE 147793909</p>
                <h2>Verantwortliche Redaktion&nbsp; </h2>
                <p>Jugendamt Stuttgart, Fachdienst Fr&uuml;he Hilfen</p>
                <p>Aktualisierungsw&uuml;nsche senden Sie bitte an: <br /><a href={"mailto:portal.fruehehilfen@stuttgart.de"}>portal.fruehehilfen@stuttgart.de</a></p>
                <p>F&uuml;r die sachliche Richtigkeit und Aktualit&auml;t der einzelnen Themen, Beschreibungen der Dienstleistungen/Angebote und Daten zu Adressen sind die jeweiligen Tr&auml;ger verantwortlich.</p>
                <h2>Au&szlig;ergerichtliche Online-Streitbeteiligung</h2>
                <p>Die Europ&auml;ische Kommission stellt unter<a href="https://ec.europa.eu/consumers/odr/">&nbsp; https://ec.europa.eu/consumers/odr/</a> eine Plattform zur au&szlig;ergerichtlichen Online‐Streitbeilegung bereit.</p>
                <p>Information gem&auml;&szlig; &sect; 36 Verbraucherstreitbeilegungsgesetz<br /> Die Landeshauptstadt Stuttgart ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder bereit noch verpflichtet.</p>
                <h2>Redaktionssystem und technische Betreuung </h2>
                <p>GEBIT M&uuml;nster GmbH &amp; Co. KG, Corrensstra&szlig;e 80, 48149 M&uuml;nster</p>
                <p><a href="https://www.gebit-ms.de/">www.gebit-ms.de</a></p>
                <h2>Web-Design und Usability</h2>
                <p>GEBIT M&uuml;nster GmbH &amp; Co. KG, Corrensstra&szlig;e 80, 48149 M&uuml;nster</p>
                <p><a href="https://www.gebit-ms.de/">www.gebit-ms.de</a></p>
                <h2>Technischer Betrieb </h2>
                <p>GEBIT M&uuml;nster GmbH &amp; Co. KG, Corrensstra&szlig;e 80, 48149 M&uuml;nster</p>
                <p><a href="https://www.gebit-ms.de/">www.gebit-ms.de</a></p>
                <h2>Urheberrecht und Haftungsausschluss</h2>
                <ol>
                    <li>Das Layout der Website, die verwendeten Grafiken, Texte und sonstigen Inhalte und Werke sind u.a. urheberrechtlich gesch&uuml;tzt. Eine Vervielf&auml;ltigung ist nur zum privaten Gebrauch zul&auml;ssig. Es d&uuml;rfen jedoch keine &Auml;nderungen vorgenommen und keine Vervielf&auml;ltigungsst&uuml;cke verbreitet oder zu &ouml;ffentlichen Wiedergaben benutzt werden. Die einzelnen Beitr&auml;ge sind ebenfalls u.a. urheberrechtlich gesch&uuml;tzt; weitere Hinweise k&ouml;nnen ggf. dort nachgelesen werden.</li>
                    <li>Die Landeshauptstadt beh&auml;lt sich das Recht vor, zur Verf&uuml;gung gestellte Manuskripte, Bildmaterial und &Auml;hnliches zu bearbeiten. Ein Anspruch auf Ver&ouml;ffentlichung dieser Materialien unter www.fruehehilfen-stuttgart.de besteht nicht.</li>
                    <li>Alle Angebote sind freibleibend und unverbindlich.</li>
                    <li>Die Landeshauptstadt ist nur f&uuml;r die eigenen Inhalte auf dieser Seite verantwortlich. Die Landeshauptstadt ist jedoch nicht verpflichtet, das Einstellen von fremden Inhalten zu &uuml;berwachen, oder diese auf ihre Rechtm&auml;&szlig;igkeit zu pr&uuml;fen. Rechtswidrige Inhalte werden nach Kenntnis umgehend entfernt.</li>
                    <li>Soweit die Landeshauptstadt Links zu fremden Webseiten bereith&auml;lt, kann f&uuml;r die Inhalte auf den verlinkten Webseiten keine Gew&auml;hr &uuml;bernommen werden. F&uuml;r die Inhalte einer verlinkten Webseite ist nur deren jeweiliger Anbieter oder Seitenbetreiber verantwortlich. Die Landeshauptstadt hat die Inhalte der verlinkten Webseiten zum Zeitpunkt der Verlinkung &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren nicht erkennbar. Eine st&auml;ndige Kontrolle der verlinkten Webseiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Erh&auml;lt die Landeshauptstadt von rechtswidrigen Inhalten auf den verlinkten Webseiten Kenntnis, werden auf stuttgart.de gesetzte Links unverz&uuml;glich entfernt.<br /> &nbsp;</li>
                </ol>
                <h2>Bildrechte</h2>
                <p>Bilder, Fotos oder Grafiken, die im Internetangebot der Landeshauptstadt verwendet werden, sind grunds&auml;tzlich urheberrechtlich gesch&uuml;tzt. Die freie Verwendung der Bilder ist nicht erlaubt.</p>
                <h2>Fotografie</h2>
                <p>Frederik Laux, Stuttgart</p>

                <h2>Haftung f&uuml;r Inhalte von <a href="http://www.fruehehilfen-stuttgart.de">www.fruehehilfen-stuttgart.de</a></h2>
                <p>Die Landeshauptstadt Stuttgart bietet den Veranstaltern/Tr&auml;gern die M&ouml;glichkeit, die Veranstaltungen im Veranstaltungskalender &uuml;ber die Einstellung ihrer Angebote selbst einzutragen und zu verwalten. Bitte beachten Sie dabei unsere <a href="https://service.stuttgart.de/login/signup">&nbsp;Nutzungsbedingungen</a>.&nbsp;</p>
                <p>Es besteht keine rechtliche Verpflichtung seitens der Landeshauptstadt Stuttgart Veranstaltungen zu ver&ouml;ffentlichen. Veranstaltungen, die gegen geltendes Recht versto&szlig;en, werden abgelehnt und/oder gel&ouml;scht.</p>
                <p>Die Informationen &uuml;ber die Veranstaltungen unter www.fr&uuml;hehilfen-stuttgart.de
                    <br /> a.) werden von Veranstaltern/Tr&auml;gern selbst eingetragen und gepflegt,<br /> b.) von der der Landeshauptstadt Stuttgart eingetragen.<br /> </p>
                <p>Die Landeshauptstadt Stuttgart &uuml;bernimmt keine Gew&auml;hr f&uuml;r die Richtigkeit, Aktualit&auml;t oder Vollst&auml;ndigkeit der Informationen. Jede Haftung von Sch&auml;den, die durch die Nutzung der dargebotenen Informationen verursacht wurden, ist grunds&auml;tzlich ausgeschlossen.</p>
                <p>Der Veranstalter stellt schon jetzt die die Landeshauptstadt Stuttgart von s&auml;mtlichen Anspr&uuml;chen Dritter frei, die von Dritten aufgrund von oder in Zusammenhang mit einem von ihm eingetragenen Veranstaltungshinweis aufgrund von einer Rechtsverletzung erhoben werden. Dies gilt auch f&uuml;r angemessene Anwaltskosten.</p>
                <p>Zudem &uuml;bernimmt die Landeshauptstadt Stuttgart bei nichtst&auml;dtischen Veranstaltungen keine Haftung f&uuml;r die Rechtm&auml;&szlig;igkeit und Inhalte der Veranstaltungen.</p>
            </div>

        );

        return out;
    }
}

ViewStaticImpressum.contextType = AppContext;
export default ViewStaticImpressum;
