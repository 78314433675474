import { Component } from 'react';

class MerkmalZahl extends Component {

	render() {

		let label = this.props.zahl_data.Label;
		let zahl_data = this.props.zahl_data.MultipleMerkmalId;

		let zahlen = [];
		for(let data of zahl_data)
		{
			zahlen.push(data.Values[0].Value);
		}

		return(
				zahlen.join(', ')
		    );
	}
}

export default MerkmalZahl;