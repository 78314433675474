import React from 'react';
/**
 * Describes the FilterOfferTypes
 * for options @see OfferTypesFilterOptions
 *
 * @class      FilterOfferTypes (name)
 */
export default class FilterOfferTypes {

	static TYPE = "FilterOfferTypes";

	constructor(ID, slug, options) {
		this.type = FilterOfferTypes.TYPE;
		this.ID = ID;
		this.slug = slug;
		this.options = options;
	}

	isSet(){
		let active = false;
		let option;

		//loop through options and check if anything is set to active
		for (let option_id in this.options) {
			option = this.options[option_id];
			if (option.active) {
				active = true;
			}
		}

		return active;
	}

	toString(){
		let option;
		let out = "";

		for (let option_id in this.options) {
			option = this.options[option_id];
			if (option.active) {
				out += option.value + " | ";
			}
		}

		return out;
	}

	toJSX(key, deactivateSelf){
		let out = [];

		for (let option_id in this.options) {
			let option = this.options[option_id];
			if (option.active) {
				out.push(<span key={key++} onClick={() => {deactivateSelf(this.ID, option_id)}}>{option.value}</span>);
			}
		}

		return out;
	}

	buildForAPI() {
		let active_options = [];

		//loop through options object and prepare values
		for (const opt_id in this.options) {
			if (this.options[opt_id].active) {
				active_options.push(opt_id);
			}
		}

		if(active_options.length) {
			return {
				"Type": "OfferType",
				"MerkmalTypeIDs": "",
				"Fields": "",
				"Values": active_options,
				"OperatorType": ""
			};
		}

		return false;
	}
}