import cloneDeep from 'lodash/cloneDeep';
import FilterSearch from './FilterSearch';
import FilterSearchOption from './FilterSearchOption';

import Helper from '../tools/Helper';
import Config from "../../Config";

/**
* Statische Klasse für die Anpassung von Filtern
*/
export default class HelperFilter {

	/**
	* gibt den Filter mit den selectierten Optionen vom ersten Level zurück
	* @prop 		{FilterSearchOption} pFilter - see this.context.filters[Config.FILTER_IDS['slug']]
	*/
	static selectFirstLevelFromSearchFilterOption(pSearchFilterOption){
		if(pSearchFilterOption){
			pSearchFilterOption.options = this.selectFirstLevelFromOptions(pSearchFilterOption.options);
		}
		return pSearchFilterOption;
	}
	/** Model FilterSearchOption*/
	static selectFirstLevelFromOptions(pOptions){
		let optionsResult = {};
		if(typeof(pOptions) !== "undefined")
		{
			for(let option_id of Object.keys(pOptions))
			{
				let option = pOptions[option_id];
				if(option.position.split(".").length  === 1){
					optionsResult[option_id]=option;
				}
			}
			return optionsResult;
		}
	}
	/**List Model from Server*/
	static selectFirstLevelFromServerHierarchie(pOptions){
		let optionsResult = {};
		if(typeof(pOptions) !== "undefined")
		{
			let result = pOptions.filter(option => option.Position.split(".").length  === 1);
			return result;
		}
	}

	static removeOptionsByValue(options, val){
		let new_options = cloneDeep(options);
		for(let key in new_options)
		{
			if(new_options[key].value == val)
			{
				delete new_options[key];
			}
		}

		return new_options;
	}

	/*
	* pFilterApi
	**/
	static convertApiItemsToSearchWithOptions(pConfigFilterIds, pConfigFilterIdsSlug, pFilterApi){
		let options = this.convertApiItemsToSearchOptions(pFilterApi);
		if(options && options.length)
		{
			let filterGuid = pConfigFilterIds[pConfigFilterIdsSlug];
			return new FilterSearch(filterGuid, pConfigFilterIdsSlug, options);
		}
		return null;
	}
	static convertApiItemsToSearchOptions(pFilterApi){
		//let options = [];
		let options = {};
		if(pFilterApi && pFilterApi.length)
		{
			for(const filter_data of pFilterApi)
			{
				let option = new FilterSearchOption(filter_data.Token, filter_data.Designation, filter_data.Position, filter_data.CLientCode);
				options[option.ID] = option;
			}
			return options;
		}
		return null;
	}

	/* konvertiert FilterOptionen to label-value SelectOptionen  **/
	static convertFilterWithOptionsToSelect(pFilterWithOptions){
		let options = [];
		if(pFilterWithOptions)
		{
			for(let option_id in pFilterWithOptions.options)
			{
				let option = pFilterWithOptions.options[option_id];
				options.push({
						value: option.ID,
						label: option.value
				});
			}
		}
		return options;
	}
	static convertOptionsToSelect(pFilterOptions){
		if(pFilterOptions && Object.keys(pFilterOptions).length > 0)
		{
			let options = [];
			for(let option_id in pFilterOptions)
			{
				let option = pFilterOptions[option_id];
				options.push({
						value: option.ID,
						label: option.value
				});
			}
			return options;
		}
		return null;
	}

	static getOptionsActiveFromSearchFilter(pSearchFilterWithOptions){
		let optionsAsArray = Object.values(pSearchFilterWithOptions.options);
		return optionsAsArray.filter(option => option.active);
	}

	static selectOptionsWithClientCode(pFilter, pClientCodes){
		if (pClientCodes !== window.undefined && pClientCodes.length > 0) {
			//wenn es konfiguriert ist, zeige nur die Einträge aus bestimmter DB an
			//weil der Integrator alle öffentliche Einträge aus allen Datenbanken liefert
			//Beispiel FGIF
			let listFilterOptionsNew = [];
			for (let id in pFilter.options){
				let filterOption = pFilter.options[id];
				if(pClientCodes.indexOf(";"+filterOption.client_code+";")>-1){
					listFilterOptionsNew[id]=(pFilter.options[id]);
				}
			}
			pFilter.options = listFilterOptionsNew;
		}
		return pFilter;
	}

	static createCardsFilter(filter_arr){
		return {
			'FILTERS_OR': [
				{
					'FILTERS_AND': filter_arr
				}
			]
		}
	}
}
