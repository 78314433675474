import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Config from '../../Config';
import CookieUtil from '../tools/CookieUtil.js';

import SvgIcons from '../../common/components/SvgIcons.js';
import {SvgIconsConstants} from '../../common/components/SvgIconsConstants.js';

export default class Favorite extends Component{

  constructor(props) {
      super(props);
      this.state = {
          s_favorite: this.props.p_favoriteSet
      };
  }

  handleClickFavoriteToogle(pDatasetId){
      let letFavoriteToogle = !this.state.s_favorite;
      CookieUtil.toogleCookieFavoriteDatasetValue(pDatasetId, letFavoriteToogle);
      this.setState({
          s_favorite: letFavoriteToogle
      });
  }
  getElementFavoriteIcon(pFavoriteSet){
    let elemIconFavorite = '';
    if(pFavoriteSet){
      elemIconFavorite =(<SvgIcons className="c_svg_favorite"
                            p_IconKey={SvgIconsConstants.g_IconFavoriteSet}
                            p_Tooltip="gemerkt, klicken zum abwählen"
                            width="40"
                            fill={"orange"}
                            />);
    }else{
      elemIconFavorite = (<SvgIcons className="c_svg_favorite"
                          p_IconKey={SvgIconsConstants.g_IconFavoriteInit}
                          p_Tooltip="mir merken"
                          width="40"
                          fill={"grey"}
                          />);
    }
    return elemIconFavorite;
  }
  render(){
    if(Config.favoritesOn){
      let svgFavorite = this.getElementFavoriteIcon(this.state.s_favorite);
      return(<div onClick={(e)=>this.handleClickFavoriteToogle(this.props.p_id)}>{svgFavorite}</div>);
    }
    return ('');
  }
}
Favorite.propTypes = {
  p_id: PropTypes.string.isRequired,
  p_favoriteSet: PropTypes.bool.isRequired
};
