import React, {Component} from 'react';
import Config from "../../Config";

import {AppContext} from '../../common/app/AppContext';

import ConfigCards from '../../ConfigCards';
import CreateSearchCardsWrapper from '../../common/components/CreateSearchCardsWrapper';
import SearchCardsHolder from "../../common/components/SearchCardsHolder";


/**
 * This is the home view
 *
 * @class      ViewHome (name)
 */
class ViewHome extends Component {

    componentDidMount() {
        this.context.setHeaderImg(Config.ImgHeaderHauptbild, 'config-header-hauptbild');
    }

    render() {

        return (

            <div className="view-home">
                <SearchCardsHolder card_data={this.context.cms_card_data} />
            </div>
        );
    }
}

ViewHome.contextType = AppContext;
export default ViewHome;
